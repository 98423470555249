<app-navbar-style-three></app-navbar-style-three>

<div class="page-title-area bg-image" style="height: 250px;">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Former Principal</h2>
                </div>
            </div>
        </div>
    </div>
</div>

<div>
    <div class="subscribe-wrap-area">
        <div class="container">
            <div class="subscribe-wrap-inner-box">
                <div class="row justify-content-center">
                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="single-team-box">
                            <div class="image">
                                <img src="assets/img/team-image/team19.jpg" alt="team">
                            </div>
                            <div class="content">
                                <h3>Rev. Fr. Kamal Corraya</h3>
                                <span>Former Pricipal</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="single-team-box">
                            <div class="image">
                                <img src="assets/img/team-image/team19.jpg" alt="team">
                            </div>
                            <div class="content">
                                <h3>Mr. Richard Gomes</h3>
                                <span>Former Pricipal</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="single-team-box">
                            <div class="image">
                                <img src="assets/img/team-image/team19.jpg" alt="team">
                            </div>
                            <div class="content">
                                <h3>Md. Abdul Mannan Lovlu</h3>
                                <span>Former Pricipal</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="single-team-box">
                            <div class="image">
                                <img src="assets/img/team-image/team19.jpg" alt="team">
                            </div>
                            <div class="content">
                                <h3>Dr. Md. Kutub Uddin</h3>
                                <span>Former Pricipal</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="single-team-box">
                            <div class="image">
                                <img src="assets/img/team-image/team19.jpg" alt="team">
                            </div>
                            <div class="content">
                                <h3>Dr. Ranajit Sen Chowdhury</h3>
                                <span>Former Pricipal</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="single-team-box">
                            <div class="image">
                                <img src="assets/img/team-image/team19.jpg" alt="team">
                            </div>
                            <div class="content">
                                <h3>Mrs. Edline Corraya</h3>
                                <span>Former Pricipal</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="single-team-box">
                            <div class="image">
                                <img src="assets/img/team-image/team19.jpg" alt="team">
                            </div>
                            <div class="content">
                                <h3>Brother Ujjal Placid Pereira, Csc</h3>
                                <span>Former Pricipal</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>