<!-- Start Footer Area -->
<section class="footer-area">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Contact Info</h3>
                    <ul class="footer-contact-info">
                        <li>
                            <i class="flaticon-phone-call"></i>
                            <span>Phone</span>
                            <!-- <a href="tel:+8801309108497">+8801309108497</a> <br> -->
                            <a href="tel:+88247113687">+88 247113687</a>
                        </li>
                        <li>
                            <i class="flaticon-email"></i>
                            <span>E-MAIL</span>
                            <a href="mailto:principalsghscdhaka@gmail.com">principalsghscdhaka@gmail.com</a><br>
                            <a href="mailto:hmsghs@gmail.com">hmsghs@gmail.com</a>
                            <a href="mailto:sghsc.info@sghscdhaka.edu.bd">sghsc.info@sghscdhaka.edu.bd</a>
                        </li>
                        <li>
                            <i class='fa-solid fa-map-location'></i>
                            <span>ADDRESS</span>
                            <a href="https://goo.gl/maps/MZXSuT6RPP9HwfFH7" target="_blank">82, Municipal Office Street,
                                Luxmibazar, <br> Dhaka-1100, Bangladesh</a>
                        </li>
                        <li>
                        </li>
                    </ul>
                </div>
                <div class="mapouter">
                    <div class="gmap_canvas"><iframe class="gmap_iframe" width="100%" frameborder="0" scrolling="no"
                            marginheight="0" marginwidth="0"
                            src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=St. Gregory's High School & College&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe><a
                            href="https://capcuttemplate.org/">Capcuttemplate.org</a></div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget pl-5">
                    <h3>Important Links</h3>
                    <ul class="footer-quick-links">
                        <li><a href="http://www.moedu.gov.bd/" target="_blank" rel="noopener">Ministry Of Education</a>
                        </li>
                        <li><a href="https://www.dhakaeducationboard.gov.bd/" target="_blank" rel="noopener">Dhaka
                                Board</a></li>
                        <li><a href="http://www.dpe.gov.bd/" target="_blank" rel="noopener">Directorate Of Primary
                                Education</a></li>
                        <li><a href="http://www.ugc.gov.bd/" target="_blank" rel="noopener">University Grants Comission
                                Of Bangladesh</a></li>
                        <li><a href="http://www.bteb.gov.bd/" target="_blank" rel="noopener">Technical Education
                                Board</a></li>
                        <li><a href="http://deb108497.dhakaeducationboard.gov.bd/" target="_blank" rel="noopener">Our
                                Government Website</a></li>
                        <li><a href="http://banbeis.gov.bd/new/" target="_blank" rel="noopener">Banbeis</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget pl-5">
                    <h3>Visitor Counts</h3>
                    <table class="table" style="color: white;">
                        <tbody style="text-align:left">
                            <tr class="">
                                <td>Today</td>
                                <td><span class="odometer" data-count="20">00</span></td>
                            </tr>
                            <tr class="">
                                <td>This Week</td>
                                <td><span class="odometer" data-count="500">00</span></td>
                            </tr>
                            <tr class="">
                                <td>This Month</td>
                                <td><span class="odometer" data-count="1000">00</span></td>
                            </tr>
                            <tr class="">
                                <td>This Year</td>
                                <td><span class="odometer" data-count="30938">00</span></td>
                            </tr>
                            <tr class="">
                                <td>Total</td>
                                <td><span class="odometer" data-count="234345">00</span></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="copyright-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-sm-6 col-md-6">
                    <p>Copyright @{{currentYear}} <a href="https://deshuniversal.com/" class="text-gray-600 ml-1"
                            target="_blank" rel="noopener noreferrer">Desh
                            Universal Pvt. Limited</a>. All rights reserved</p>
                </div>
                <!-- <div class="col-lg-6 col-sm-6 col-md-6">
                    <ul>
                        <li><a routerLink="/">Terms & Conditions</a></li>
                        <li><a routerLink="/">Privacy Policy</a></li>
                    </ul>
                </div> -->
            </div>
        </div>
    </div>
</section>
<!-- End Footer Area -->
