<app-navbar-style-three></app-navbar-style-three>

<div class="page-title-area bg-image" style="height: 250px;">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Visitors</h2>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="subscribe-wrap-area">
    <div class="container">
        <div class="subscribe-wrap-inner-box">
            <p>St. Gregory's High School was founded in 1882 by the Benedictine Priests, an order of catholic
                missionaries. It was
                founded as an English Medium School and later converted into a Bengali medium in National Curriculum to
                comply with
                the
                government order. St. Gregory's High School at present is conducted by the Brothers of Holy Cross under
                the
                patronization of the Archdiocese of Dhaka and permanently recognized by the Board of Intermediate and
                Secondary
                Education, Dhaka.

                The courses of Studies meet the requirements of the Secondary School Certificate examination
                administered by the
                Board
                of Intermediate and Secondary Education, Dhaka. Students are offered Science and Introduction to
                Business study
                courses
                from class 9.

                The aims of this institution may be stated in a concise from as: to assist students in developing nature
                sense of
                obligation to God, country and fellowmen to impart right kind of education, shape character and to
                develop in
                students
                the habits of true discipline and competencies to enable them to become dutiful and patriotic citizens
                of
                Bangladesh. At
                large, the school works for total development of the students through educating mind and heart.The
                institution
                welcomes
                students of all creeds, cast and ethnic background. Faith and freedom of conscience of all students are
                respected
                and
                safeguarded.
                <br><br>
                <strong>RULES and Regulation</strong>
                <br><br>
                <strong>I. Admission policies and rules :</strong>
                <br>
                New Students in class one are admitted at the beginning of the academic year only after duly fulfilling
                requiredadmission process. Students in other classes are admitted only when vacancies arise and
                admission is
                declared.
                A student has to fulfill the following for being in the pool of qualifying students: appropriate age,
                submitting
                rightly
                filled up form, appearing for interviews and writing admission test. Students selected on the basis of
                availability
                of
                seats, merit and having completed all admission criteria.
                If any student or parent foresees to study in a Cadet School, should not admit him at St. Gregory's High
                School.
            </p>
        </div>
    </div>
</div>