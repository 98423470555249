import
{
  Location,
  LocationStrategy,
  PathLocationStrategy,
} from '@angular/common';
import
{
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import
{
  NavigationCancel,
  NavigationEnd,
  NavigationStart,
  Router,
} from '@angular/router';
import { filter } from 'rxjs/operators';
import { EmitterService } from './EmitterService';
import { ApiService } from './api.service';
import { myvariables } from './variables';
import { environment } from 'src/environments/environment';
declare let $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [
    Location,
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy,
    },
  ],
})
export class AppComponent implements OnInit, OnDestroy
{
  location: any;
  routerSubscription: any;
  Key = environment.VAR_A;
  Cli = environment.VAR_B;
  constructor(
    private router: Router,
    private apiService: ApiService,
    private emitterService: EmitterService
  ) { }

  ngOnInit()
  {
    // let body = {
    //   username: atob(myvariables.a),
    //   password: atob(myvariables.b),
    // };

    // let clientData = localStorage.getItem('clientData');

    // if (this.apiService.isTokenExpired(clientData)) {
    //   this.apiService.getToken(body).subscribe((response: any) => {
    //     console.log(response, 'response getToken');

    //     localStorage.setItem('clientData', JSON.stringify(response.data));

    //     this.FetchNoticeData(JSON.stringify(response.data));
    //   });
    // } else {
    //   this.FetchNoticeData(clientData);
    // }

    this.FetchNoticeData_2(this.Key, this.Cli);

    this.recallJsFuntions();
  }

  recallJsFuntions()
  {
    this.router.events.subscribe((event) =>
    {
      if (event instanceof NavigationStart)
      {
        $('.preloader').fadeIn('slow');
      }
    });
    this.routerSubscription = this.router.events
      .pipe(
        filter(
          (event) =>
            event instanceof NavigationEnd || event instanceof NavigationCancel
        )
      )
      .subscribe((event) =>
      {
        $.getScript('../assets/js/custom.js');
        $('.preloader').fadeOut('slow');
        this.location = this.router.url;
        if (!(event instanceof NavigationEnd))
        {
          return;
        }
        window.scrollTo(0, 0);
      });
  }

  FetchNoticeData(clientData)
  {
    this.apiService.getNotices(clientData).subscribe((response: any) =>
    {
      console.log(response, 'response Notices');

      let sortedNotice = this.sortByStartDate(response.data);

      localStorage.setItem('noticeData', JSON.stringify(sortedNotice));

      this.emitterService.changeMessage(true);
    });
  }

  FetchNoticeData_2(key, cli)
  {
    this.apiService.getNotices_2(key, cli).subscribe((response: any) =>
    {
      console.log(response, 'response Notices');

      let sortedNotice = this.sortByStartDate(response.data);

      localStorage.setItem('noticeData', JSON.stringify(sortedNotice));

      this.emitterService.changeMessage(true);
    });
  }

  FetchData()
  {
    this.apiService.fetchData().subscribe(
      (data) =>
      {
        console.log('Data:', data);
        // Handle the fetched data here...
      },
      (error) =>
      {
        console.error('Error:', error);
        // Handle errors here...
      }
    );
  }

  async getJson()
  {
    await this.apiService.getMenuData().then(data =>
    {

      console.log(data, 'data');
    }).catch((err) =>
    {
      console.log('error in fetching data', err);
    });
  }
  ngOnDestroy()
  {
    this.routerSubscription.unsubscribe();
  }

  private getTime(date?: Date)
  {
    return date != null ? new Date(date).getTime() : 0;
  }

  public sortByStartDate(array: any): any
  {
    return array.sort((a: any, b: any) =>
    {
      return this.getTime(b.CreatedDate) - this.getTime(a.CreatedDate);
    });
  }
}
