import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'app-image-gallery-demo',
  templateUrl: './image-gallery-demo.component.html',
  styleUrls: ['./image-gallery-demo.component.scss']
})
export class ImageGalleryDemoComponent implements OnInit, OnDestroy
{
  clientData: any;

  albums: any = [];
  files: any = [];
  mainResponse: any = [];
  setupMessage = 'not set up yet';
  someParameterValue = null;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private apiService: ApiService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _router: Router,
    private activateRoute: ActivatedRoute,

  ) { }

  ngOnInit(): void
  {
    this.clientData = localStorage.getItem('clientData');

    this.activateRoute.params.subscribe(params =>
    {
      this.setupComponent(params['someParam']);
    })
  }

  setupComponent(someParam)
  {
    this.setupMessage = 'set up at ' + new Date();
    this.someParameterValue = someParam;
    console.log(this.someParameterValue, 'this.someParameterValue');

    if (this.someParameterValue == null || this.someParameterValue == false)
    {
      this.FetchMainAlbum(this.clientData);

    } else
    {
      this.FetchAlbumById(this.clientData, this.someParameterValue);
    }
  }

  FetchMainAlbum(clientData)
  {
    this.apiService.getAlbums(clientData).subscribe((response: any) =>
    {
      console.log(response, 'response FetchMainAlbum');

      let dalbum = this.apiService.TransformFolder_Data(response.data);
      console.log("dalbum:", dalbum);

      this.mainResponse = dalbum;

      this.albums = dalbum.filter(item => item.IsAlbumFolder === true);
      this.files = dalbum.filter(item => item.IsAlbumFolder === false);

      console.log("albums:", this.albums);
      console.log("files:", this.files);


      // this._changeDetectorRef.markForCheck();

    });
  }

  FetchAlbumById(clientData, albumId)
  {
    this.apiService.getAlbumById(clientData, albumId).subscribe((response: any) =>
    {
      console.log(response, 'response FetchAlbumById');

      let dalbum = this.apiService.TransformFolder_Data(response.data);
      console.log("dalbum:", dalbum);

      this.mainResponse = dalbum;

      this.albums = dalbum.filter(item => item.IsAlbumFolder === true);
      this.files = dalbum.filter(item => item.IsAlbumFolder === false);

      console.log("albums:", this.albums);
      console.log("files:", this.files);


      // this._changeDetectorRef.markForCheck();

    });
  }

  TransformUrl(value)
  {
    if (value.includes('https://'))
    {
      return value;
    }
    else
    {
      return `https://${value}`
    }
  }

  Goto(link): void
  {
    this.albums = [];
    this.files = [];
    this.someParameterValue = null;
    this._router.navigateByUrl(`/imageGallery/${link}`);
  }

  OpenFile(url)
  {
    // window.open(`https://${url}`, '_blank');
    window.open(`${url}`, '_blank');

  }

  ngOnDestroy(): void
  {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
