<app-navbar-style-three></app-navbar-style-three>

<div class="page-title-area bg-image"
  style="height: 250px">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="page-title-content">
          <h2>Admission Informations</h2>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="subscribe-wrap-area bg-light">
  <div class="container">
    <div class="subscribe-wrap-inner-box p-4 rounded">
      <h1 class="my-4 text-center">Admission Notice</h1>
      <div class="text-center">
        <a routerLink="/notice"
          class="btn btn-lg button">Click for Notice Board</a>
      </div>

      <h2 class="mt-4">ফর্মপূরণ সংক্রান্ত নিয়মাবলী</h2>
      <p>
        ১. বিজ্ঞান বিভাগ, ব্যবসায় শিক্ষা বিভাগ ও মানবিক বিভাগে ভর্তিচ্ছু ছাত্রকে এসএসসি পরীক্ষার ন্যূনতম জিপিএ যথাক্রমে
        ৪.৭৮, ৩.৫০ ও ২.৫০ থাকতে হবে।
      </p>
      <p>
        বিভাগ পরিবর্তনের ক্ষেত্রে ন্যূনতম জিপিএ:
      </p>
      <p>(ক) বিজ্ঞান বিভাগ থেকে ব্যবসায় শিক্ষা বিভাগ - ৪.০০ ও তদুর্ধ্ব</p>
      <p>(খ) বিজ্ঞান বিভাগ থেকে মানবিক বিভাগ - ৩.০০ ও তদুর্ধ্ব</p>
      <p>(গ) ব্যবসায় শিক্ষা বিভাগ থেকে মানবিক বিভাগ- ৩.০০ ও তদুর্ধ্ব</p>
      <p>
        ২। প্রার্থীর নাম জন্ম নিবন্ধন সার্টিফিকেট অনুযায়ী দিতে হবে এবং
        পিতা-মাতার নাম অবশ্যই তাদের জাতীয় পরিচয়পত্র অনুযায়ী দিতে হবে।
      </p>
      <p style="font-weight: bold;">
        ### পরীক্ষার সংক্রান্ত সব তথ্য (বিভাগ, তারিখ ও সময়) ভর্তি পরীক্ষার প্রবেশপত্রে পাওয়া যাবে।
      </p>
      <p>
        ২. ভর্তি আবেদন ফরম পূরণের সময় এসএসসি পরীক্ষার প্রবেশপত্র ও ট্রান্সক্রিপ্ট অনুযায়ী তথ্য প্রদান করতে হবে।
      </p>
      <p>
        ৩. ভর্তি সংক্রান্ত ব্যয় মোট ৩৫০/- টাকা (অফেরতযোগ্য)।
      </p>
      <p>৪. আবেদন ফরমে আবেদনকারীর সদ্য তোলা ছবি আপলোড দিতে হবে।</p>
      <p>৫. ভর্তির সময় এসএসসি পরীক্ষার প্রবেশপত্র ও রেজিস্ট্রেশন কার্ড এর মূলকপি ও ফটোকপি জমা দিতে হবে।</p>
      <p>৬. নির্দিষ্ট তারিখের মধ্যে সকল ফি পরিশোধ করে চূড়ান্ত ভর্তি সম্পন্ন করতে হবে।</p>
      <p>৭. ভর্তির আবেদনপত্রে কোনো ভুল তথ্য দেওয়া হলে ভর্তি বাতিল বলে গন্য হবে।</p>
      <p>৮. ভর্তি পরীক্ষার দিন সঙ্গে আনতে হবে:</p>
      <ul>
        <li>ডাউনলোডকৃত এডমিট কার্ড।</li>
        <li>এসএসসি পরীক্ষার প্রবেশপত্র ও রেজিস্ট্রেশন কার্ড এর ফটোকপি।</li>
        <li>অনলাইন ট্রান্সক্রিপ্টের কপি।</li>
        <li>উপর্যুক্ত কাগজপত্র একত্রে জমা দিতে হবে।</li>
      </ul>
      <p>
        ৯. সেন্ট গ্রেগরী হাই স্কুল অ্যান্ড কলেজে
      </p>
      <p style="font-weight: bold;">
        বিজ্ঞান বিভাগের অন্তর্গত বিষয়সমূহ:
      </p>
      <p>
        বাংলা, ইংরেজি, তথ্য ও যোগাযোগ প্রযুক্তি, পদার্থবিজ্ঞান, রসায়ন, জীববিজ্ঞান, উচ্চতর গণিত।
      </p>
      <p style="font-weight: bold;">
        ব্যবসায় শিক্ষা বিভাগের অন্তর্গত বিষয়সমূহ:
      </p>
      <p>
        বাংলা, ইংরেজি, তথ্য ও যোগাযোগ প্রযুক্তি, হিসাববিজ্ঞান, ব্যবসায় সংগঠন ও ব্যবস্থাপনা, ফিন্যান্স, ব্যাংকিং ও বিমা,
        পরিসংখ্যান/ অর্থনীতি।
      </p>
      <p style="font-weight: bold;">
        মানবিক বিভাগের অন্তর্গত বিষয়সমূহ:
      </p>
      <p>
        বাংলা, ইংরেজি, তথ্য ও যোগাযোগ প্রযুক্তি, ইতিহাস, সমাজকর্ম, যুক্তিবিদ্যা/অর্থনীতি, পরিসংখ্যান।
      </p>
      <p>
        ## ভর্তি সংক্রান্ত অন্যান্য তথ্যাদির জন্য visit করুন www.sghscdhaka.edu.bd) অথবা প্রতিষ্ঠানের অফিশিয়াল ফেসবুক
        পেইজে। (ভর্তি সংক্রান্ত কাজে প্রবেশপত্র অবশ্যই সঙ্গে আনতে হবে।)
      </p>
      <p>
        ## ওয়েবপেজ, ডাটাবেজ ও ইন্টারনেট সার্ভার জড়িত যেকোনো সমস্যার কারণে, আতঙ্ক না হয়ে ১৫ মিনিট পর আবার চেষ্টা করুন।
      </p>
      <p>
        ## অনলাইনে যেকোনো আবেদন সংক্রান্ত জটিলতার জন্য - 09643224488/ 01791350268
      </p>
      <p>
        ## প্রতিষ্ঠান সংক্রান্ত তথ্য ও ফরম ফিলাপে সহযোগিতা: 01890160462/ 01915603375
      </p>

      <h2 class="mt-4">Admission Options</h2>
      <div class="row mb-4">
        <div class="mb-4">
          <div class="d-flex align-items-center">
            <h3 class="mr-5 mb-0">English Version admission for Nursery: -</h3>
            <a href="https://sghscdhaka.edu.bd/admission/"
              target="_blank"
              class="btn btn-lg button-dis disabled-link"
              style="margin-left: 10px">Click Link</a>
          </div>
        </div>
        <div class="mb-4">
          <div class="d-flex align-items-center">
            <h3 class="mr-3 mb-0">Bangla Version admission for Class One: -</h3>
            <a href="https://sghscdhaka.edu.bd/admission/"
              target="_blank"
              class="btn btn-lg button-dis disabled-link"
              style="margin-left: 10px">Click Link</a>
          </div>
        </div>
        <div class="mb-4">
          <div class="d-flex align-items-center">
            <h3 class="mr-5 mb-0">
              Bangla Version admission for Class Eleven: -
            </h3>
            <a href="https://sghscdhaka.edu.bd/admission/institute"
              target="_blank"
              class="btn btn-lg button"
              style="margin-left: 10px">Click Link</a>
            <!-- <a href="https://sghscdhaka.edu.bd/admission/"
              target="_blank"
              class="btn btn-lg button-dis disabled-link"
              style="margin-left: 10px">Click Link</a> -->
          </div>
        </div>

        <!-- <h2 class="mb-4 mt-5 text-center">How to Apply Online for Admission</h2> -->
        <!-- <div
          class="card"
          style="box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); margin-bottom: 2rem"
        >
          <div class="card-body">
            <div class="youtube-embed">
              <iframe
                width="420"
                height="315"
                src="https://drive.google.com/file/d/1P3T8PaO32J3g4aElul-6AhSLQFd46_O7/preview"
                frameborder="0"
                allowfullscreen=""
              ></iframe>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</div>
