<app-navbar-style-three></app-navbar-style-three>

<!-- Start Page Title Area -->
<div class="page-title-area bg-image" style="height: 250px;">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Saint Gregory</h2>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start About Area -->
<section class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/school/saint_gregory.jpg" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <p>We bePope Gregory I (Latin: Gregorius I; c. 540 – 12 March 604), commonly known as Saint Gregory
                        the Great,[1] was Pope from
                        3 September 590 to his death in 604. Gregory is well known for his writings, which were more
                        prolific than those of any
                        of his predecessors as pope.[2] He is also known as St. Gregory the Dialogist in Eastern
                        Christianity because of his
                        Dialogues. For this reason, English translations of Eastern texts will sometimes list him as
                        "Gregory Dialogue".

                        Throughout the Middle Ages he was known as “the Father of Christian Worship” because of his
                        exceptional efforts in
                        revising the Roman worship of his day.[3] His contributions to the development of the Divine
                        Liturgy of the
                        Presanctified Gifts, still in use in the Byzantine Rite, were so significant that he is
                        generally recognized as its de
                        facto author. He was the first of the popes to come from a monastic background. Gregory is a
                        Doctor of the Church and
                        one of the Latin Fathers. He is considered a saint in the Catholic Church, Eastern Orthodox
                        Church, Anglican Communion,
                        and some Lutheran churches. Immediately after his death, Gregory was canonized by popular
                        acclaim.[4] The Protestant
                        reformer John Calvin admired Gregory and declared in his Institutes that Gregory was the last
                        good pope.[5] He is the
                        patron saint of musicians, singers, students, and teachers.</p>
                </div>
            </div>
        </div>
    </div>
</section>