<div id="myModal" class="modal fade" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body text-center">
                <div class="close-container" (click)="closeModal()">
                    <div class="leftright"></div>
                    <div class="rightleft"></div>
                </div>
                <h1>THIS SITE IS UNDER DEVELOPMENT</h1>
                <img src="assets/img/school/sghsc_logo.png" alt="sghsc logo" class="logoImg">
                <button class="btn old-btn" (click)="closeModal()">Continue Browsing</button>
            </div>
        </div>
    </div>
</div>