<app-navbar-style-three></app-navbar-style-three>

<section class="pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h5>Image Gallery</h5>
        </div>
        <!-- <a [routerLink]="['/imageGallery','true']">Route to self with param=True</a> |
        <a [routerLink]="['/imageGallery','false']">Route to self with param=false</a> -->
        <ng-container *ngIf="mainResponse.length > 0">

            <ng-container *ngIf="albums.length > 0">

                <div>
                    <h5>Folders</h5>
                </div>
                <div class="row">

                    <div class="col-lg-3 col-sm-6 mb-4"
                        *ngFor="let album of albums;">
                        <div style="border: 1px solid black; border-radius: 5px;">
                            <a (click)="Goto(album.Id)">

                                <div *ngIf="!album.ThumbnailLink"
                                    style="height: 200px;"
                                    class="d-flex flex-wrap justify-content-center align-items-center">
                                    <div class="text-center">
                                        <i class='bx bxs-folder-open text-center'
                                            style="font-size: 10rem;"></i>
                                    </div>
                                </div>

                                <div *ngIf="album.ThumbnailLink"
                                    style="position: relative; overflow: hidden; "
                                    class="text-center">
                                    <img [src]="album.ThumbnailLink"
                                        style="width: 200px; height: 200px;"
                                        class="img-fluid"
                                        alt="team">
                                </div>

                                <div class="text-center "
                                    style=" margin-top: 10px;">
                                    <span style="padding: 5px;">{{ album.Name }}</span>
                                </div>

                            </a>
                        </div>
                    </div>

                </div>
            </ng-container>

            <ng-container *ngIf="files.length > 0">

                <div>
                    <h5>Files</h5>
                </div>
                <div class="row">

                    <div class="col-lg-3 col-sm-6 mb-4"
                        *ngFor="let album of files;">
                        <div style="border: 1px solid black; border-radius: 5px;">
                            <!-- <a (click)="OpenFile(album.ImageLink)"> -->
                            <a [href]="album.ImageLink"
                                class="popup-btn">
                                <div *ngIf="!album.ImageLink"
                                    style="height: 200px;"
                                    class="d-flex flex-wrap justify-content-center align-items-center">
                                    <div class="text-center">
                                        <i class='bx bxs-folder-open text-center'
                                            style="font-size: 10rem;"></i>
                                    </div>
                                </div>

                                <div *ngIf="album.ImageLink"
                                    style="position: relative; overflow: hidden; "
                                    class="text-center">
                                    <img [src]="album.ImageLink"
                                        style="width: 200px; height: 200px;"
                                        class="img-fluid"
                                        alt="team">
                                </div>

                                <div class="text-center "
                                    style=" margin-top: 10px;">
                                    <span style="padding: 5px;">{{ album.ImagePath | nameGhange}}</span>
                                </div>

                            </a>
                        </div>
                    </div>

                </div>
            </ng-container>

        </ng-container>

        <ng-container *ngIf="mainResponse.length == 0">
            <div class="section-title">
                <span>No Album Data found</span>
            </div>
        </ng-container>

    </div>
</section>
