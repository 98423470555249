import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, ParamMap } from "@angular/router";
@Component({
    selector: "app-services",
    templateUrl: "./services.component.html",
    styleUrls: ["./services.component.scss"],
})
export class ServicesComponent implements OnInit {
    timelineData: any[] = [
        { month: "January", day: "19", event: "Freshman Students’ orientation" },
        { month: "January", day: "22", event: "First day of regular classes" },
        { month: "January", day: "26 & 29", event: "Adding/ Dropping" },
        {
            month: "January",
            day: "26",
            event: "Automatic conversion of UW, I, blank grades of Fall 2022-23 Semester to F",
        },
        {
            month: "February",
            day: "9",
            event: "Submission of TSF and course description",
        },
        {
            month: "February",
            day: "16 (Thursday)",
            event: "Makeup of Sunday Classes",
        },
        {
            month: "February",
            day: "18 (Saturday)",
            event: "Makeup of Monday Classes",
        },
        {
            month: "February",
            day: "7th Week\n26 – March 2",
            event: "Laboratory midterm exams",
        },
        { month: "March", day: "8th Week\n4 – 11", event: "Midterm Exam" },
        { month: "March", day: "18", event: "Submission of midterm grades" },
        { month: "March", day: "19 – 23", event: "TPE" },
        {
            month: "March",
            day: "23 (Thursday)",
            event: "Makeup of Tuesday Classes\n[online in MS Teams platform]",
        },
        {
            month: "March",
            day: "25 (Saturday)",
            event: "Makeup of Wednesday Classes\n[online in MS Teams platform]",
        },
        { month: "March", day: "23", event: "Midterm Grades Locked" },
        {
            month: "March",
            day: "25 – 30",
            event: "Pre-registration for Summer 2022-23 Semester",
        },
        { month: "March", day: "26/ 27", event: "Ramadan Class Timing starts" },
        {
            month: "April",
            day: "16th Week\n30 – May 4",
            event: "Laboratory Final exams",
        },
        { month: "`May`", day: "17th Week\n6 – 13", event: "Final Exam" },
        { month: "May", day: "20", event: "Submission of Final Grades" },
        { month: "May", day: "27", event: "Final Grades Locked" },
        {
            month: "May",
            day: "14 - May 27",
            event: "Semester break\nRelease of grades\nRegistration for Summer 2022-23",
        },
        {
            month: "June",
            day: "22",
            event: "Automatic conversion of UW, I grades of this semester to F",
        },
    ];
    academic: string[] = [
        "academicCalender",
        "academicRegulation",
        "curriculum",
        "teachersInformation",
        "staffInformation",
        "employeeInformation",
    ];

    selectedItem: string = "";

    camelCaseToTitleCase(name: string): string {
        // Convert the first character to uppercase
        let convertedName = name.charAt(0).toUpperCase() + name.slice(1);

        // Look for capital letters preceded by lowercase letters and insert a space
        convertedName = convertedName.replace(/([a-z])([A-Z])/g, "$1 $2");

        return convertedName;
    }

    constructor(private route: ActivatedRoute, private location: Location) {}

    ngOnInit() {
        this.route.paramMap.subscribe((params: ParamMap) => {
            const item = params.get("item");
            if (item && this.academic.includes(item)) {
                this.selectedItem = item;
            } else {
                this.selectedItem = this.academic[0];
                this.location.replaceState(`/academic/${this.selectedItem}`);
            }
        });
    }

    selectItem(item: string) {
        this.selectedItem = item;
        this.location.go(`/academic/${item}`);
    }
}
