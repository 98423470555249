import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, ParamMap } from "@angular/router";

@Component({
    selector: "app-blog",
    templateUrl: "./blog.component.html",
    styleUrls: ["./blog.component.scss"],
})
export class BlogComponent implements OnInit {
    newsandevents: string[] = ["2023", "2022", "2021", "2020", "2019"];

    selectedNewsYear: string = "";

    constructor(private route: ActivatedRoute, private location: Location) {}

    ngOnInit() {
        this.route.paramMap.subscribe((params: ParamMap) => {
            const news = params.get("news");
            if (news && this.newsandevents.includes(news)) {
                this.selectedNewsYear = news;
            } else {
                this.selectedNewsYear = this.newsandevents[0];
                this.location.replaceState(
                    `/newsandevents/${this.selectedNewsYear}`
                );
            }
        });
    }

    selectNewsYear(news: string) {
        this.selectedNewsYear = news;
        this.location.go(`/newsandevents/${news}`);
    }
}