import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutStyleTwoComponent } from './components/pages/about-style-two/about-style-two.component';
import { AboutComponent } from './components/pages/about/about.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { BlogRightSidebarComponent } from './components/pages/blog-right-sidebar/blog-right-sidebar.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { FeaturesComponent } from './components/pages/features/features.component';
import { HomeDemoEightComponent } from './components/pages/home-demo-eight/home-demo-eight.component';
import { HomeDemoElevenComponent } from './components/pages/home-demo-eleven/home-demo-eleven.component';
import { HomeDemoFiveComponent } from './components/pages/home-demo-five/home-demo-five.component';
import { HomeDemoFourComponent } from './components/pages/home-demo-four/home-demo-four.component';
import { HomeDemoNineComponent } from './components/pages/home-demo-nine/home-demo-nine.component';
import { HomeDemoSevenComponent } from './components/pages/home-demo-seven/home-demo-seven.component';
import { HomeDemoSixComponent } from './components/pages/home-demo-six/home-demo-six.component';
import { HomeDemoTenComponent } from './components/pages/home-demo-ten/home-demo-ten.component';
import { HomeDemoThreeComponent } from './components/pages/home-demo-three/home-demo-three.component';
import { HomeDemoTwelveComponent } from './components/pages/home-demo-twelve/home-demo-twelve.component';
import { HomeDemoTwoComponent } from './components/pages/home-demo-two/home-demo-two.component';
import { HomeComponent } from './components/pages/home/home.component';
import { LoginComponent } from './components/pages/login/login.component';
import { NotFoundComponent } from './components/pages/not-found/not-found.component';
import { NoticeComponent } from './components/pages/notice/notice.component';
import { PartnerComponent } from './components/pages/partner/partner.component';
import { PricingComponent } from './components/pages/pricing/pricing.component';
import { ProjectDetailsComponent } from './components/pages/project-details/project-details.component';
import { ProjectsStyleFourComponent } from './components/pages/projects-style-four/projects-style-four.component';
import { ProjectsStyleThreeComponent } from './components/pages/projects-style-three/projects-style-three.component';
import { ProjectsStyleTwoComponent } from './components/pages/projects-style-two/projects-style-two.component';
import { ProjectsComponent } from './components/pages/projects/projects.component';
import { RegisterComponent } from './components/pages/register/register.component';
import { ServiceDetailsComponent } from './components/pages/service-details/service-details.component';
import { ServicesStyleThreeComponent } from './components/pages/services-style-three/services-style-three.component';
import { ServicesStyleTwoComponent } from './components/pages/services-style-two/services-style-two.component';
import { ServicesComponent } from './components/pages/services/services.component';
import { TeamComponent } from './components/pages/team/team.component';
import { ImageGalleryDemoComponent } from './components/pages/image-gallery-demo/image-gallery-demo.component';

const routes: Routes = [
  { path: '', component: HomeComponent }, // Home page
  // { path: "why-study-here", component: HomeDemoTwoComponent }, // about - why study here
  // { path: 'staff-information', component: HomeDemoThreeComponent }, // academic - staff information
  // { path: "mission-and-vision", component: HomeDemoFourComponent }, // about - mission & vision
  // { path: "staff-information", component: HomeDemoFiveComponent }, // about - staff information
  // { path: "employee-information", component: HomeDemoSixComponent }, // about - employee information
  { path: 'on-campus', component: HomeDemoSevenComponent }, // On Campus
  { path: 'visitors', component: HomeDemoEightComponent }, // visitors
  // { path: "history", component: HomeDemoNineComponent }, // about - history
  {
    path: 'about',
    children: [
      { path: '', component: HomeDemoNineComponent },
      { path: ':item', component: HomeDemoNineComponent },
    ],
  },
  { path: 'student', component: HomeDemoTenComponent }, // future students
  // { path: "chairman-message", component: HomeDemoElevenComponent }, // administration - chairman message
  { path: 'contact', component: HomeDemoTwelveComponent }, // nav bar contact
  // { path: "about", component: AboutComponent }, // about - saint gregory
  // { path: "about-2", component: AboutStyleTwoComponent },
  {
    path: 'academic',
    children: [
      { path: '', component: ServicesComponent },
      { path: ':item', component: ServicesComponent },
    ],
  },
  // { path: "academic-regulation", component: ServicesStyleTwoComponent }, // academic - Academic Regulation
  // { path: "services-3", component: ServicesStyleThreeComponent },
  // { path: "teachers-information", component: ServiceDetailsComponent }, // academic - Teachers Information
  // { path: 'projects', component: ProjectsComponent },
  // { path: 'projects-2', component: ProjectsStyleTwoComponent },
  // { path: 'projects-3', component: ProjectsStyleThreeComponent },
  // { path: 'projects-4', component: ProjectsStyleFourComponent },
  // { path: 'project-details', component: ProjectDetailsComponent },
  {
    path: 'newsandevents',
    children: [
      { path: '', component: BlogComponent },
      { path: ':news', component: BlogComponent },
    ],
  },
  // { path: 'blog-2', component: BlogRightSidebarComponent },
  { path: 'under-construction', component: BlogDetailsComponent }, // under construction
  {
    path: 'administration',
    children: [
      { path: '', component: FeaturesComponent },
      { path: ':item', component: FeaturesComponent },
    ],
  },
  // { path: "legends-gregorian", component: TeamComponent }, // about - legends gregorian
  { path: 'notice', component: NoticeComponent },
  // { path: "principal-message", component: PricingComponent }, // administration - principal message
  // { path: "vice-principal-message", component: PartnerComponent }, // administration - vp
  {
    path: 'clubs',
    children: [
      { path: '', component: FaqComponent },
      { path: ':club', component: FaqComponent },
    ],
  },
  // { path: "former-principal", component: LoginComponent }, // administration - former principal
  { path: 'admission-details', component: RegisterComponent },
  { path: 'admission', component: ContactComponent }, //admission

  { path: 'imageGallery', component: ImageGalleryDemoComponent }, //imageGallery
  { path: 'imageGallery/:someParam', component: ImageGalleryDemoComponent }, //imageGallery

  // Here add new pages component

  { path: '**', component: NotFoundComponent }, // This line will remain down from the whole pages component list
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
