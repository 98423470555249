<app-navbar-style-three></app-navbar-style-three>

<!-- Start Page Title Area -->
<div class="page-title-area bg-image" style="height: 250px;">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Contact Us</h2>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Contact Area -->
<section class="contact-area ptb-100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="contact-info-box">
                    <div class="icon">
                        <i class="flaticon-email"></i>
                    </div>
                    <h3>Email</h3>
                    <a href="mailto:principalsghscdhaka@gmail.com">principalsghscdhaka@gmail.com</a><br>
                    <a href="mailto:hmsghs@gmail.com">hmsghs@gmail.com</a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="contact-info-box">
                    <div class="icon">
                        <i class="flaticon-marker"></i>
                    </div>
                    <h3>Location</h3>
                    <a href="https://goo.gl/maps/MZXSuT6RPP9HwfFH7" target="_blank">82, Municipal Office Street,
                        Luxmibazar, Dhaka-1100,
                        Bangladesh</a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="contact-info-box">
                    <div class="icon">
                        <i class="flaticon-phone-call"></i>
                    </div>
                    <h3>Call</h3>
                    <!-- <a href="tel:+8801309108497">+8801309108497</a> <br> -->
                    <a href="tel:+88247113687">+88 247113687</a>
                </div>
            </div>
        </div>


    </div>
</section>
<!-- End Contact Area -->
