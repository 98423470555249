<app-navbar-style-three></app-navbar-style-three>

<div class="bg-image">
    <div class="container" style="padding-top: 30px; padding-bottom: 30px;">
        <div class="row">
            <div class="col-md-3">
                <div align="left" style="padding-left: 20px">
                    <p style="font-size: 26px">Clubs</p>
                </div>
                <!-- <div class="list-group list-group-flush">
                <button *ngFor="let club of clubs" (click)="selectClub(club)" [class.active]="selectClub === club"
                    class="newA linkStyle list-group-item list-group-item-action">{{club}}</button>
            </div> -->

                <div class="list-group list-group-flush">
                    <button *ngFor="let club of clubs" (click)="selectClub(club)" [class.active]="selectedClub === club"
                        class="newA linkStyle list-group-item list-group-item-action">{{camelCaseToTitleCase(club)}}</button>
                </div>

                <!-- <div class="list-group list-group-flush">
                <button (click)="selectClub('englishClub')" [class.active]="selectedClub === 'englishClub'"
                    class="newA linkStyle list-group-item list-group-item-action">English Club</button>
                <button (click)="selectClub('ycsClub')" [class.active]="selectedClub === 'ycsClub'"
                    class="newA linkStyle list-group-item list-group-item-action">YCS Club</button>
                <button (click)="selectClub('sportsClub')" [class.active]="selectedClub === 'sportsClub'"
                    class="newA linkStyle list-group-item list-group-item-action">Sports Club</button>
                <button (click)="selectClub('quizMathClub')" [class.active]="selectedClub === 'quizMathClub'"
                    class="newA linkStyle list-group-item list-group-item-action">Quiz Club & Math Club</button>
                <button (click)="selectClub('scienceClub')" [class.active]="selectedClub === 'scienceClub'"
                    class="newA linkStyle list-group-item list-group-item-action">Science Club</button>
                <button (click)="selectClub('artHandwritingClub')"
                    [class.active]="selectedClub === 'artHandwritingClub'"
                    class="newA linkStyle list-group-item list-group-item-action">Art & Handwriting Club</button>
                <button (click)="selectClub('ecologyClub')" [class.active]="selectedClub === 'ecologyClub'"
                    class="newA linkStyle list-group-item list-group-item-action">Ecology Club</button>
                <button (click)="selectClub('artClub')" [class.active]="selectedClub === 'artClub'"
                    class="newA linkStyle list-group-item list-group-item-action">Art Club</button>
                <button (click)="selectClub('chessClub')" [class.active]="selectedClub === 'chessClub'"
                    class="newA linkStyle list-group-item list-group-item-action">Chess Club</button>
                <button (click)="selectClub('debateClub')" [class.active]="selectedClub === 'debateClub'"
                    class="newA linkStyle list-group-item list-group-item-action">Debate Club</button>
                <button (click)="selectClub('knowledgeFiestaClub')"
                    [class.active]="selectedClub === 'knowledgeFiestaClub'"
                    class="newA linkStyle list-group-item list-group-item-action">Knowledge Fiesta Club</button>
                <button (click)="selectClub('scoutClub')"
                    [class.active]="selectedClub === 'scoutClub'"
                    class="newA linkStyle list-group-item list-group-item-action">Scout Club</button>
            </div> -->
            </div>
            <div class="col-md-9">
                <div [hidden]="selectedClub !== 'englishClub'">
                    <img class="img-fluid" style="height: 300px; width: 100%; object-fit: cover; border-radius: 10px;"
                        src="assets/img/school/clubs/1.jpg">
                    <div class="row" style="margin-top: 20px;">
                        <div class="faq-accordion">
                            <ul class="accordion">
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)"> <i class="fas fa-plus"></i>
                                        English 2023 </a>
                                    <section class="blog-area gallery-section accordion-content">
                                        <div class="container">
                                            <div class="gallery-container">
                                                <div class="gallery">
                                                    <a *ngFor="let image of english23" class="gallery__item"
                                                        href="{{ image.src }}" [title]="image.alt"> <img
                                                            [src]="image.thumbnail" [alt]="image.alt"
                                                            class="popup-btn" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <p style="border: 1px solid black; margin-top: 10px; padding: 5px;">Lorem ipsum
                                            dolor sit amet,
                                            consectetur adipiscing elit, sed do eiusmod
                                            tempor incididunt ut labore et dolore magna aliqua. Quis ipsum
                                            suspendisse ultrices gravida.</p>
                                    </section>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)"> <i class="fas fa-plus"></i>
                                        English 2022 </a>
                                    <section class="blog-area gallery-section accordion-content">
                                        <div class="container">
                                            <div class="gallery-container">
                                                <div class="gallery">
                                                    <a *ngFor="let image of english22" class="gallery__item"
                                                        href="{{ image.src }}" [title]="image.alt"> <img
                                                            [src]="image.thumbnail" [alt]="image.alt"
                                                            class="popup-btn" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <p style="border: 1px solid black; margin-top: 10px; padding: 5px;">Lorem ipsum
                                            dolor sit amet,
                                            consectetur adipiscing elit, sed do eiusmod
                                            tempor incididunt ut labore et dolore magna aliqua. Quis ipsum
                                            suspendisse ultrices gravida.</p>
                                    </section>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div [hidden]="selectedClub !== 'ycsClub'">
                    <img class="img-fluid" style="height: 300px; width: 100%; object-fit: cover; border-radius: 10px;"
                        src="assets/img/school/clubs/2.jpg">
                    <div class="row" style="margin-top: 20px;">
                        <div class="faq-accordion">
                            <ul class="accordion">
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)"> <i class="fas fa-plus"></i>
                                        YCS
                                        2023 </a>
                                    <section class="blog-area gallery-section accordion-content">
                                        <div class="container">
                                            <div class="gallery-container">
                                                <div class="gallery">
                                                    <a *ngFor="let image of english23" class="gallery__item"
                                                        href="{{ image.src }}" [title]="image.alt"> <img
                                                            [src]="image.thumbnail" [alt]="image.alt"
                                                            class="popup-btn" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <p style="border: 1px solid black; margin-top: 10px; padding: 5px;">Lorem ipsum
                                            dolor sit amet,
                                            consectetur adipiscing elit, sed do eiusmod
                                            tempor incididunt ut labore et dolore magna aliqua. Quis ipsum
                                            suspendisse ultrices gravida.</p>
                                    </section>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)"> <i class="fas fa-plus"></i>
                                        YCS 2022 </a>
                                    <section class="blog-area gallery-section accordion-content">
                                        <div class="container">
                                            <div class="gallery-container">
                                                <div class="gallery">
                                                    <a *ngFor="let image of english22" class="gallery__item"
                                                        href="{{ image.src }}" [title]="image.alt"> <img
                                                            [src]="image.thumbnail" [alt]="image.alt"
                                                            class="popup-btn" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <p style="border: 1px solid black; margin-top: 10px; padding: 5px;">Lorem ipsum
                                            dolor sit amet,
                                            consectetur adipiscing elit, sed do eiusmod
                                            tempor incididunt ut labore et dolore magna aliqua. Quis ipsum
                                            suspendisse ultrices gravida.</p>
                                    </section>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div [hidden]="selectedClub !== 'sportsClub'">
                    <img class="img-fluid" style="height: 300px; width: 100%; object-fit: cover; border-radius: 10px;"
                        src="assets/img/school/clubs/3.jpg">
                    <div class="row" style="margin-top: 20px;">
                        <div class="faq-accordion">
                            <ul class="accordion">
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)"> <i class="fas fa-plus"></i>
                                        Sports Club 2023 </a>
                                    <section class="blog-area gallery-section accordion-content">
                                        <div class="container">
                                            <div class="gallery-container">
                                                <div class="gallery">
                                                    <a *ngFor="let image of english23" class="gallery__item"
                                                        href="{{ image.src }}" [title]="image.alt"> <img
                                                            [src]="image.thumbnail" [alt]="image.alt"
                                                            class="popup-btn" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <p style="border: 1px solid black; margin-top: 10px; padding: 5px;">Lorem ipsum
                                            dolor sit amet,
                                            consectetur adipiscing elit, sed do eiusmod
                                            tempor incididunt ut labore et dolore magna aliqua. Quis ipsum
                                            suspendisse ultrices gravida.</p>
                                    </section>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)"> <i class="fas fa-plus"></i>
                                        Sports Club 2022 </a>
                                    <section class="blog-area gallery-section accordion-content">
                                        <div class="container">
                                            <div class="gallery-container">
                                                <div class="gallery">
                                                    <a *ngFor="let image of english22" class="gallery__item"
                                                        href="{{ image.src }}" [title]="image.alt"> <img
                                                            [src]="image.thumbnail" [alt]="image.alt"
                                                            class="popup-btn" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <p style="border: 1px solid black; margin-top: 10px; padding: 5px;">Lorem ipsum
                                            dolor sit amet,
                                            consectetur adipiscing elit, sed do eiusmod
                                            tempor incididunt ut labore et dolore magna aliqua. Quis ipsum
                                            suspendisse ultrices gravida.</p>
                                    </section>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div [hidden]="selectedClub !== 'quizMathClub'">
                    <img class="img-fluid" style="height: 300px; width: 100%; object-fit: cover; border-radius: 10px;"
                        src="assets/img/school/clubs/4.jpg">
                    <div class="row" style="margin-top: 20px;">
                        <div class="faq-accordion">
                            <ul class="accordion">
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)"> <i class="fas fa-plus"></i>
                                        Quiz Club & Math Club 2023 </a>
                                    <section class="blog-area gallery-section accordion-content">
                                        <div class="container">
                                            <div class="gallery-container">
                                                <div class="gallery">
                                                    <a *ngFor="let image of english23" class="gallery__item"
                                                        href="{{ image.src }}" [title]="image.alt"> <img
                                                            [src]="image.thumbnail" [alt]="image.alt"
                                                            class="popup-btn" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <p style="border: 1px solid black; margin-top: 10px; padding: 5px;">Lorem ipsum
                                            dolor sit amet,
                                            consectetur adipiscing elit, sed do eiusmod
                                            tempor incididunt ut labore et dolore magna aliqua. Quis ipsum
                                            suspendisse ultrices gravida.</p>
                                    </section>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)"> <i class="fas fa-plus"></i>
                                        Quiz Club & Math Club 2022 </a>
                                    <section class="blog-area gallery-section accordion-content">
                                        <div class="container">
                                            <div class="gallery-container">
                                                <div class="gallery">
                                                    <a *ngFor="let image of english22" class="gallery__item"
                                                        href="{{ image.src }}" [title]="image.alt"> <img
                                                            [src]="image.thumbnail" [alt]="image.alt"
                                                            class="popup-btn" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <p style="border: 1px solid black; margin-top: 10px; padding: 5px;">Lorem ipsum
                                            dolor sit amet,
                                            consectetur adipiscing elit, sed do eiusmod
                                            tempor incididunt ut labore et dolore magna aliqua. Quis ipsum
                                            suspendisse ultrices gravida.</p>
                                    </section>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div [hidden]="selectedClub !== 'scienceClub'">
                    <img class="img-fluid" style="height: 300px; width: 100%; object-fit: cover; border-radius: 10px;"
                        src="assets/img/school/clubs/5.jpg">
                    <div class="row" style="margin-top: 20px;">
                        <div class="faq-accordion">
                            <ul class="accordion">
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)"> <i class="fas fa-plus"></i>
                                        Science 2023 </a>
                                    <section class="blog-area gallery-section accordion-content">
                                        <div class="container">
                                            <div class="gallery-container">
                                                <div class="gallery">
                                                    <a *ngFor="let image of english23" class="gallery__item"
                                                        href="{{ image.src }}" [title]="image.alt"> <img
                                                            [src]="image.thumbnail" [alt]="image.alt"
                                                            class="popup-btn" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <p style="border: 1px solid black; margin-top: 10px; padding: 5px;">Lorem ipsum
                                            dolor sit amet,
                                            consectetur adipiscing elit, sed do eiusmod
                                            tempor incididunt ut labore et dolore magna aliqua. Quis ipsum
                                            suspendisse ultrices gravida.</p>
                                    </section>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)"> <i class="fas fa-plus"></i>
                                        Science 2022 </a>
                                    <section class="blog-area gallery-section accordion-content">
                                        <div class="container">
                                            <div class="gallery-container">
                                                <div class="gallery">
                                                    <a *ngFor="let image of english22" class="gallery__item"
                                                        href="{{ image.src }}" [title]="image.alt"> <img
                                                            [src]="image.thumbnail" [alt]="image.alt"
                                                            class="popup-btn" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <p style="border: 1px solid black; margin-top: 10px; padding: 5px;">Lorem ipsum
                                            dolor sit amet,
                                            consectetur adipiscing elit, sed do eiusmod
                                            tempor incididunt ut labore et dolore magna aliqua. Quis ipsum
                                            suspendisse ultrices gravida.</p>
                                    </section>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div [hidden]="selectedClub !== 'artHandwritingClub'">
                    <img class="img-fluid" style="height: 300px; width: 100%; object-fit: cover; border-radius: 10px;"
                        src="assets/img/school/clubs/6.jpg">
                    <div class="row" style="margin-top: 20px;">
                        <div class="faq-accordion">
                            <ul class="accordion">
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)"> <i class="fas fa-plus"></i>
                                        Art & Handwriting Club 2023 </a>
                                    <section class="blog-area gallery-section accordion-content">
                                        <div class="container">
                                            <div class="gallery-container">
                                                <div class="gallery">
                                                    <a *ngFor="let image of english23" class="gallery__item"
                                                        href="{{ image.src }}" [title]="image.alt"> <img
                                                            [src]="image.thumbnail" [alt]="image.alt"
                                                            class="popup-btn" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <p style="border: 1px solid black; margin-top: 10px; padding: 5px;">Lorem ipsum
                                            dolor sit amet,
                                            consectetur adipiscing elit, sed do eiusmod
                                            tempor incididunt ut labore et dolore magna aliqua. Quis ipsum
                                            suspendisse ultrices gravida.</p>
                                    </section>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)"> <i class="fas fa-plus"></i>
                                        Art & Handwriting Club 2022 </a>
                                    <section class="blog-area gallery-section accordion-content">
                                        <div class="container">
                                            <div class="gallery-container">
                                                <div class="gallery">
                                                    <a *ngFor="let image of english22" class="gallery__item"
                                                        href="{{ image.src }}" [title]="image.alt"> <img
                                                            [src]="image.thumbnail" [alt]="image.alt"
                                                            class="popup-btn" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <p style="border: 1px solid black; margin-top: 10px; padding: 5px;">Lorem ipsum
                                            dolor sit amet,
                                            consectetur adipiscing elit, sed do eiusmod
                                            tempor incididunt ut labore et dolore magna aliqua. Quis ipsum
                                            suspendisse ultrices gravida.</p>
                                    </section>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div [hidden]="selectedClub !== 'ecologyClub'">
                    <img class="img-fluid" style="height: 300px; width: 100%; object-fit: cover; border-radius: 10px;"
                        src="assets/img/school/clubs/7.jpg">
                    <div class="row" style="margin-top: 20px;">
                        <div class="faq-accordion">
                            <ul class="accordion">
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)"> <i class="fas fa-plus"></i>
                                        Ecology Club 2023 </a>
                                    <section class="blog-area gallery-section accordion-content">
                                        <div class="container">
                                            <div class="gallery-container">
                                                <div class="gallery">
                                                    <a *ngFor="let image of english23" class="gallery__item"
                                                        href="{{ image.src }}" [title]="image.alt"> <img
                                                            [src]="image.thumbnail" [alt]="image.alt"
                                                            class="popup-btn" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <p style="border: 1px solid black; margin-top: 10px; padding: 5px;">Lorem ipsum
                                            dolor sit amet,
                                            consectetur adipiscing elit, sed do eiusmod
                                            tempor incididunt ut labore et dolore magna aliqua. Quis ipsum
                                            suspendisse ultrices gravida.</p>
                                    </section>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)"> <i class="fas fa-plus"></i>
                                        Ecology Club 2022 </a>
                                    <section class="blog-area gallery-section accordion-content">
                                        <div class="container">
                                            <div class="gallery-container">
                                                <div class="gallery">
                                                    <a *ngFor="let image of english22" class="gallery__item"
                                                        href="{{ image.src }}" [title]="image.alt"> <img
                                                            [src]="image.thumbnail" [alt]="image.alt"
                                                            class="popup-btn" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <p style="border: 1px solid black; margin-top: 10px; padding: 5px;">Lorem ipsum
                                            dolor sit amet,
                                            consectetur adipiscing elit, sed do eiusmod
                                            tempor incididunt ut labore et dolore magna aliqua. Quis ipsum
                                            suspendisse ultrices gravida.</p>
                                    </section>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div [hidden]="selectedClub !== 'artClub'">
                    <img class="img-fluid" style="height: 300px; width: 100%; object-fit: cover; border-radius: 10px;"
                        src="assets/img/school/clubs/8.jpg">
                    <div class="row" style="margin-top: 20px;">
                        <div class="faq-accordion">
                            <ul class="accordion">
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)"> <i class="fas fa-plus"></i>
                                        Art Club 2023 </a>
                                    <section class="blog-area gallery-section accordion-content">
                                        <div class="container">
                                            <div class="gallery-container">
                                                <div class="gallery">
                                                    <a *ngFor="let image of english23" class="gallery__item"
                                                        href="{{ image.src }}" [title]="image.alt"> <img
                                                            [src]="image.thumbnail" [alt]="image.alt"
                                                            class="popup-btn" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <p style="border: 1px solid black; margin-top: 10px; padding: 5px;">Lorem ipsum
                                            dolor sit amet,
                                            consectetur adipiscing elit, sed do eiusmod
                                            tempor incididunt ut labore et dolore magna aliqua. Quis ipsum
                                            suspendisse ultrices gravida.</p>
                                    </section>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)"> <i class="fas fa-plus"></i>
                                        Art Club 2022 </a>
                                    <section class="blog-area gallery-section accordion-content">
                                        <div class="container">
                                            <div class="gallery-container">
                                                <div class="gallery">
                                                    <a *ngFor="let image of english22" class="gallery__item"
                                                        href="{{ image.src }}" [title]="image.alt"> <img
                                                            [src]="image.thumbnail" [alt]="image.alt"
                                                            class="popup-btn" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <p style="border: 1px solid black; margin-top: 10px; padding: 5px;">Lorem ipsum
                                            dolor sit amet,
                                            consectetur adipiscing elit, sed do eiusmod
                                            tempor incididunt ut labore et dolore magna aliqua. Quis ipsum
                                            suspendisse ultrices gravida.</p>
                                    </section>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div [hidden]="selectedClub !== 'chessClub'">
                    <img class="img-fluid" style="height: 300px; width: 100%; object-fit: cover; border-radius: 10px;"
                        src="assets/img/school/clubs/9.jpg">
                    <div class="row" style="margin-top: 20px;">
                        <div class="faq-accordion">
                            <ul class="accordion">
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)"> <i class="fas fa-plus"></i>
                                        Chess Club 2023 </a>
                                    <section class="blog-area gallery-section accordion-content">
                                        <div class="container">
                                            <div class="gallery-container">
                                                <div class="gallery">
                                                    <a *ngFor="let image of english23" class="gallery__item"
                                                        href="{{ image.src }}" [title]="image.alt"> <img
                                                            [src]="image.thumbnail" [alt]="image.alt"
                                                            class="popup-btn" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <p style="border: 1px solid black; margin-top: 10px; padding: 5px;">Lorem ipsum
                                            dolor sit amet,
                                            consectetur adipiscing elit, sed do eiusmod
                                            tempor incididunt ut labore et dolore magna aliqua. Quis ipsum
                                            suspendisse ultrices gravida.</p>
                                    </section>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)"> <i class="fas fa-plus"></i>
                                        Chess Club 2022 </a>
                                    <section class="blog-area gallery-section accordion-content">
                                        <div class="container">
                                            <div class="gallery-container">
                                                <div class="gallery">
                                                    <a *ngFor="let image of english22" class="gallery__item"
                                                        href="{{ image.src }}" [title]="image.alt"> <img
                                                            [src]="image.thumbnail" [alt]="image.alt"
                                                            class="popup-btn" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <p style="border: 1px solid black; margin-top: 10px; padding: 5px;">Lorem ipsum
                                            dolor sit amet,
                                            consectetur adipiscing elit, sed do eiusmod
                                            tempor incididunt ut labore et dolore magna aliqua. Quis ipsum
                                            suspendisse ultrices gravida.</p>
                                    </section>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div [hidden]="selectedClub !== 'debateClub'">
                    <img class="img-fluid" style="height: 300px; width: 100%; object-fit: cover; border-radius: 10px;"
                        src="assets/img/school/clubs/1.jpg">
                    <div class="row" style="margin-top: 20px;">
                        <div class="faq-accordion">
                            <ul class="accordion">
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)"> <i class="fas fa-plus"></i>
                                        Debate Club 2023 </a>
                                    <section class="blog-area gallery-section accordion-content">
                                        <div class="container">
                                            <div class="gallery-container">
                                                <div class="gallery">
                                                    <a *ngFor="let image of english23" class="gallery__item"
                                                        href="{{ image.src }}" [title]="image.alt"> <img
                                                            [src]="image.thumbnail" [alt]="image.alt"
                                                            class="popup-btn" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <p style="border: 1px solid black; margin-top: 10px; padding: 5px;">Lorem ipsum
                                            dolor sit amet,
                                            consectetur adipiscing elit, sed do eiusmod
                                            tempor incididunt ut labore et dolore magna aliqua. Quis ipsum
                                            suspendisse ultrices gravida.</p>
                                    </section>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)"> <i class="fas fa-plus"></i>
                                        Debate Club 2022 </a>
                                    <section class="blog-area gallery-section accordion-content">
                                        <div class="container">
                                            <div class="gallery-container">
                                                <div class="gallery">
                                                    <a *ngFor="let image of english22" class="gallery__item"
                                                        href="{{ image.src }}" [title]="image.alt"> <img
                                                            [src]="image.thumbnail" [alt]="image.alt"
                                                            class="popup-btn" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <p style="border: 1px solid black; margin-top: 10px; padding: 5px;">Lorem ipsum
                                            dolor sit amet,
                                            consectetur adipiscing elit, sed do eiusmod
                                            tempor incididunt ut labore et dolore magna aliqua. Quis ipsum
                                            suspendisse ultrices gravida.</p>
                                    </section>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div [hidden]="selectedClub !== 'knowledgeFiestaClub'">
                    <img class="img-fluid" style="height: 300px; width: 100%; object-fit: cover; border-radius: 10px;"
                        src="assets/img/school/clubs/2.jpg">
                    <div class="row" style="margin-top: 20px;">
                        <div class="faq-accordion">
                            <ul class="accordion">
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)"> <i class="fas fa-plus"></i>
                                        Knowledge Fiesta Club 2023 </a>
                                    <section class="blog-area gallery-section accordion-content">
                                        <div class="container">
                                            <div class="gallery-container">
                                                <div class="gallery">
                                                    <a *ngFor="let image of english23" class="gallery__item"
                                                        href="{{ image.src }}" [title]="image.alt"> <img
                                                            [src]="image.thumbnail" [alt]="image.alt"
                                                            class="popup-btn" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <p style="border: 1px solid black; margin-top: 10px; padding: 5px;">Lorem ipsum
                                            dolor sit amet,
                                            consectetur adipiscing elit, sed do eiusmod
                                            tempor incididunt ut labore et dolore magna aliqua. Quis ipsum
                                            suspendisse ultrices gravida.</p>
                                    </section>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)"> <i class="fas fa-plus"></i>
                                        Knowledge Fiesta Club 2022 </a>
                                    <section class="blog-area gallery-section accordion-content">
                                        <div class="container">
                                            <div class="gallery-container">
                                                <div class="gallery">
                                                    <a *ngFor="let image of english22" class="gallery__item"
                                                        href="{{ image.src }}" [title]="image.alt"> <img
                                                            [src]="image.thumbnail" [alt]="image.alt"
                                                            class="popup-btn" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <p style="border: 1px solid black; margin-top: 10px; padding: 5px;">Lorem ipsum
                                            dolor sit amet,
                                            consectetur adipiscing elit, sed do eiusmod
                                            tempor incididunt ut labore et dolore magna aliqua. Quis ipsum
                                            suspendisse ultrices gravida.</p>
                                    </section>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div [hidden]="selectedClub !== 'scoutClub'">
                    <img class="img-fluid" style="height: 300px; width: 100%; object-fit: cover; border-radius: 10px;"
                        src="assets/img/school/clubs/3.jpg">
                    <div class="row" style="margin-top: 20px;">
                        <div class="faq-accordion">
                            <ul class="accordion">
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)"> <i class="fas fa-plus"></i>
                                        Scout Club 2023 </a>
                                    <section class="blog-area gallery-section accordion-content">
                                        <div class="container">
                                            <div class="gallery-container">
                                                <div class="gallery">
                                                    <a *ngFor="let image of english23" class="gallery__item"
                                                        href="{{ image.src }}" [title]="image.alt"> <img
                                                            [src]="image.thumbnail" [alt]="image.alt"
                                                            class="popup-btn" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <p style="border: 1px solid black; margin-top: 10px; padding: 5px;">Lorem ipsum
                                            dolor sit amet,
                                            consectetur adipiscing elit, sed do eiusmod
                                            tempor incididunt ut labore et dolore magna aliqua. Quis ipsum
                                            suspendisse ultrices gravida.</p>
                                    </section>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)"> <i class="fas fa-plus"></i>
                                        Scout Club 2022 </a>
                                    <section class="blog-area gallery-section accordion-content">
                                        <div class="container">
                                            <div class="gallery-container">
                                                <div class="gallery">
                                                    <a *ngFor="let image of english22" class="gallery__item"
                                                        href="{{ image.src }}" [title]="image.alt"> <img
                                                            [src]="image.thumbnail" [alt]="image.alt"
                                                            class="popup-btn" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <p style="border: 1px solid black; margin-top: 10px; padding: 5px;">Lorem ipsum
                                            dolor sit amet,
                                            consectetur adipiscing elit, sed do eiusmod
                                            tempor incididunt ut labore et dolore magna aliqua. Quis ipsum
                                            suspendisse ultrices gravida.</p>
                                    </section>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>