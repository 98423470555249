<app-navbar-style-three></app-navbar-style-three>

<!-- Carousel Start -->
<div id="carouselExampleCaptions"
  class="carousel slide"
  data-bs-ride="carousel">
  <div class="carousel-indicators">
    <button *ngFor="let slide of slides; let i = index"
      type="button"
      data-bs-target="#carouselExampleCaptions"
      [attr.data-bs-slide-to]="i"
      [class.active]="i === 0"
      [attr.aria-current]="i === 0 ? 'true' : null"
      [attr.aria-label]="'Slide ' + (i + 1)"></button>
  </div>
  <div class="carousel-inner">
    <div *ngFor="let slide of slides; let i = index"
      class="carousel-item main-carousel fist-carousel"
      [class.active]="i === 0">
      <img [src]="slide.imgSrc"
        class="d-block w-100 h-100 imgH"
        alt="Slide {{ i + 1 }}" />
      <div class="carousel-caption d-none d-md-block">
        <p style="color: white">{{ slide.caption }}</p>
      </div>
    </div>
  </div>
  <button class="carousel-control-prev"
    type="button"
    data-bs-target="#carouselExampleCaptions"
    data-bs-slide="prev">
    <span class="carousel-control-prev-icon"
      aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next"
    type="button"
    data-bs-target="#carouselExampleCaptions"
    data-bs-slide="next">
    <span class="carousel-control-next-icon"
      aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
<!-- Carousel End -->

<div class="bg-image">
  <!-- welcome & youtube start -->
  <section class="about-area">
    <div class="container">
      <div class="row parent">
        <div class="col-lg-6 col-md-12 child">
          <aside class="widget-area">
            <section class="widget widget_karlson_posts_thumb">
              <h3 class="widget-title"
                style="color: #327ab5">
                Welcome to St. Gregory's High School & College
              </h3>
              <p style="text-align: justify !important">
                <ng-container *ngIf="showFullContent; else partialContent">
                  The school was founded in 1882 by Rev. Father Gregory De
                  Groote, a Belgian Benedictine priest. St. Gregory's has a long
                  and distinguished history, with many notable alumni, including
                  the first Prime Minister of Bangladesh, Tajuddin Ahmed, and
                  the 13th President of Bangladesh, AQM Badruddoza Chowdhury.
                  <br />
                  The article discusses the school's academic and co-curricular
                  achievements. In the field of academics, St. Gregory's has a
                  long history of producing top-performing students. In the
                  1930s, the school produced a number of top-ranking students in
                  the Board Examination, including Dalim Guha, who topped the
                  list in 1934. In more recent years, the school has continued
                  to produce high-performing students, with many students
                  achieving GPA 5 in the SSC examination. <br />
                  For example, in 2015, 211 students from St. Gregory's were
                  sent for the SSC examination, and all of them passed with 100%
                  results. This is a testament to the school's strong academic
                  foundation and the dedication of its teachers. <br />
                  In the field of co-curricular activities, St. Gregory's has
                  also had a long history of success. The school was one of the
                  first schools in Dhaka to start a Scout Troop, and the troop
                  has gone on to achieve many successes. The school also has a
                  strong Cadet Corps, and many of the cadets have gone on to
                  serve in the military. <br />
                  For example, in 2023, two scouts from St. Gregory's, Imtiaz
                  Ahmed Sakib and Taufiqul Alam, were nominated to receive
                  President's Scout Award for their excellent performance. This
                  is a testament to the school's commitment to providing its
                  students with opportunities to develop their leadership and
                  teamwork skills. <br />
                  The article concludes by discussing the school's current
                  co-curricular activities. The school offers a wide range of
                  activities, including sports, music, drama, and debate. The
                  article also mentions the Gregorian Science Club, which was
                  the first club in the history of the school. <br />
                  We hope you enjoy reading this article about St. Gregory's
                  High School! If you have any questions, please feel free to
                  contact us. <br />
                  <button (click)="toggleShowContent()"
                    class="btn readMoreLess-btn">
                    Read Less
                  </button>
                </ng-container>
                <ng-template #partialContent>
                  The school was founded in 1882 by Rev. Father Gregory De
                  Groote, a Belgian Benedictine priest. St. Gregory's has a long
                  and distinguished history, with many notable alumni, including
                  the first Prime Minister of Bangladesh, Tajuddin Ahmed, and
                  the 13th President of Bangladesh, AQM Badruddoza Chowdhury.
                  <br />
                  The article discusses the school's academic and co-curricular
                  achievements. In the field of academics, St. Gregory's has a
                  long history of producing top-performing students. In the
                  1930s, the school produced a number of top-ranking students in
                  the Board Examination, including Dalim Guha, who topped the
                  list in 1934. In more recent years, the school has continued
                  to produce high-performing students, with many students
                  achieving GPA 5 in the SSC examination...
                  <br />
                  <button (click)="toggleShowContent()"
                    class="btn readMoreLess-btn">
                    Read More
                  </button>
                </ng-template>
              </p>
            </section>
          </aside>
        </div>

        <div class="col-lg-6 col-md-12 child">
          <div class="widget-area">
            <section class="widget widget_karlson_posts_thumb">
              <h3 class="widget-title"
                style="color: #327ab5">
                SGHSC in Social Media
              </h3>
              <div class="layer mt-1 video-container">
                <div id="socialSlider"
                  class="carousel slide csWidth"
                  data-ride="carousel"
                  data-interval="false">
                  <div class="carousel-inner">
                    <div class="carousel-item active">
                      <div class="carousel-video-inner embed-responsive embed-responsive-16by9 videowrapper">
                        <iframe width="500"
                          height="315"
                          src="https://www.youtube.com/embed/5qodFCiwcaQ"
                          title="YouTube video player"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          allowfullscreen></iframe>
                      </div>
                    </div>
                  </div>

                  <button class="carousel-control-prev"
                    type="button"
                    data-bs-target="#socialSlider"
                    data-bs-slide="prev">
                    <span class="carousel-control-prev-icon"
                      aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                  </button>
                  <button class="carousel-control-next"
                    type="button"
                    data-bs-target="#socialSlider"
                    data-bs-slide="next">
                    <span class="carousel-control-next-icon"
                      aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                  </button>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- welcome & youtube end -->

  <!-- school tabs & pills start -->
  <section class="about-area">
    <div id="wt-text-holder">
      <div class="container">
        <div class="row">
          <div class="col-xs-12 col-sm-3 col-md-3">
            <ul class="nav flex-column nav-pills"
              id="v-pills-tab"
              role="tablist"
              aria-orientation="vertical">
              <li class="nav-item"
                (click)="
                  changeBackgroundImage(
                    '/assets/img/school/infoTabs/overview.jpg'
                  )
                ">
                <a class="nav-link active"
                  id="v-pills-tab1-tab"
                  data-bs-toggle="pill"
                  href="#v-pills-tab1"
                  role="tab"
                  aria-controls="v-pills-tab1"
                  aria-selected="true">
                  <i class="fa fa-cube"></i>
                  <div>
                    <h6>Overview</h6>
                  </div>
                </a>
              </li>
              <li class="nav-item"
                (click)="
                  changeBackgroundImage('/assets/img/school/whyStudyHere.jpg')
                ">
                <a class="nav-link"
                  id="v-pills-tab2-tab"
                  data-bs-toggle="pill"
                  href="#v-pills-tab2"
                  role="tab"
                  aria-controls="v-pills-tab2"
                  aria-selected="false">
                  <i class="fa fa-flask"></i>
                  <div>
                    <h6>Why Study Here</h6>
                  </div>
                </a>
              </li>
              <li class="nav-item"
                (click)="
                  changeBackgroundImage(
                    '/assets/img/school/missionAndVision.jpg'
                  )
                ">
                <a class="nav-link"
                  id="v-pills-tab3-tab"
                  data-bs-toggle="pill"
                  href="#v-pills-tab3"
                  role="tab"
                  aria-controls="v-pills-tab3"
                  aria-selected="false">
                  <i class="fa fa-certificate"></i>
                  <div>
                    <h6>Vision & Mission</h6>
                  </div>
                </a>
              </li>
              <li class="nav-item"
                (click)="
                  changeBackgroundImage('/assets/img/school/infoTabs/3.jpg')
                ">
                <a class="nav-link"
                  id="v-pills-tab4-tab"
                  data-bs-toggle="pill"
                  href="#v-pills-tab4"
                  role="tab"
                  aria-controls="v-pills-tab4"
                  aria-selected="false">
                  <i class="fa fa-graduation-cap"></i>
                  <div>
                    <h6>Holly Cross Brothers</h6>
                  </div>
                </a>
              </li>
              <li class="nav-item"
                (click)="
                  changeBackgroundImage('/assets/img/school/infoTabs/4.JPG')
                ">
                <a class="nav-link"
                  id="v-pills-tab5-tab"
                  data-bs-toggle="pill"
                  href="#v-pills-tab5"
                  role="tab"
                  aria-controls="v-pills-tab5"
                  aria-selected="false">
                  <i class="fa fa-gift"></i>
                  <div>
                    <h6>Institute Discipline</h6>
                  </div>
                </a>
              </li>
              <li class="nav-item"
                (click)="
                  changeBackgroundImage('/assets/img/school/infoTabs/5.JPG')
                ">
                <a class="nav-link"
                  id="v-pills-tab6-tab"
                  data-bs-toggle="pill"
                  href="#v-pills-tab6"
                  role="tab"
                  aria-controls="v-pills-tab6"
                  aria-selected="false">
                  <i class="fa fa-book"></i>
                  <div>
                    <h6>Building and Compounds</h6>
                  </div>
                </a>
              </li>
              <li class="nav-item"
                (click)="
                  changeBackgroundImage('/assets/img/school/infoTabs/6.JPG')
                ">
                <a class="nav-link"
                  id="v-pills-tab7-tab"
                  data-bs-toggle="pill"
                  href="#v-pills-tab7"
                  role="tab"
                  aria-controls="v-pills-tab7"
                  aria-selected="false">
                  <i class="fa fa-magic"></i>
                  <div>
                    <h6>Culture</h6>
                  </div>
                </a>
              </li>
            </ul>
          </div>
          <!-- End .col -->
          <div class="col-xs-12 col-sm-9 col-md-9 bgImageTab">
            <!-- Tab Content -->
            <div class="tab-content"
              id="v-pills-tabContent">
              <div class="tab-pane fade show active"
                id="v-pills-tab1"
                role="tabpanel"
                aria-labelledby="v-pills-tab1-tab">
                <h1>Overview</h1>
                <ng-container *ngIf="showFullOverview; else partialOverview">
                  St. Gregory's High School is a private Catholic school in
                  Dhaka, Bangladesh. It was founded in 1882 by Rev. Father
                  Gregory De Groote, a Belgian Benedictine priest. The school
                  has a long and distinguished history, with many notable
                  alumni, including the first Prime Minister of Bangladesh,
                  Tajuddin Ahmed, and the 13th President of Bangladesh, AQM
                  Badruddoza Chowdhury. <br />
                  The article discusses the school's academic and co-curricular
                  achievements. In the field of academics, St. Gregory's has a
                  long history of producing top-performing students. In the
                  1930s, the school produced a number of top-ranking students in
                  the Board Examination, including Dalim Guha, who topped the
                  list in 1934. In more recent years, the school has continued
                  to produce high-performing students, with many students
                  achieving GPA 5 in the SSC examination. <br />
                  In the field of co-curricular activities, St. Gregory's has
                  also had a long history of success. The school was one of the
                  first schools in Dhaka to start a Scout Troop, and the troop
                  has gone on to achieve many successes. The school also has a
                  strong Cadet Corps, and many of the cadets have gone on to
                  serve in the military. <br />
                  The article concludes by discussing the school's current
                  co-curricular activities. The school offers a wide range of
                  activities, including sports, music, drama, and debate. The
                  article also mentions the Gregorian Science Club, which was
                  the first club in the history of the school. <br />
                  Overall, the article provides a brief overview of the history
                  and achievements of St. Gregory's High School. The school has
                  a long and distinguished history, and it continues to produce
                  high-performing students in both academics and co-curricular
                  activities.
                  <br />
                  <button (click)="toggleShowOverview()"
                    class="btn readMoreLess-btn">
                    Read Less
                  </button>
                </ng-container>
                <ng-template #partialOverview>
                  St. Gregory's High School is a private Catholic school in
                  Dhaka, Bangladesh. It was founded in 1882 by Rev. Father
                  Gregory De Groote, a Belgian Benedictine priest. The school
                  has a long and distinguished history, with many notable
                  alumni, including the first Prime Minister of Bangladesh,
                  Tajuddin Ahmed, and the 13th President of Bangladesh, AQM
                  Badruddoza Chowdhury. <br />
                  The article discusses the school's academic and co-curricular
                  achievements. In the field of academics, St. Gregory's has a
                  long history of producing top-performing students. In the
                  1930s, the school produced a number of top-ranking students in
                  the Board Examination, including Dalim Guha, who topped the
                  list in 1934. In more recent years, the school has continued
                  to produce high-performing students, with many students
                  achieving GPA 5 in the SSC examination...
                  <br />
                  <button (click)="toggleShowOverview()"
                    class="btn readMoreLess-btn">
                    Read More
                  </button>
                </ng-template>
              </div>
              <div class="tab-pane fade"
                id="v-pills-tab2"
                role="tabpanel"
                aria-labelledby="v-pills-tab2-tab">
                <h1>Why Study Here</h1>
                <p>
                  St. Gregory's High School is a well-equipped institution that
                  provides students with all the opportunities they need to
                  flourish their latent talents. Our school has a
                  well-disciplined and pleasant atmosphere, highly qualified and
                  devoted teachers who are very encouraging and supportive, an
                  enriched library, modern science laboratories, a large
                  playground, a big basketball court, and facilities for playing
                  other indoor games and sports.
                  <br />
                  We believe that every student deserves to get an education in
                  a supportive environment where they can reach their full
                  potential. At St. Gregory's, we are committed to providing our
                  students with the resources and opportunities they need to
                  succeed. <br />
                  <button routerLink="/about/whyStudyHere"
                    class="btn readMoreLess-btn">
                    Read More
                  </button>
                </p>
              </div>
              <div class="tab-pane fade"
                id="v-pills-tab3"
                role="tabpanel"
                aria-labelledby="v-pills-tab3-tab">
                <h1>Vision & Mission</h1>
                <p>
                  St. Gregory's High School & College is a private Catholic
                  school in Dhaka, Bangladesh. It was founded in 1882 by Rev.
                  Father Gregory De Groote, a Belgian Benedictine priest. The
                  school has a long and distinguished history, with many notable
                  alumni, including the first Prime Minister of Bangladesh,
                  Tajuddin Ahmed, and the 13th President of Bangladesh, AQM
                  Badruddoza Chowdhury. <br />
                  The school's vision is to "provide a holistic education that
                  prepares students to be responsible citizens, leaders, and
                  global citizens." The school's mission is to "foster academic
                  excellence, moral and spiritual development, and physical
                  well-being in a caring and supportive environment."
                  <br />
                  <button routerLink="/about/missionAndVision"
                    class="btn readMoreLess-btn">
                    Read More
                  </button>
                </p>
              </div>
              <div class="tab-pane fade"
                id="v-pills-tab4"
                role="tabpanel"
                aria-labelledby="v-pills-tab4-tab">
                <h1>Holly Cross Brothers</h1>
                <ng-container *ngIf="showFullWhy; else partialWhy">
                  The Holy Cross Brothers are a religious congregation of men
                  who dedicate their lives to the education of young people.
                  They were founded in France in 1837 by Blessed Basil Moreau.
                  The Holy Cross Brothers came to Bangladesh in 1882 and
                  established St. Gregory's High School & College. The school
                  has been under the management of the Holy Cross Brothers ever
                  since. <br />
                  <ul>
                    <li>
                      <span style="font-weight: bolder">Management</span><br />
                      The Holy Cross Brothers are responsible for the overall
                      management of St. Gregory's High School & College. They
                      are responsible for setting the school's policies, hiring
                      and managing the staff, and ensuring that the school
                      provides a high-quality education. The Holy Cross Brothers
                      are also involved in the day-to-day running of the school,
                      and they can often be seen interacting with students and
                      teachers.
                    </li>
                    <li>
                      <span style="font-weight: bolder">Lifestyle</span><br />The Holy Cross Brothers live a simple and
                      austere
                      lifestyle. They live in community with other brothers, and
                      they share all of their possessions. They also make a vow
                      of poverty, chastity, and obedience. The Holy Cross
                      Brothers dedicate their lives to serving God and others,
                      and they see education as a way to help young people grow
                      in faith and knowledge.
                    </li>
                    <li>
                      <span style="font-weight: bolder">Impact</span><br />The
                      Holy Cross Brothers have had a profound impact on St.
                      Gregory's High School & College. They have helped to
                      create a school that is known for its academic excellence,
                      its strong moral values, and its commitment to social
                      justice. The Holy Cross Brothers have also helped to shape
                      the lives of countless students, and they have inspired
                      them to become leaders in their communities.
                    </li>
                  </ul>
                  <button (click)="toggleShowWhy()"
                    class="btn readMoreLess-btn">
                    Read Less
                  </button>
                </ng-container>
                <ng-template #partialWhy>
                  The Holy Cross Brothers are a religious congregation of men
                  who dedicate their lives to the education of young people.
                  They were founded in France in 1837 by Blessed Basil Moreau.
                  The Holy Cross Brothers came to Bangladesh in 1882 and
                  established St. Gregory's High School & College. The school
                  has been under the management of the Holy Cross Brothers ever
                  since... <br />
                  <br />
                  <button (click)="toggleShowWhy()"
                    class="btn readMoreLess-btn">
                    Read More
                  </button>
                </ng-template>
              </div>
              <div class="tab-pane fade"
                id="v-pills-tab5"
                role="tabpanel"
                aria-labelledby="v-pills-tab5-tab">
                <h1>Institute Discipline</h1>
                <ng-container *ngIf="showFullIns; else partialIns">
                  St. Gregory's High School & College is a private school in
                  Dhaka, Bangladesh. The school is run by the Holy Cross
                  Brothers, a religious congregation of men who dedicate their
                  lives to the education of young people. St. Gregory's is known
                  for its academic excellence, its strong moral values, and its
                  commitment to social justice. <br />

                  The school has a strict code of conduct that students and
                  parents are expected to follow. Students are required to be
                  obedient and loyal, scholarly and hard working, respectful and
                  honest, healthy, joyful and caring, and fostering the spirit
                  of family growing as a man of good character. If a student is
                  found to be in violation of the code of conduct, they may be
                  subject to disciplinary action, up to and including expulsion.
                  <br />

                  The school also has a number of policies in place to ensure
                  the safety and security of students. For example, teachers are
                  authorized to check students' bags at any time, and the school
                  reserves the right to check any student or visitor at any
                  time. The school also prohibits the use of offensive or
                  morally-wrong materials on campus.<br />

                  St. Gregory's High School & College is a demanding school, but
                  it also provides a supportive environment where students can
                  learn and grow. The school's strict code of conduct helps to
                  ensure that students are held accountable for their actions,
                  and the school's policies help to keep students safe and
                  secure.<br />

                  <button (click)="toggleShowIns()"
                    class="btn readMoreLess-btn">
                    Read Less
                  </button>
                </ng-container>
                <ng-template #partialIns>
                  St. Gregory's High School & College is a private school in
                  Dhaka, Bangladesh. The school is run by the Holy Cross
                  Brothers, a religious congregation of men who dedicate their
                  lives to the education of young people. St. Gregory's is known
                  for its academic excellence, its strong moral values, and its
                  commitment to social justice...<br />
                  <br />
                  <button (click)="toggleShowIns()"
                    class="btn readMoreLess-btn">
                    Read More
                  </button>
                </ng-template>
              </div>
              <div class="tab-pane fade"
                id="v-pills-tab6"
                role="tabpanel"
                aria-labelledby="v-pills-tab6-tab">
                <h1>Building & Compunds</h1>
                <ng-container *ngIf="showFullBuilding; else partialBuilding">
                  St. Gregory's High School & College is located in the Luxmi
                  Bazar neighborhood of Dhaka, Bangladesh. The school's compound
                  is spread over a large area and includes a number of
                  buildings, including the main academic building, a science
                  laboratory building, a library, a playground, and a basketball
                  court. <br />
                  The main academic building is a six-story structure that
                  houses classrooms, offices, and a canteen. The science
                  laboratory building is equipped with modern facilities for
                  conducting scientific experiments. The library is a large and
                  well-stocked facility that provides students with access to a
                  wide range of books and resources. The playground is a
                  spacious area where students can play sports and games. The
                  basketball court is a popular spot for students to play
                  basketball and other sports.<br />
                  The school's compound is well-maintained and provides a safe
                  and secure environment for students. The buildings are clean
                  and well-lit, and the grounds are kept clean and tidy. The
                  compound is also well-secured with a perimeter wall and
                  security guards.<br />
                  St. Gregory's High School & College's buildings and compound
                  provide a stimulating and supportive environment for students
                  to learn and grow. The school's facilities are modern and
                  well-maintained, and the compound is clean and secure. This
                  makes St. Gregory's a great place for students to get an
                  education.
                  <br />
                  <button (click)="toggleShowBuilding()"
                    class="btn readMoreLess-btn">
                    Read Less
                  </button>
                </ng-container>
                <ng-template #partialBuilding>
                  St. Gregory's High School & College is located in the Luxmi
                  Bazar neighborhood of Dhaka, Bangladesh. The school's compound
                  is spread over a large area and includes a number of
                  buildings, including the main academic building, a science
                  laboratory building, a library, a playground, and a basketball
                  court...<br />
                  <br />
                  <button (click)="toggleShowBuilding()"
                    class="btn readMoreLess-btn">
                    Read More
                  </button>
                </ng-template>
              </div>
              <div class="tab-pane fade"
                id="v-pills-tab7"
                role="tabpanel"
                aria-labelledby="v-pills-tab7-tab">
                <h1>Culture</h1>
                <ng-container *ngIf="showFullCulture; else partialCulture">
                  St. Gregory's High School & College is a private school in
                  Dhaka, Bangladesh that has a long history of promoting
                  cultural activities. The school's culture program includes a
                  variety of events, such as drama performances, music recitals,
                  dance performances, and art exhibitions. The school also hosts
                  an annual festival, which is a celebration of the school's
                  cultural heritage. <br />

                  The school's culture program is designed to provide students
                  with opportunities to express themselves creatively and to
                  learn about different cultures. The program also helps to
                  foster a sense of community among students and to promote the
                  school's values of diversity and inclusion. <br />

                  The school's annual festival is a major event that attracts
                  students from all over Bangladesh. The festival features a
                  variety of cultural performances, as well as food stalls,
                  games, and competitions. The festival is a great opportunity
                  for students to showcase their talents and to learn about
                  different cultures. <br />

                  St. Gregory's High School & College's culture program and
                  festival are a great way for students to express themselves
                  creatively and to learn about different cultures. The program
                  also helps to foster a sense of community among students and
                  to promote the school's values of diversity and inclusion.
                  <br />
                  <button (click)="toggleShowCulture()"
                    class="btn readMoreLess-btn">
                    Read Less
                  </button>
                </ng-container>
                <ng-template #partialCulture>
                  St. Gregory's High School & College is a private school in
                  Dhaka, Bangladesh that has a long history of promoting
                  cultural activities. The school's culture program includes a
                  variety of events, such as drama performances, music recitals,
                  dance performances, and art exhibitions. The school also hosts
                  an annual festival, which is a celebration of the school's
                  cultural heritage...<br />
                  <br />
                  <button (click)="toggleShowCulture()"
                    class="btn readMoreLess-btn">
                    Read More
                  </button>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span id="bg-tab"></span>
    </div>
  </section>

  <!-- notice facebook & ad start -->
  <section class="about-area">
    <div class="container">
      <div class="row parent">
        <div class="col-lg-6 col-md-12 child">
          <aside class="widget-area">
            <section class="widget widget_karlson_posts_thumb">
              <h3 class="widget-title"
                style="color: #151574">Notices</h3>
              <article class="item">
                <div class="ticker-wrapper-v-image">
                  <ul class="news-ticker-v-image"
                    *ngIf="Notices.length == 0">
                    <li style="border: 1px solid #eeeeee; padding: 10px">

                      <div>
                        <div style="
                          white-space: nowrap;
                          overflow: hidden;
                          text-overflow: ellipsis;
                        ">
                          No Notices Found
                        </div>

                      </div>
                      <div class="clear"></div>
                    </li>

                  </ul>
                  <ul class="news-ticker-v-image"
                    *ngIf="Notices.length != 0">
                    <li *ngFor="let item of Notices"
                      style="border: 1px solid #eeeeee; padding: 10px">
                      <div class="thumbnail">
                        <div style="border: 1px solid #eeeeee; width: 80px">
                          <div style="background-color: #082c5c"
                            class="text-center">
                            <span style="text-align: center; color: #ffffff">{{
                              item.CreatedDate | date:'dd'
                              }}</span>
                          </div>
                          <div style="background-color: #f2f2f2"
                            class="text-center">
                            <span style="text-align: center; color: #caa545">{{
                              item.CreatedDate | date:'MMM'
                              }}</span>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div style="
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                          ">
                          {{ item.EnglishTitle }}
                        </div>
                        <div style="overflow: auto">
                          <a (click)="onNavigate(item.FileLink)"
                           
                            style="text-decoration: unset; color: #151574">View Notice</a>
                        </div>
                      </div>
                      <div class="clear"></div>
                    </li>
                    <!-- <li
                      *ngFor="let item of dynamicTape"
                      style="border: 1px solid #eeeeee; padding: 10px">
                      <div class="thumbnail">
                        <div style="border: 1px solid #eeeeee; width: 80px">
                          <div
                            style="background-color: #082c5c"
                            class="text-center">
                            <span style="text-align: center; color: #ffffff">{{
                              item.Date.getDate()
                            }}</span>
                          </div>
                          <div
                            style="background-color: #f2f2f2"
                            class="text-center">
                            <span style="text-align: center; color: #caa545">{{
                              item.Date.toLocaleString('default', {
                                month: 'short'
                              })
                            }}</span>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div
                          style="
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                          ">
                          {{ item.Text }}
                        </div>
                        <div style="overflow: auto">
                          <a
                            href="assets/img/school/notice/{{ item.Link }}"
                            target="_blank"
                            style="text-decoration: unset; color: #151574"
                            >View Notice</a
                          >
                        </div>
                      </div>
                      <div class="clear"></div>
                    </li> -->
                  </ul>
                </div>
              </article>
            </section>
            <a href="/notice"
              class="viewAll"><i class="fa fa-bars"></i> View All</a>
          </aside>
        </div>

        <div class="col-lg-6 col-md-12 child d-flex justify-content-center align-items-center">
          <div class="widget-area">
            <section class="widget widget_karlson_posts_thumb">
              <div class="text-center">
                <iframe class="pt-5"
                  *ngIf="isBig"
                  src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fsghscdhaka%2F&tabs&width=500&height=130&small_header=false&adapt_container_width=true&hide_cover=true&show_facepile=true&appId"
                  width="500"
                  height="200"
                  style="border: none; overflow: hidden"
                  scrolling="no"
                  frameborder="0"
                  allowfullscreen="true"
                  allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                <iframe class="pt-5"
                  *ngIf="isSmall"
                  src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fsghscdhaka%2F&tabs&width=220&height=130&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
                  width="300"
                  height="200"
                  style="border: none; overflow: hidden"
                  scrolling="no"
                  frameborder="0"
                  allowfullscreen="true"
                  allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
              </div>

              <div class="text-center">
                <a routerLink="/admission-details">
                  <img src="assets/img/school/sponsor/Picture1.png"
                    style="height: 350px; width: 350px"
                    alt="" />
                </a>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- notice facebook & ad end -->

  <!-- news & events start -->
  <section class="blog-area">
    <div class="container">
      <h3 class="widget-title"
        style="color: #327ab5">News & Events</h3>
      <hr />
      <div class="team-slider owl-carousel owl-theme">
        <div class="single-team-box">
          <div class="single-blog-post badgeItem">
            <span class="badge badge-primary badgeDate"><time datetime="2023-05-10"
                class="fullimage cover bg1 date">May 10, 2023</time></span>
            <div class="hover-zoom">
              <a routerLink="/newsandevents"><img src="assets/img/school/news/1.jpg"
                  alt="image" /></a>
            </div>
            <div class="post-content">
              <h3>
                <a routerLink="/newsandevents">Annual Sports Day On 19th May</a>
              </h3>
              <a routerLink="/newsandevents"
                class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></a>
            </div>
          </div>
        </div>
        <div class="single-team-box">
          <div class="single-blog-post badgeItem">
            <span class="badge badge-primary badgeDate"><time datetime="2023-05-13"
                class="fullimage cover bg1 date">May 13, 2023</time></span>
            <div class="hover-zoom">
              <a routerLink="/newsandevents"><img src="assets/img/school/news/2.jpg"
                  alt="image" /></a>
            </div>
            <div class="post-content">
              <h3>
                <a routerLink="/newsandevents">Annual Sports Day On 19th July</a>
              </h3>
              <a routerLink="/newsandevents"
                class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></a>
            </div>
          </div>
        </div>
        <div class="single-team-box">
          <div class="single-blog-post badgeItem">
            <span class="badge badge-primary badgeDate"><time datetime="2023-05-15"
                class="fullimage cover bg1 date">May 15, 2023</time></span>
            <div class="hover-zoom">
              <a routerLink="/newsandevents"><img src="assets/img/school/news/3.jpg"
                  alt="image" /></a>
            </div>
            <div class="post-content">
              <h3>
                <a routerLink="/newsandevents">Annual Sports Day On 19th July</a>
              </h3>
              <a routerLink="/newsandevents"
                class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></a>
            </div>
          </div>
        </div>
        <div class="single-team-box">
          <div class="single-blog-post badgeItem">
            <span class="badge badge-primary badgeDate"><time datetime="2023-05-20"
                class="fullimage cover bg1 date">May 20, 2023</time></span>
            <div class="hover-zoom">
              <a routerLink="/newsandevents"><img src="assets/img/school/news/4.jpg"
                  alt="image" /></a>
            </div>
            <div class="post-content">
              <h3>
                <a routerLink="/newsandevents">Annual Sports Day On 19th July</a>
              </h3>
              <a routerLink="/newsandevents"
                class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></a>
            </div>
          </div>
        </div>
      </div>
      <a href="/newsandevents"
        class="viewAll"><i class="fa fa-bars"></i> View All</a>
    </div>
  </section>
  <!-- news & events end -->
</div>

<!-- labs start -->
<section class="header">
  <div class="container py-4"
    class="subscribe-inner-area bgImageTabTwo"
    data-jarallax='{"speed": 0.3}'>
    <div class="row"
      *ngIf="isBig">
      <div class="col-md-9">
        <!-- Tabs content -->
        <div class="tab-content secondTab"
          id="v-pills-tabContent">
          <div class="tab-pane secondTabPane fade show active p-5"
            id="v-pills-library"
            role="tabpanel"
            aria-labelledby="v-pills-library-tab">
            <h4 class="font-italic mb-4">Library</h4>
            <div class="card-list">
              <div class="container">
                <div class="row">
                  <div class="col-12 col-lg-6">
                    <div class="content">
                      <p>
                        Welcome to the Library page of St. Gregory's High School
                        & College! Our library is a treasure trove of knowledge,
                        a sanctuary for learning, and a hub for intellectual
                        exploration. With an extensive collection of books,
                        periodicals, reference materials, and digital resources,
                        our library provides a stimulating environment where
                        students can cultivate a love for reading, research, and
                        lifelong learning.
                      </p>
                    </div>
                  </div>
                  <div class="col-12 col-lg-6">
                    <img src="assets/img/school/labs/1.jpg"
                      alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane secondTabPane fade p-5"
            id="v-pills-computerLab"
            role="tabpanel"
            aria-labelledby="v-pills-computerLab-tab">
            <h4 class="font-italic mb-4">Computer Lab</h4>
            <div class="card-list">
              <div class="container">
                <div class="row">
                  <div class="col-12 col-lg-6">
                    <div class="content">
                      <p>
                        Computer Lab page of St. Gregory's High School &
                        College! Our state-of-the-art computer lab is a hub of
                        technological innovation and learning opportunities for
                        our students. Equipped with the latest hardware and
                        software, our lab provides a dynamic environment where
                        students can explore the world of technology, enhance
                        their digital skills, and prepare for the challenges of
                        the modern era.
                      </p>
                    </div>
                  </div>
                  <div class="col-12 col-lg-6">
                    <img src="assets/img/school/labs/2.jpg"
                      alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane secondTabPane fade p-5"
            id="v-pills-physicsLab"
            role="tabpanel"
            aria-labelledby="v-pills-physicsLab-tab">
            <h4 class="font-italic mb-4">Physics Lab</h4>
            <div class="card-list">
              <div class="container">
                <div class="row">
                  <div class="col-12 col-lg-6">
                    <div class="content">
                      <p>
                        Physics Lab of St. Gregory's High School & College! Our
                        physics lab is a space where students engage in hands-on
                        experiments, explore the fundamental laws of nature, and
                        develop a deep understanding of the principles that
                        govern our universe. Equipped with state-of-the-art
                        equipment and resources, our lab provides an immersive
                        learning environment where students can apply
                        theoretical concepts to practical scenarios.
                      </p>
                    </div>
                  </div>
                  <div class="col-12 col-lg-6">
                    <img src="assets/img/school/labs/3.jpg"
                      alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane secondTabPane fade p-5"
            id="v-pills-biologyLab"
            role="tabpanel"
            aria-labelledby="v-pills-biologyLab-tab">
            <h4 class="font-italic mb-4 headingSt">Biology Lab</h4>
            <div class="card-list">
              <div class="container">
                <div class="row">
                  <div class="col-12 col-lg-6">
                    <div class="content">
                      <p>
                        Biology Lab of St. Gregory's High School & College! Our
                        biology lab is a hub of scientific exploration and
                        discovery, where students can immerse themselves in the
                        fascinating world of life sciences. Equipped with modern
                        equipment and resources, our lab provides a dynamic
                        environment for hands-on learning and practical
                        experiments.
                      </p>
                    </div>
                  </div>
                  <div class="col-12 col-lg-6">
                    <img src="assets/img/school/labs/4.jpg"
                      alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane secondTabPane fade p-5"
            id="v-pills-chemistryLab"
            role="tabpanel"
            aria-labelledby="v-pills-chemistryLab-tab">
            <h4 class="font-italic mb-4 headingSt">chemistry Lab</h4>
            <div class="card-list">
              <div class="container">
                <div class="row">
                  <div class="col-12 col-lg-6">
                    <div class="content">
                      <p>
                        Our chemistry lab is a place of scientific discovery and
                        experimentation, where students can explore the
                        fascinating world of chemical reactions and molecular
                        structures. Equipped with modern equipment and safety
                        protocols, our lab provides a hands-on learning
                        environment where students can engage in practical
                        experiments, analyze data, and deepen their
                        understanding of chemical principles.
                      </p>
                    </div>
                  </div>
                  <div class="col-12 col-lg-6">
                    <img src="assets/img/school/labs/5.jpg"
                      alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane secondTabPane fade p-5"
            id="v-pills-multimediaClassroom"
            role="tabpanel"
            aria-labelledby="v-pills-multimediaClassroom-tab">
            <h4 class="font-italic mb-4 headingSt">Multimedia Classroom</h4>
            <div class="card-list">
              <div class="container">
                <div class="row">
                  <div class="col-12 col-lg-6">
                    <div class="content">
                      <p>
                        Digital Multimedia Classroom St. Gregory's High School &
                        College! Our cutting-edge multimedia classroom is a
                        space where creativity, technology, and education
                        converge. Equipped with state-of-the-art audiovisual
                        equipment, interactive displays, and multimedia
                        software, our classroom provides an immersive learning
                        environment that enhances student engagement and
                        facilitates dynamic teaching practices.
                      </p>
                    </div>
                  </div>
                  <div class="col-12 col-lg-6">
                    <img src="assets/img/school/labs/6.jpg"
                      alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <!-- Tabs nav -->
        <div class="nav flex-column nav-pills nav-pills-custom"
          id="v-pills-tab"
          role="tablist"
          aria-orientation="vertical">
          <a class="nav-link mb-3 p-3 shadow active"
            id="v-pills-library-tab"
            data-bs-toggle="pill"
            href="#v-pills-library"
            role="tab"
            aria-controls="v-pills-library"
            aria-selected="true">
            <i class="fa fa-book me-2"></i>
            <span class="font-weight-bold small text-uppercase">Library</span>
          </a>

          <a class="nav-link mb-3 p-3 shadow"
            id="v-pills-computerLab-tab"
            data-bs-toggle="pill"
            href="#v-pills-computerLab"
            role="tab"
            aria-controls="v-pills-computerLab"
            aria-selected="false">
            <i class="fa fa-book me-2"></i>
            <span class="font-weight-bold small text-uppercase">Computer Lab</span>
          </a>

          <a class="nav-link mb-3 p-3 shadow"
            id="v-pills-physicsLab-tab"
            data-bs-toggle="pill"
            href="#v-pills-physicsLab"
            role="tab"
            aria-controls="v-pills-physicsLab"
            aria-selected="false">
            <i class="fa fa-book me-2"></i>
            <span class="font-weight-bold small text-uppercase">Physics Lab</span>
          </a>

          <a class="nav-link mb-3 p-3 shadow"
            id="v-pills-biologyLab-tab"
            data-bs-toggle="pill"
            href="#v-pills-biologyLab"
            role="tab"
            aria-controls="v-pills-biologyLab"
            aria-selected="false">
            <i class="fa fa-book me-2"></i>
            <span class="font-weight-bold small text-uppercase">Biology Lab</span>
          </a>

          <a class="nav-link mb-3 p-3 shadow"
            id="v-pills-chemistryLab-tab"
            data-bs-toggle="pill"
            href="#v-pills-chemistryLab"
            role="tab"
            aria-controls="v-pills-chemistryLab"
            aria-selected="false">
            <i class="fa fa-book me-2"></i>
            <span class="font-weight-bold small text-uppercase">Chemistry Lab</span>
          </a>

          <a class="nav-link mb-3 p-3 shadow"
            id="v-pills-multimediaClassroom-tab"
            data-bs-toggle="pill"
            href="#v-pills-multimediaClassroom"
            role="tab"
            aria-controls="v-pills-multimediaClassroom"
            aria-selected="false">
            <i class="fa fa-book me-2"></i>
            <span class="font-weight-bold small text-uppercase">Multimedia Classroom</span>
          </a>
        </div>
      </div>
      <div class="row"
        *ngIf="isSmall">
        <div class="col-md-3">
          <!-- Tabs nav -->
          <div class="nav flex-column nav-pills nav-pills-custom"
            id="v-pills-tab"
            role="tablist"
            aria-orientation="vertical">
            <a class="nav-link mb-3 p-3 shadow active"
              id="v-pills-library-tab"
              data-bs-toggle="pill"
              href="#v-pills-library"
              role="tab"
              aria-controls="v-pills-library"
              aria-selected="true">
              <i class="fa fa-book me-2"></i>
              <span class="font-weight-bold small text-uppercase">Library</span>
            </a>

            <a class="nav-link mb-3 p-3 shadow"
              id="v-pills-computerLab-tab"
              data-bs-toggle="pill"
              href="#v-pills-computerLab"
              role="tab"
              aria-controls="v-pills-computerLab"
              aria-selected="false">
              <i class="fa fa-book me-2"></i>
              <span class="font-weight-bold small text-uppercase">Computer Lab</span>
            </a>

            <a class="nav-link mb-3 p-3 shadow"
              id="v-pills-physicsLab-tab"
              data-bs-toggle="pill"
              href="#v-pills-physicsLab"
              role="tab"
              aria-controls="v-pills-physicsLab"
              aria-selected="false">
              <i class="fa fa-book me-2"></i>
              <span class="font-weight-bold small text-uppercase">Physics Lab</span>
            </a>

            <a class="nav-link mb-3 p-3 shadow"
              id="v-pills-biologyLab-tab"
              data-bs-toggle="pill"
              href="#v-pills-biologyLab"
              role="tab"
              aria-controls="v-pills-biologyLab"
              aria-selected="false">
              <i class="fa fa-book me-2"></i>
              <span class="font-weight-bold small text-uppercase">Biology Lab</span>
            </a>

            <a class="nav-link mb-3 p-3 shadow"
              id="v-pills-chemistryLab-tab"
              data-bs-toggle="pill"
              href="#v-pills-chemistryLab"
              role="tab"
              aria-controls="v-pills-chemistryLab"
              aria-selected="false">
              <i class="fa fa-book me-2"></i>
              <span class="font-weight-bold small text-uppercase">Chemistry Lab</span>
            </a>

            <a class="nav-link mb-3 p-3 shadow"
              id="v-pills-multimediaClassroom-tab"
              data-bs-toggle="pill"
              href="#v-pills-multimediaClassroom"
              role="tab"
              aria-controls="v-pills-multimediaClassroom"
              aria-selected="false">
              <i class="fa fa-book me-2"></i>
              <span class="font-weight-bold small text-uppercase">Multimedia Classroom</span>
            </a>
          </div>
        </div>
        <div class="col-md-9">
          <!-- Tabs content -->
          <div class="tab-content secondTab"
            id="v-pills-tabContent">
            <div class="tab-pane secondTabPane fade show active p-5"
              id="v-pills-library"
              role="tabpanel"
              aria-labelledby="v-pills-library-tab">
              <h4 class="font-italic mb-4">Library</h4>
              <div class="card-list">
                <div class="container">
                  <div class="row">
                    <div class="col-12 col-lg-6">
                      <div class="content">
                        <p>
                          Welcome to the Library page of St. Gregory's High
                          School & College! Our library is a treasure trove of
                          knowledge, a sanctuary for learning, and a hub for
                          intellectual exploration. With an extensive collection
                          of books, periodicals, reference materials, and
                          digital resources, our library provides a stimulating
                          environment where students can cultivate a love for
                          reading, research, and lifelong learning.
                        </p>
                      </div>
                    </div>
                    <div class="col-12 col-lg-6">
                      <img src="assets/img/school/labs/1.jpg"
                        alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane secondTabPane fade p-5"
              id="v-pills-computerLab"
              role="tabpanel"
              aria-labelledby="v-pills-computerLab-tab">
              <h4 class="font-italic mb-4">Computer Lab</h4>
              <div class="card-list">
                <div class="container">
                  <div class="row">
                    <div class="col-12 col-lg-6">
                      <div class="content">
                        <p>
                          Computer Lab page of St. Gregory's High School &
                          College! Our state-of-the-art computer lab is a hub of
                          technological innovation and learning opportunities
                          for our students. Equipped with the latest hardware
                          and software, our lab provides a dynamic environment
                          where students can explore the world of technology,
                          enhance their digital skills, and prepare for the
                          challenges of the modern era.
                        </p>
                      </div>
                    </div>
                    <div class="col-12 col-lg-6">
                      <img src="assets/img/school/labs/2.jpg"
                        alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane secondTabPane fade p-5"
              id="v-pills-physicsLab"
              role="tabpanel"
              aria-labelledby="v-pills-physicsLab-tab">
              <h4 class="font-italic mb-4">Physics Lab</h4>
              <div class="card-list">
                <div class="container">
                  <div class="row">
                    <div class="col-12 col-lg-6">
                      <div class="content">
                        <p>
                          Physics Lab of St. Gregory's High School & College!
                          Our physics lab is a space where students engage in
                          hands-on experiments, explore the fundamental laws of
                          nature, and develop a deep understanding of the
                          principles that govern our universe. Equipped with
                          state-of-the-art equipment and resources, our lab
                          provides an immersive learning environment where
                          students can apply theoretical concepts to practical
                          scenarios.
                        </p>
                      </div>
                    </div>
                    <div class="col-12 col-lg-6">
                      <img src="assets/img/school/labs/3.jpg"
                        alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane secondTabPane fade p-5"
              id="v-pills-biologyLab"
              role="tabpanel"
              aria-labelledby="v-pills-biologyLab-tab">
              <h4 class="font-italic mb-4 headingSt">Biology Lab</h4>
              <div class="card-list">
                <div class="container">
                  <div class="row">
                    <div class="col-12 col-lg-6">
                      <div class="content">
                        <p>
                          Biology Lab of St. Gregory's High School & College!
                          Our biology lab is a hub of scientific exploration and
                          discovery, where students can immerse themselves in
                          the fascinating world of life sciences. Equipped with
                          modern equipment and resources, our lab provides a
                          dynamic environment for hands-on learning and
                          practical experiments.
                        </p>
                      </div>
                    </div>
                    <div class="col-12 col-lg-6">
                      <img src="assets/img/school/labs/4.jpg"
                        alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane secondTabPane fade p-5"
              id="v-pills-chemistryLab"
              role="tabpanel"
              aria-labelledby="v-pills-chemistryLab-tab">
              <h4 class="font-italic mb-4 headingSt">chemistry Lab</h4>
              <div class="card-list">
                <div class="container">
                  <div class="row">
                    <div class="col-12 col-lg-6">
                      <div class="content">
                        <p>
                          Our chemistry lab is a place of scientific discovery
                          and experimentation, where students can explore the
                          fascinating world of chemical reactions and molecular
                          structures. Equipped with modern equipment and safety
                          protocols, our lab provides a hands-on learning
                          environment where students can engage in practical
                          experiments, analyze data, and deepen their
                          understanding of chemical principles.
                        </p>
                      </div>
                    </div>
                    <div class="col-12 col-lg-6">
                      <img src="assets/img/school/labs/5.jpg"
                        alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane secondTabPane fade p-5"
              id="v-pills-multimediaClassroom"
              role="tabpanel"
              aria-labelledby="v-pills-multimediaClassroom-tab">
              <h4 class="font-italic mb-4 headingSt">Multimedia Classroom</h4>
              <div class="card-list">
                <div class="container">
                  <div class="row">
                    <div class="col-12 col-lg-6">
                      <div class="content">
                        <p>
                          Digital Multimedia Classroom St. Gregory's High School
                          & College! Our cutting-edge multimedia classroom is a
                          space where creativity, technology, and education
                          converge. Equipped with state-of-the-art audiovisual
                          equipment, interactive displays, and multimedia
                          software, our classroom provides an immersive learning
                          environment that enhances student engagement and
                          facilitates dynamic teaching practices.
                        </p>
                      </div>
                    </div>
                    <div class="col-12 col-lg-6">
                      <img src="assets/img/school/labs/6.jpg"
                        alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- labs end -->

<!-- facts & figures start -->
<section class="subscribe-area ptt-100 pt-0">
  <div class="subscribe-inner-area jarallax"
    data-jarallax='{"speed": 0.3}'>
    <h3 class="widget-title blueText">Facts and Figures</h3>
    <hr />
    <div class="row">
      <div class="col-lg-3 col-sm-6 col-md-6">
        <div class="single-fun-facts">
          <h3 style="color: #327ab5 !important">
            <span class="odometer"
              data-count="125">00</span>
          </h3>
          <p style="color: #327ab5 !important">Teacher</p>
        </div>
      </div>
      <div class="col-lg-3 col-sm-6 col-md-6">
        <div class="single-fun-facts"
          style="color: #327ab5 !important">
          <h3 style="color: #327ab5 !important">
            <span class="odometer"
              data-count="141">00</span>
          </h3>
          <p style="color: #327ab5 !important">Years</p>
        </div>
      </div>
      <div class="col-lg-3 col-sm-6 col-md-6">
        <div class="single-fun-facts">
          <h3 style="color: #327ab5 !important">
            <span class="odometer"
              data-count="4500">00</span><span class="sign-icon">+</span>
          </h3>
          <p style="color: #327ab5 !important">Students</p>
        </div>
      </div>
      <div class="col-lg-3 col-sm-6 col-md-6">
        <div class="single-fun-facts">
          <h3 style="color: #327ab5 !important">
            <span class="odometer"
              data-count="3">00</span>
          </h3>
          <p style="color: #327ab5 !important">Building</p>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- facts & figures end -->

<!-- clubs start -->
<div class="bg-image clubP">
  <section class="blog-area">
    <div class="container"
      style="padding-top: 10px">
      <h3 class="widget-title"
        style="color: #327ab5">Clubs</h3>
      <hr />
      <div class="work-slider owl-carousel owl-theme">
        <div class="clubCard card"
          (mouseover)="onCardHover()"
          (mouseout)="onCardLeave()">
          <div class="card-body text-white"
            style="background-color: rgb(20, 20, 92)">
            <h5 class="card-title">
              <a class="text-white"
                routerLink="/clubs"
                routerLink="/clubs/englishClub">ENGLISH CLUB</a>
            </h5>
          </div>
          <div class="card-img-top position-relative">
            <a routerLink="/clubs/englishClubs">
              <div class="photo_wrapper">
                <div class="photo scale-with-grid clubS clubS-1">
                  <div class="overlay">
                    <span><img src="assets/img/school/sghsc_logo.png"
                        alt="image" /></span>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div class="clubCard card"
          (mouseover)="onCardHover()"
          (mouseout)="onCardLeave()">
          <div class="card-body text-white"
            style="background-color: rgb(20, 20, 92)">
            <h5 class="card-title">
              <a class="text-white"
                routerLink="/clubs/ycsClub"
                href="">YCS CLUB</a>
            </h5>
          </div>
          <div class="card-img-top position-relative">
            <a href="/clubs/ycsClub">
              <div class="photo_wrapper">
                <div class="photo scale-with-grid clubS clubS-2">
                  <div class="overlay">
                    <span><img src="assets/img/school/sghsc_logo.png"
                        alt="image" /></span>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div class="clubCard card"
          (mouseover)="onCardHover()"
          (mouseout)="onCardLeave()">
          <div class="card-body text-white"
            style="background-color: rgb(20, 20, 92)">
            <h5 class="card-title">
              <a class="text-white"
                routerLink="/clubs/sportsClub"
                href="">SPORTS CLUB</a>
            </h5>
          </div>
          <div class="card-img-top position-relative">
            <a href="/clubs/sportsClub">
              <div class="photo_wrapper">
                <div class="photo scale-with-grid clubS clubS-3">
                  <div class="overlay">
                    <span><img src="assets/img/school/sghsc_logo.png"
                        alt="image" /></span>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div class="clubCard card"
          (mouseover)="onCardHover()"
          (mouseout)="onCardLeave()">
          <div class="card-body text-white"
            style="background-color: rgb(20, 20, 92)">
            <h5 class="card-title">
              <a class="text-white"
                routerLink="/clubs/quizMathClub"
                href="">QUIZ CLUB & MATH CLUB</a>
            </h5>
          </div>
          <div class="card-img-top position-relative">
            <a href="/clubs/quizMathClub">
              <div class="photo_wrapper">
                <div class="photo scale-with-grid clubS clubS-4">
                  <div class="overlay">
                    <span><img src="assets/img/school/sghsc_logo.png"
                        alt="image" /></span>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div class="clubCard card"
          (mouseover)="onCardHover()"
          (mouseout)="onCardLeave()">
          <div class="card-body text-white"
            style="background-color: rgb(20, 20, 92)">
            <h5 class="card-title">
              <a class="text-white"
                routerLink="/clubs/scienceClub"
                href="">SCIENCE CLUB</a>
            </h5>
          </div>
          <div class="card-img-top position-relative">
            <a href="/clubs/scienceClub">
              <div class="photo_wrapper">
                <div class="photo scale-with-grid clubS clubS-5">
                  <div class="overlay">
                    <span><img src="assets/img/school/sghsc_logo.png"
                        alt="image" /></span>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div class="clubCard card"
          (mouseover)="onCardHover()"
          (mouseout)="onCardLeave()">
          <div class="card-body text-white"
            style="background-color: rgb(20, 20, 92)">
            <h5 class="card-title">
              <a class="text-white"
                routerLink="/clubs/artHandwritingClub"
                href="">ART AND HAND WRITING CLUB</a>
            </h5>
          </div>
          <div class="card-img-top position-relative">
            <a href="/clubs/artHandwritingClub">
              <div class="photo_wrapper">
                <div class="photo scale-with-grid clubS clubS-6">
                  <div class="overlay">
                    <span><img src="assets/img/school/sghsc_logo.png"
                        alt="image" /></span>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div class="clubCard card"
          (mouseover)="onCardHover()"
          (mouseout)="onCardLeave()">
          <div class="card-body text-white"
            style="background-color: rgb(20, 20, 92)">
            <h5 class="card-title">
              <a class="text-white"
                routerLink="/clubs/ecologyClub"
                href="">ECOLOGY CLUB</a>
            </h5>
          </div>
          <div class="card-img-top position-relative">
            <a href="/clubs/ecologyClub">
              <div class="photo_wrapper">
                <div class="photo scale-with-grid clubS clubS-7">
                  <div class="overlay">
                    <span><img src="assets/img/school/sghsc_logo.png"
                        alt="image" /></span>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div class="clubCard card"
          (mouseover)="onCardHover()"
          (mouseout)="onCardLeave()">
          <div class="card-body text-white"
            style="background-color: rgb(20, 20, 92)">
            <h5 class="card-title">
              <a class="text-white"
                routerLink="/clubs/artClub"
                href="">ART CLUB</a>
            </h5>
          </div>
          <div class="card-img-top position-relative">
            <a href="/clubs/artClub">
              <div class="photo_wrapper">
                <div class="photo scale-with-grid clubS clubS-8">
                  <div class="overlay">
                    <span><img src="assets/img/school/sghsc_logo.png"
                        alt="image" /></span>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div class="clubCard card"
          (mouseover)="onCardHover()"
          (mouseout)="onCardLeave()">
          <div class="card-body text-white"
            style="background-color: rgb(20, 20, 92)">
            <h5 class="card-title">
              <a class="text-white"
                routerLink="/clubs/chessClub"
                href="">CHESS CLUB</a>
            </h5>
          </div>
          <div class="card-img-top position-relative">
            <a href="/clubs/chessClub">
              <div class="photo_wrapper">
                <div class="photo scale-with-grid clubS clubS-9">
                  <div class="overlay">
                    <span><img src="assets/img/school/sghsc_logo.png"
                        alt="image" /></span>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div class="clubCard card"
          (mouseover)="onCardHover()"
          (mouseout)="onCardLeave()">
          <div class="card-body text-white"
            style="background-color: rgb(20, 20, 92)">
            <h5 class="card-title">
              <a class="text-white"
                routerLink="/clubs/debateClub"
                href="">DEBATE CLUB</a>
            </h5>
          </div>
          <div class="card-img-top position-relative">
            <a href="/clubs/debateClub">
              <div class="photo_wrapper">
                <div class="photo scale-with-grid clubS clubS-9">
                  <div class="overlay">
                    <span><img src="assets/img/school/sghsc_logo.png"
                        alt="image" /></span>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div class="clubCard card"
          (mouseover)="onCardHover()"
          (mouseout)="onCardLeave()">
          <div class="card-body text-white"
            style="background-color: rgb(20, 20, 92)">
            <h5 class="card-title">
              <a class="text-white"
                routerLink="/clubs/knowledgeFiestaClub"
                href="">KNOWLEDGE FIESTA CLUB</a>
            </h5>
          </div>
          <div class="card-img-top position-relative">
            <a href="/clubs/knowledgeFiestaClub">
              <div class="photo_wrapper">
                <div class="photo scale-with-grid clubS clubS-9">
                  <div class="overlay">
                    <span><img src="assets/img/school/sghsc_logo.png"
                        alt="image" /></span>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div class="clubCard card"
          (mouseover)="onCardHover()"
          (mouseout)="onCardLeave()">
          <div class="card-body text-white"
            style="background-color: rgb(20, 20, 92)">
            <h5 class="card-title">
              <a class="text-white"
                routerLink="/clubs/scoutClub"
                href="">SCOUT CLUB</a>
            </h5>
          </div>
          <div class="card-img-top position-relative">
            <a href="/clubs/scoutClub">
              <div class="photo_wrapper">
                <div class="photo scale-with-grid clubS clubS-9">
                  <div class="overlay">
                    <span><img src="assets/img/school/sghsc_logo.png"
                        alt="image" /></span>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
<!-- clubs end -->
