import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { EmitterService } from 'src/app/EmitterService';
import { Slide } from 'src/app/types';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit
{
  isBig: boolean;
  isSmall: boolean;
  randomBackgroundColor: string = '#000000';
  hoverShadowColor: string = '#00000033';
  slides: Slide[] = [];
  showFullContent = false;
  showFullOverview = false;
  showFullWhy = false;
  showFullIns = false;
  showFullBuilding = false;
  showFullCulture = false;
  Notices = [];
  subscription: Subscription;

  dynamicTape = [
    {
      Id: 1,
      Text: 'Holiday for Hijri New Year, Summer Vacation and Ashura',
      Link: '1.jpg',
      Date: new Date('2023-05-22'),
    },
    {
      Id: 2,
      Text: 'Second Terminal Examination - 2023',
      Link: '2.jpg',
      Date: new Date('2023-06-15'),
    },
    {
      Id: 3,
      Text: 'Routine for Pre-test Examination - 2023',
      Link: '3.jpg',
      Date: new Date('2023-06-15'),
    },
    {
      Id: 4,
      Text: 'Practical Board Exam Routine - 2023',
      Link: '4.jpg',
      Date: new Date('2023-06-15'),
    },
    {
      Id: 5,
      Text: 'Faculty Meeting',
      Link: '5.jpg',
      Date: new Date('2023-06-15'),
    },
    {
      Id: 6,
      Text: 'এইচএসসি ২০২৩ পরীক্ষার্থীদের জন্য বিশেষ বিজ্ঞপ্তি',
      Link: '6.jpg',
      Date: new Date('2023-06-15'),
    },
    {
      Id: 7,
      Text: '৯ম শ্রেণীতে রেজিস্ট্রেশন বিষয়ক বিশেষ বিজ্ঞপ্তি ',
      Link: '7.jpg',
      Date: new Date('2023-06-15'),
    },
    {
      Id: 8,
      Text: 'Thanksgiving Program',
      Link: '8.jpg',
      Date: new Date('2023-06-15'),
    },
    {
      Id: 9,
      Text: 'লেখা আহ্বান ',
      Link: '9.jpg',
      Date: new Date('2023-06-15'),
    },
    {
      Id: 10,
      Text: 'এইচএসসি ২০২৩ পরীক্ষার্থীদের জন্য বিশেষ বিজ্ঞপ্তি',
      Link: '10.jpg',
      Date: new Date('2023-06-15'),
    },
    {
      Id: 11,
      Text: 'Faculty Meeting',
      Link: '11.jpg',
      Date: new Date('2023-06-15'),
    },
    {
      Id: 12,
      Text: '1st Round Assessment of Year Final Examination 2023',
      Link: '12.jpg',
      Date: new Date('2023-07-23'),
    },
    {
      Id: 13,
      Text: '1st Round Assessment of Year Final Examination 2023, Classes: V, VIII & IX',
      Link: '13.jpeg',
      Date: new Date('2023-07-26'),
    },
    {
      Id: 14,
      Text: 'Make-Up Test - 2023',
      Link: '14.jpeg',
      Date: new Date('2023-07-26'),
    },
    {
      Id: 15,
      Text: 'Promotion Test for Class XI - 2023',
      Link: '15.jpeg',
      Date: new Date('2023-07-26'),
    },
    {
      Id: 16,
      Text: "HSC Candidate (Batch'2023) & All Teachers",
      Link: '16.jpeg',
      Date: new Date('2023-08-01'),
    },
    {
      Id: 17,
      Text: 'Students, Teachers and Parents of the Morning Shift',
      Link: '17.jpeg',
      Date: new Date('2023-08-01'),
    },
    {
      Id: 18,
      Text: 'Result of SSC Examination - 2023',
      Link: '18.jpeg',
      Date: new Date('2023-07-27'),
    },
    {
      Id: 19,
      Text: 'Handing out assessment report and parent-teacher meet of class V, VIII & IX',
      Link: '19.jpeg',
      Date: new Date('2023-08-02'),
    },
    {
      Id: 20,
      Text: 'New Class Schedule for Class XI (College Section)',
      Link: '20.jpeg',
      Date: new Date('2023-08-03'),
    },
    {
      Id: 21,
      Text: '২০২৩-২০২৪ শিক্ষাবর্ষে একাদশ শ্রেণীতে ভর্তি বিজ্ঞপ্তি',
      Link: '21.jpeg',
      Date: new Date('2023-08-08'),
    },
    {
      Id: 22,
      Text: 'একাদশ শ্রেণির ভর্তি প্রক্রিয়া ও নির্দেশিকা',
      Link: '22.jpg',
      Date: new Date('2023-08-08'),
    },
    {
      Id: 23,
      Text: '২০২৩ - ২০২৪ শিক্ষাবর্ষে একাদশ শ্রেণিতে ভর্তি (শুধুমাত্র গ্রেগরীয়ানদের জন্য)',
      Link: '23.jpeg',
      Date: new Date('2023-08-09'),
    },
    {
      Id: 24,
      Text: '২০২৩-২০২৪ শিক্ষাবর্ষে একাদশ শ্রেণীতে ভর্তি',
      Link: '24.jpeg',
      Date: new Date('2023-08-09'),
    },
    {
      Id: 25,
      Text: 'মানসিক দৃঢ়তা ও ধৈর্য প্রয়োজন',
      Link: '25.jpeg',
      Date: new Date('2023-08-09'),
    },
    {
      Id: 26,
      Text: 'এইচএসসি-২০২৩ শিক্ষাবর্ষের পরীক্ষার্থীদের প্রবেশ পত্র বিষয়ক বিশেষ বিজ্ঞপ্তি',
      Link: '26.jpeg',
      Date: new Date('2023-08-10'),
    },
  ];

  constructor(private elementRef: ElementRef,
    private emitterService: EmitterService
  )
  {
    this.smallScreen();
    // this.dynamicTape.sort((a, b) => b.Date.getTime() - a.Date.getTime());
    // this.dynamicTape.sort((a, b) =>
    // {
    //   if (a.Date.getTime() === b.Date.getTime())
    //   {
    //     return b.Id - a.Id;
    //   }
    // });




     this.subscription = this.emitterService.currentMessage.subscribe(
       (message) => {
         if (message === true) {
           let noticeData = localStorage.getItem('noticeData');
           let parsedData: any = JSON.parse(noticeData);

           this.Notices = parsedData.filter(
             (x) => x.ShowScroll === true && x.Id !== 1045
           );

           this.Notices.sort((a, b) => b.Id - a.Id);

          //  console.log(this.Notices, 'this.Notices');
         }
       }
     );
    
    // this.subscription = this.emitterService.currentMessage.subscribe(message =>
    // {
    //   if (message == true)
    //   {
    //     let noticeData = localStorage.getItem("noticeData");

    //     let parsedData: any = JSON.parse(noticeData);

    //     this.Notices = parsedData.filter((x) =>
    //       x.OutOfNotice == true
    //     );

    //     this.Notices.sort((a, b) => b.CreatedDate - a.CreatedDate);
    //     this.Notices.sort((a, b) =>
    //     {
    //       if (a.CreatedDate === b.CreatedDate)
    //       {
    //         return b.Id - a.Id;
    //       }
    //     });
    //     // console.log(this.Notices, 'this.Notices');
    //   }
    // })
  }

  ngOnInit()
  {
    this.randomBackgroundColor = this.getRandomColor();
    this.fetchHomePageData();
  }

  toggleShowContent()
  {
    this.showFullContent = !this.showFullContent;
  }

  toggleShowOverview()
  {
    this.showFullOverview = !this.showFullOverview;
  }

  toggleShowWhy()
  {
    this.showFullWhy = !this.showFullWhy;
  }

  toggleShowIns()
  {
    this.showFullIns = !this.showFullIns;
  }

  toggleShowBuilding()
  {
    this.showFullBuilding = !this.showFullBuilding;
  }
  toggleShowCulture()
  {
    this.showFullCulture = !this.showFullCulture;
  }

  fetchHomePageData()
  {
    fetch('assets/json/homePage.json')
      .then((response) => response.json())
      .then((data) =>
      {
        this.slides = data.slideImageTop;
      })
      .catch((error) => console.error('Error fetching data:', error));
  }

  @HostListener('window:resize')
  onResize()
  {
    this.smallScreen();
  }

  getRandomColor(): string
  {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++)
    {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  onCardHover()
  {
    this.hoverShadowColor = this.randomBackgroundColor;
  }

  onCardLeave()
  {
    this.hoverShadowColor = '#00000033';
  }

  smallScreen()
  {
    this.isSmall = window.innerWidth < 1300;
    this.isBig = window.innerWidth > 1300;
  }

  changeBackgroundImage(imageUrl: string)
  {
    const element = this.elementRef.nativeElement.querySelector('.bgImageTab');
    element.style.setProperty('--tab-background-image', `url(${imageUrl})`);
  }

  onNavigate(url: string)
  {
    window.open(`https://${url}`, '_blank');
  }

  NoAdmissionAlert()
  {
    alert("Admission services not open yet.");
    return;
  }
}
