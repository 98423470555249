<app-navbar-style-three></app-navbar-style-three>

<div class="page-title-area bg-image" style="height: 250px;">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Student</h2>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="subscribe-wrap-area">
    <div class="container">
        <div class="subscribe-wrap-inner-box">
            <p>Everyone deserves to get education in such an environment where students get all sort of opportunities to
                flourish
                their
                latent talents. St. Gregory’s High School is that of well-equipped institution. It provides well
                disciplined
                pleasant
                atmosphere, highly qualified, devoted teachers who are very encouraging and supportive to the students.
                Its enriched
                library , modern science laboratories , large playground , big Basketball court , facilities of playing
                other sort
                of
                indoor games and sports , all are attractive for studying at St. Gregory’s. It will be absolutely the
                perfect place
                for
                any student to polish and nurture his talent in the most useful way.
            </p>
        </div>
    </div>
</div>