<app-navbar-style-three></app-navbar-style-three>

<div class="page-title-area bg-image" style="height: 250px;">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Vice-Principal Message</h2>
                </div>
            </div>
        </div>
    </div>
</div>

<div>
    <div class="subscribe-wrap-area">
        <div class="container">
            <div class="subscribe-wrap-inner-box">
                <div class="row justify-content-center">
                    <article class="col-md-12 text-justify  p-4 ">
                        <div><img alt="Message From The Chairman" class="img-thumbnail img-fluid"
                                src="assets/img/school/vp.jpg"
                                style="float:left; height:200px; margin:0px 15px 10px 0px; width:200px"></div>

                        <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nostrum, dolorem sunt
                            necessitatibus doloremque, exercitationem officia iure ducimus sed ea magni quod officiis
                            hic quam? Error molestiae sapiente velit in! Id magnam totam quibusdam sint adipisci natus
                            eligendi, similique deserunt illo ratione, sit aperiam deleniti fuga ullam pariatur cum
                            illum eius. Possimus, distinctio. Debitis, nemo eos consectetur molestias totam veritatis
                            ullam. Odio id tempora et? Ipsum ducimus quaerat sint, aut autem qui, voluptatibus earum,
                            laboriosam hic soluta ratione sunt labore iure repellendus provident! Officiis ipsa fuga
                            porro non et ab sequi dolore. Obcaecati, neque exercitationem nulla expedita minima
                            praesentium iusto voluptatem?</p>

                        <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Laborum officia voluptatum, facilis
                            a placeat maxime beatae impedit sunt repudiandae illo repellat sed, aliquam rerum molestiae.
                            Blanditiis dolorum quae voluptatum ea eos placeat dolor amet quam fugit nisi id, consectetur
                            asperiores cum qui facilis quasi, mollitia quaerat ipsam, in corporis. Sit aliquam qui
                            soluta facere, vero accusantium non voluptas praesentium, debitis dolores deserunt, aperiam
                            consequuntur nostrum enim necessitatibus quae at libero reprehenderit. Perspiciatis facere
                            maiores perferendis, sint tenetur dolorum ad reprehenderit, esse obcaecati earum eligendi
                            cupiditate ut. Sint ullam ducimus dignissimos natus aspernatur temporibus repudiandae fuga
                            aperiam delectus, expedita iusto debitis ex repellat ipsa doloremque sapiente! Ea quisquam
                            nemo delectus itaque error aliquid? Debitis, molestiae omnis! Aspernatur eos accusantium,
                            minus aliquam eligendi ducimus porro magnam facere accusamus ipsum placeat eveniet
                            repellendus facilis, doloremque consectetur quis dolores, alias error odit nemo inventore!
                            Eius amet, cum ipsa voluptate laudantium sed recusandae repudiandae. Aperiam.</p>

                        <p><strong>Mr. X</strong><br>
                            Chairmans<br>
                        </p>
                    </article>
                </div>
            </div>
        </div>
    </div>
</div>