<app-navbar-style-three></app-navbar-style-three>

<div class="bg-image">
    <div class="container" style="padding-top: 30px; padding-bottom: 30px;">
        <div class="row">
            <div class="col-md-3">
                <div align="left" style="padding-left: 20px">
                    <p style="font-size: 26px">News & Events</p>
                </div>
                <div class="list-group list-group-flush">
                    <button *ngFor="let item of academic" (click)="selectItem(item)"
                        [class.active]="selectedItem === item"
                        class="newA linkStyle list-group-item list-group-item-action">{{camelCaseToTitleCase(item)}}</button>
                </div>
            </div>
            <div class="col-md-9">
                <div style="min-height: 800px;">
                    <div *ngFor="let item of academic">
                        <ng-container *ngIf="selectedItem === item">
                            <div *ngIf="item === 'academicCalender'">
                                <h3>{{camelCaseToTitleCase(item)}}</h3>
                                <hr>
                                <div class="container card" style="padding: 20px;">
                                    <h4>Session 2022-2023</h4>
                                    <p>Classes of freshman, existing regular students, and master's program freshman
                                        students start from
                                        <span style="color:blue; font-style: italic;">January 22, 2023</span>
                                    </p>
                                    <table class="table table-striped table-responsive-sm">
                                        <tbody>
                                            <ng-container *ngFor="let item of timelineData; let i = index">
                                                <tr *ngIf="i === 0 || item.month !== timelineData[i - 1].month">
                                                    <td colspan="3" style="width:150px;">
                                                        <p align="center"><strong>{{ item.month }}</strong></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="width:90px;">
                                                        <p align="center">{{ item.day }}</p>
                                                    </td>
                                                    <td style="width:403px;">
                                                        <p align="center">{{ item.event }}</p>
                                                    </td>
                                                </tr>
                                            </ng-container>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div *ngIf="item === 'academicRegulation'">
                                <h3>{{camelCaseToTitleCase(item)}}</h3>
                                <hr>
                                <div class="container card" style="padding: 20px;">
                                        <div>
                                            <img class="img-fluid"
                                                style="height: 300px; width: 100%; object-fit: cover; border-radius: 10px;"
                                                src="assets/img/school/academicRegulation.JPG">
                                        </div>
                                        <br>
                                        <p>
                                            St. Gregory's High School & College is a private school in Dhaka, Bangladesh. The school is run by the Holy Cross
                                            Brothers, a religious congregation of men who dedicate their lives to the education of young people. St. Gregory's is
                                            known for its academic excellence, its strong moral values, and its commitment to social justice. <br>
                                            
                                            The school has a strict code of conduct that students and parents are expected to follow. Students are required to be
                                            obedient and loyal, scholarly and hard working, respectful and honest, healthy, joyful and caring, and fostering the
                                            spirit of family growing as a man of good character. If a student is found to be in violation of the code of conduct,
                                            they may be subject to disciplinary action, up to and including expulsion. <br>
                                            
                                            The school also has a number of policies in place to ensure the safety and security of students. For example, teachers
                                            are authorized to check students' bags at any time, and the school reserves the right to check any student or visitor at
                                            any time. The school also prohibits the use of offensive or morally-wrong materials on campus. <br>
                                            
                                            St. Gregory's High School & College is a demanding school, but it also provides a supportive environment where students
                                            can learn and grow. The school's strict code of conduct helps to ensure that students are held accountable for their
                                            actions, and the school's policies help to keep students safe and secure.
                                        </p>
                                    <!-- <div class="accordion" id="accordionExample">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingOne">
                                                <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                                    data-bs-target="#collapseOne" aria-expanded="true"
                                                    aria-controls="collapseOne">
                                                    School
                                                </button>
                                            </h2>
                                            <div id="collapseOne" class="accordion-collapse collapse show"
                                                aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ad,
                                                        harum. Odit, blanditiis
                                                        quam autem
                                                        nostrum minus molestias incidunt porro fuga voluptates minima
                                                        eveniet quo ratione sint
                                                        expedita
                                                        ullam quisquam voluptatibus?</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingTwo">
                                                <button class="accordion-button collapsed" type="button"
                                                    data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                                                    aria-expanded="false" aria-controls="collapseTwo">
                                                    High School
                                                </button>
                                            </h2>
                                            <div id="collapseTwo" class="accordion-collapse collapse"
                                                aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p><span>Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                                            Cumque culpa iure ipsum,
                                                            enim quas
                                                            accusamus autem quidem. Illo, illum consequatur! Debitis
                                                            cumque assumenda aut earum
                                                            possimus
                                                            quos dolorem laboriosam excepturi!</span></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingThree">
                                                <button class="accordion-button collapsed" type="button"
                                                    data-bs-toggle="collapse" data-bs-target="#collapseThree"
                                                    aria-expanded="false" aria-controls="collapseThree">
                                                    College
                                                </button>
                                            </h2>
                                            <div id="collapseThree" class="accordion-collapse collapse"
                                                aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime,
                                                        odio doloremque
                                                        laudantium unde
                                                        tempora id voluptatem repellendus ipsa enim accusantium
                                                        praesentium ut! Quia quasi in ab
                                                        sint
                                                        accusantium quam nulla!</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                            <div *ngIf="item === 'curriculum'">
                                <h3>{{camelCaseToTitleCase(item)}}</h3>
                                <hr>
                                <div class="container card" style="padding: 20px;">
                                    <div>
                                        <img class="img-fluid" style="height: 300px; width: 100%; object-fit: cover; border-radius: 10px;"
                                            src="assets/img/school/curriculum.jpg">
                                    </div>
                                    <br>
                                    <p>
                                        St. Gregory's High School & College offers a rigorous academic program that prepares students for college and beyond.
                                        The school offers a wide range of courses, including honors and AP courses. Students are also required to participate in
                                        extracurricular activities, such as sports, clubs, and student government. <br>
                                        The arts are an important part of the curriculum at St. Gregory's High School & College. The school has a strong arts
                                        program that includes courses in music, art, drama, and dance. Students have the opportunity to participate in a variety
                                        of ensembles, including the choir, band, orchestra, and drama club. The school also offers art classes, dance classes,
                                        and theater classes. <br>
                                        Athletics are also a big part of the St. Gregory's High School & College experience. The school is a member of the New
                                        England Prep School Athletic Council (NEPSAC). The school offers a variety of sports, including football, basketball,
                                        baseball, softball, soccer, lacrosse, field hockey, and track and field. Students have the opportunity to compete at the
                                        varsity, junior varsity, and freshman levels. <br>
                                        Faith is an important part of the curriculum at St. Gregory's High School & College. Students are required to take a
                                        religion class each year. The school also offers a variety of faith-based activities, such as retreats, service
                                        projects, and prayer groups. <br>
                                        St. Gregory's High School & College is a great choice for students who are looking for a rigorous academic program with
                                        a strong focus on the arts, athletics, and faith. The school offers a variety of opportunities for students to grow and
                                        develop both academically and personally. <br>

                                    </p>
                                </div>
                            </div>
                            <div *ngIf="item === 'teachersInformation'">
                                <h3>{{camelCaseToTitleCase(item)}}</h3>
                                <hr>
                                <section class="team-area card" style="padding: 20px;">
                                    <div class="container">
                                        <div class="row justify-content-center">
                                            <h2 style="padding-bottom: 10px; color: blue; text-decoration:underline;">
                                                Professor</h2>
                                            <div class="col-lg-4 col-md-6 col-sm-6">
                                                <div class="single-team-box">
                                                    <div class="image">
                                                        <img src="assets/img/team-image/team19.jpg" alt="team">
                                                        <div class="social">
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-facebook-f"></i></a>
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-twitter"></i></a>
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-instagram"></i></a>
                                                        </div>
                                                    </div>
                                                    <div class="content">
                                                        <h3>John Smith</h3>
                                                        <span>Web Developer</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-4 col-md-6 col-sm-6">
                                                <div class="single-team-box">
                                                    <div class="image">
                                                        <img src="assets/img/team-image/team19.jpg" alt="team">
                                                        <div class="social">
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-facebook-f"></i></a>
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-twitter"></i></a>
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-instagram"></i></a>
                                                        </div>
                                                    </div>
                                                    <div class="content">
                                                        <h3>Lucy Eva</h3>
                                                        <span>Manager</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <h2 style="padding-bottom: 10px; color: blue; text-decoration:underline;">
                                                Lecturer</h2>
                                            <div class="col-lg-4 col-md-6 col-sm-6">
                                                <div class="single-team-box">
                                                    <div class="image">
                                                        <img src="assets/img/team-image/team19.jpg" alt="team">
                                                        <div class="social">
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-facebook-f"></i></a>
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-twitter"></i></a>
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-instagram"></i></a>
                                                        </div>
                                                    </div>
                                                    <div class="content">
                                                        <h3>Steven Smith</h3>
                                                        <span>Web Developer</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-4 col-md-6 col-sm-6">
                                                <div class="single-team-box">
                                                    <div class="image">
                                                        <img src="assets/img/team-image/team19.jpg" alt="team">
                                                        <div class="social">
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-facebook-f"></i></a>
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-twitter"></i></a>
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-instagram"></i></a>
                                                        </div>
                                                    </div>
                                                    <div class="content">
                                                        <h3>Sarah Lucy</h3>
                                                        <span>Web Developer</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-4 col-md-6 col-sm-6">
                                                <div class="single-team-box">
                                                    <div class="image">
                                                        <img src="assets/img/team-image/team19.jpg" alt="team">
                                                        <div class="social">
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-facebook-f"></i></a>
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-twitter"></i></a>
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-instagram"></i></a>
                                                        </div>
                                                    </div>
                                                    <div class="content">
                                                        <h3>David Warner</h3>
                                                        <span>Manager</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-4 col-md-6 col-sm-6">
                                                <div class="single-team-box">
                                                    <div class="image">
                                                        <img src="assets/img/team-image/team19.jpg" alt="team">
                                                        <div class="social">
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-facebook-f"></i></a>
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-twitter"></i></a>
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-instagram"></i></a>
                                                        </div>
                                                    </div>
                                                    <div class="content">
                                                        <h3>Meg Lanning</h3>
                                                        <span>Web Developer</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-4 col-md-6 col-sm-6">
                                                <div class="single-team-box">
                                                    <div class="image">
                                                        <img src="assets/img/team-image/team19.jpg" alt="team">
                                                        <div class="social">
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-facebook-f"></i></a>
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-twitter"></i></a>
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-instagram"></i></a>
                                                        </div>
                                                    </div>
                                                    <div class="content">
                                                        <h3>Jason Roy</h3>
                                                        <span>Web Developer</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-4 col-md-6 col-sm-6">
                                                <div class="single-team-box">
                                                    <div class="image">
                                                        <img src="assets/img/team-image/team19.jpg" alt="team">
                                                        <div class="social">
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-facebook-f"></i></a>
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-twitter"></i></a>
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-instagram"></i></a>
                                                        </div>
                                                    </div>
                                                    <div class="content">
                                                        <h3>Joe Marta</h3>
                                                        <span>Manager</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-4 col-md-6 col-sm-6">
                                                <div class="single-team-box">
                                                    <div class="image">
                                                        <img src="assets/img/team-image/team19.jpg" alt="team">
                                                        <div class="social">
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-facebook-f"></i></a>
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-twitter"></i></a>
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-instagram"></i></a>
                                                        </div>
                                                    </div>
                                                    <div class="content">
                                                        <h3>Joe Root</h3>
                                                        <span>Web Developer</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                            <div *ngIf="item === 'staffInformation'">
                                <h3>{{camelCaseToTitleCase(item)}}</h3>
                                <hr>
                                <section class="team-area card" style="padding: 20px;">
                                    <div class="container">
                                        <div class="row justify-content-center">
                                            <div class="col-lg-4 col-md-6 col-sm-6">
                                                <div class="single-team-box">
                                                    <div class="image">
                                                        <img src="assets/img/team-image/team19.jpg" alt="team">
                                                        <div class="social">
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-facebook-f"></i></a>
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-twitter"></i></a>
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-instagram"></i></a>
                                                        </div>
                                                    </div>
                                                    <div class="content">
                                                        <h3>John Smith</h3>
                                                        <span>Web Developer</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-4 col-md-6 col-sm-6">
                                                <div class="single-team-box">
                                                    <div class="image">
                                                        <img src="assets/img/team-image/team19.jpg" alt="team">
                                                        <div class="social">
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-facebook-f"></i></a>
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-twitter"></i></a>
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-instagram"></i></a>
                                                        </div>
                                                    </div>
                                                    <div class="content">
                                                        <h3>Lucy Eva</h3>
                                                        <span>Manager</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-4 col-md-6 col-sm-6">
                                                <div class="single-team-box">
                                                    <div class="image">
                                                        <img src="assets/img/team-image/team19.jpg" alt="team">
                                                        <div class="social">
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-facebook-f"></i></a>
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-twitter"></i></a>
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-instagram"></i></a>
                                                        </div>
                                                    </div>
                                                    <div class="content">
                                                        <h3>Steven Smith</h3>
                                                        <span>Web Developer</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-4 col-md-6 col-sm-6">
                                                <div class="single-team-box">
                                                    <div class="image">
                                                        <img src="assets/img/team-image/team19.jpg" alt="team">
                                                        <div class="social">
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-facebook-f"></i></a>
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-twitter"></i></a>
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-instagram"></i></a>
                                                        </div>
                                                    </div>
                                                    <div class="content">
                                                        <h3>Sarah Lucy</h3>
                                                        <span>Web Developer</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-4 col-md-6 col-sm-6">
                                                <div class="single-team-box">
                                                    <div class="image">
                                                        <img src="assets/img/team-image/team19.jpg" alt="team">
                                                        <div class="social">
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-facebook-f"></i></a>
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-twitter"></i></a>
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-instagram"></i></a>
                                                        </div>
                                                    </div>
                                                    <div class="content">
                                                        <h3>David Warner</h3>
                                                        <span>Manager</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-4 col-md-6 col-sm-6">
                                                <div class="single-team-box">
                                                    <div class="image">
                                                        <img src="assets/img/team-image/team19.jpg" alt="team">
                                                        <div class="social">
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-facebook-f"></i></a>
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-twitter"></i></a>
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-instagram"></i></a>
                                                        </div>
                                                    </div>
                                                    <div class="content">
                                                        <h3>Meg Lanning</h3>
                                                        <span>Web Developer</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-4 col-md-6 col-sm-6">
                                                <div class="single-team-box">
                                                    <div class="image">
                                                        <img src="assets/img/team-image/team19.jpg" alt="team">
                                                        <div class="social">
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-facebook-f"></i></a>
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-twitter"></i></a>
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-instagram"></i></a>
                                                        </div>
                                                    </div>
                                                    <div class="content">
                                                        <h3>Jason Roy</h3>
                                                        <span>Web Developer</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-4 col-md-6 col-sm-6">
                                                <div class="single-team-box">
                                                    <div class="image">
                                                        <img src="assets/img/team-image/team19.jpg" alt="team">
                                                        <div class="social">
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-facebook-f"></i></a>
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-twitter"></i></a>
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-instagram"></i></a>
                                                        </div>
                                                    </div>
                                                    <div class="content">
                                                        <h3>Joe Marta</h3>
                                                        <span>Manager</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-4 col-md-6 col-sm-6">
                                                <div class="single-team-box">
                                                    <div class="image">
                                                        <img src="assets/img/team-image/team19.jpg" alt="team">
                                                        <div class="social">
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-facebook-f"></i></a>
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-twitter"></i></a>
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-instagram"></i></a>
                                                        </div>
                                                    </div>
                                                    <div class="content">
                                                        <h3>Joe Root</h3>
                                                        <span>Web Developer</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                            <div *ngIf="item === 'employeeInformation'">
                                <h3>{{camelCaseToTitleCase(item)}}</h3>
                                <hr>
                                <section class="team-area card" style="padding: 20px;">
                                    <div class="container">
                                        <div class="row justify-content-center">
                                            <div class="col-lg-4 col-md-6 col-sm-6">
                                                <div class="single-team-box">
                                                    <div class="image">
                                                        <img src="assets/img/team-image/team19.jpg" alt="team">
                                                        <div class="social">
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-facebook-f"></i></a>
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-twitter"></i></a>
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-instagram"></i></a>
                                                        </div>
                                                    </div>
                                                    <div class="content">
                                                        <h3>John Smith</h3>
                                                        <span>Web Developer</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-4 col-md-6 col-sm-6">
                                                <div class="single-team-box">
                                                    <div class="image">
                                                        <img src="assets/img/team-image/team19.jpg" alt="team">
                                                        <div class="social">
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-facebook-f"></i></a>
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-twitter"></i></a>
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-instagram"></i></a>
                                                        </div>
                                                    </div>
                                                    <div class="content">
                                                        <h3>Lucy Eva</h3>
                                                        <span>Manager</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-4 col-md-6 col-sm-6">
                                                <div class="single-team-box">
                                                    <div class="image">
                                                        <img src="assets/img/team-image/team19.jpg" alt="team">
                                                        <div class="social">
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-facebook-f"></i></a>
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-twitter"></i></a>
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-instagram"></i></a>
                                                        </div>
                                                    </div>
                                                    <div class="content">
                                                        <h3>Steven Smith</h3>
                                                        <span>Web Developer</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-4 col-md-6 col-sm-6">
                                                <div class="single-team-box">
                                                    <div class="image">
                                                        <img src="assets/img/team-image/team19.jpg" alt="team">
                                                        <div class="social">
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-facebook-f"></i></a>
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-twitter"></i></a>
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-instagram"></i></a>
                                                        </div>
                                                    </div>
                                                    <div class="content">
                                                        <h3>Sarah Lucy</h3>
                                                        <span>Web Developer</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-4 col-md-6 col-sm-6">
                                                <div class="single-team-box">
                                                    <div class="image">
                                                        <img src="assets/img/team-image/team19.jpg" alt="team">
                                                        <div class="social">
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-facebook-f"></i></a>
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-twitter"></i></a>
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-instagram"></i></a>
                                                        </div>
                                                    </div>
                                                    <div class="content">
                                                        <h3>David Warner</h3>
                                                        <span>Manager</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-4 col-md-6 col-sm-6">
                                                <div class="single-team-box">
                                                    <div class="image">
                                                        <img src="assets/img/team-image/team19.jpg" alt="team">
                                                        <div class="social">
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-facebook-f"></i></a>
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-twitter"></i></a>
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-instagram"></i></a>
                                                        </div>
                                                    </div>
                                                    <div class="content">
                                                        <h3>Meg Lanning</h3>
                                                        <span>Web Developer</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-4 col-md-6 col-sm-6">
                                                <div class="single-team-box">
                                                    <div class="image">
                                                        <img src="assets/img/team-image/team19.jpg" alt="team">
                                                        <div class="social">
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-facebook-f"></i></a>
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-twitter"></i></a>
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-instagram"></i></a>
                                                        </div>
                                                    </div>
                                                    <div class="content">
                                                        <h3>Jason Roy</h3>
                                                        <span>Web Developer</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-4 col-md-6 col-sm-6">
                                                <div class="single-team-box">
                                                    <div class="image">
                                                        <img src="assets/img/team-image/team19.jpg" alt="team">
                                                        <div class="social">
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-facebook-f"></i></a>
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-twitter"></i></a>
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-instagram"></i></a>
                                                        </div>
                                                    </div>
                                                    <div class="content">
                                                        <h3>Joe Marta</h3>
                                                        <span>Manager</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-4 col-md-6 col-sm-6">
                                                <div class="single-team-box">
                                                    <div class="image">
                                                        <img src="assets/img/team-image/team19.jpg" alt="team">
                                                        <div class="social">
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-facebook-f"></i></a>
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-twitter"></i></a>
                                                            <a href="#" target="_blank"><i
                                                                    class="fab fa-instagram"></i></a>
                                                        </div>
                                                    </div>
                                                    <div class="content">
                                                        <h3>Joe Root</h3>
                                                        <span>Web Developer</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>