<app-navbar-style-three></app-navbar-style-three>

<div class="page-title-area bg-image" style="height: 250px;">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Mision & Vision</h2>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="subscribe-wrap-area">
    <div class="container">
        <div class="subscribe-wrap-inner-box">
            <p>To aid students in developing mature sense of obligation to the creator, country and
                their fellowmen, to impart sound
                education, to form praiseworthy character, and to develop in the students the habits of
                true discipline and self
                efficiency to enable them to become dutiful and patriotic citizens of Bangladesh.</p>
        </div>
    </div>
</div>