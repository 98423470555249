<app-navbar-style-three></app-navbar-style-three>

<div class="bg-image">
    <div class="container" style="padding-top: 30px; padding-bottom: 30px;">
        <div class="row">
            <div class="col-md-3">
                <div align="left" style="padding-left: 20px">
                    <p style="font-size: 26px">News & Events</p>
                </div>
                <div class="list-group list-group-flush">
                    <button *ngFor="let item of administration" (click)="selectItem(item)"
                        [class.active]="selectedItem === item"
                        class="newA linkStyle list-group-item list-group-item-action">{{camelCaseToTitleCase(item)}}</button>
                </div>
            </div>
            <div class="col-md-9">
                <div style="min-height: 800px;">
                    <div *ngFor="let item of administration">
                        <ng-container *ngIf="selectedItem === item">
                            <div *ngIf="item === 'governingBody'">
                                <h3>{{camelCaseToTitleCase(item)}}</h3>
                                <hr>
                                <div class="container card" style="padding: 20px;">
                                    <div class="row justify-content-center">
                                        <div class="col-lg-5 col-md-6 col-sm-6">
                                            <div class="single-team-box">
                                                <div class="image imageGb">
                                                    <img src="assets/img/school/gb/1.JPG" alt="team">
                                                </div>
                                                <div class="content">
                                                    <h3>Rev. Fr. Kamal Corrraya</h3>
                                                    <span>Chairman</span>
                                                </div>
                                            </div>
                                        </div>
                                        <h3></h3>
                                        <div class="col-lg-4 col-md-6 col-sm-6">
                                            <div class="single-team-box">
                                                <div class="image imageGb">
                                                    <img src="assets/img/school/gb/2.jpg" alt="team">
                                                </div>
                                                <div class="content">
                                                    <h3>Mrs. Adlin Corraya</h3>
                                                    <span>Selected Female Guardian Representative</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6 col-sm-6">
                                            <div class="single-team-box">
                                                <div class="image imageGb">
                                                    <img src="assets/img/school/gb/3.jpg" alt="team">
                                                </div>
                                                <div class="content">
                                                    <h3>Dr. Md. Kutub Uddin</h3>
                                                    <span>Guardian Representative</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6 col-sm-6">
                                            <div class="single-team-box">
                                                <div class="image imageGb">
                                                    <img src="assets/img/school/gb/4.jpg" alt="team">
                                                </div>
                                                <div class="content">
                                                    <h3>Dr. Ranjit Sen Chowdhury</h3>
                                                    <span>Guardian Representative</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6 col-sm-6">
                                            <div class="single-team-box">
                                                <div class="image imageGb">
                                                    <img src="assets/img/school/gb/5.jpg" alt="team">
                                                </div>
                                                <div class="content">
                                                    <h3>Mr. Richard Gomes</h3>
                                                    <span>Teacher Representative</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6 col-sm-6">
                                            <div class="single-team-box">
                                                <div class="image imageGb">
                                                    <img src="assets/img/school/gb/6.jpg" style="object-fit: fill !important;" alt="team">
                                                </div>
                                                <div class="content">
                                                    <h3>Md. Abdul Mannan Lavlu</h3>
                                                    <span>Teacher Representative</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6 col-sm-6">
                                            <div class="single-team-box">
                                                <div class="image imageGb">
                                                    <img src="assets/img/school/gb/7.jpg" alt="team">
                                                </div>
                                                <div class="content">
                                                    <h3>Brother. Ujjal Placid Pereira, csc</h3>
                                                    <span>Principal & Secretary</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div *ngIf="item === 'charimanMessage'">
                                <h3>{{camelCaseToTitleCase(item)}}</h3>
                                <hr>
                                <div class="container card" style="padding: 20px;">
                                    <div class="row justify-content-center">
                                        <article class="col-md-12 text-justify  p-4 ">
                                            <div><img alt="Message From The Chairman" class="img-thumbnail img-fluid"
                                                    src="assets/img/school/chairman.JPG"
                                                    style="float:left; height:200px; margin:0px 15px 10px 0px; width:200px">
                                            </div>

                                            <p style="text-align: justify;">পৃথিবী একটি সুন্দর এবং সম্ভাবনাময় জায়গা। এটি সৃষ্টিকর্তার সেরা সৃষ্টি, এবং এটি আমাদের জন্য উপহার। এখানে অনেক কিছু আছে যা
                                            আমরা এখনও আবিষ্কার করিনি, এবং আমরা এই পৃথিবীর সমস্ত সম্পদ সবার সাথে ন্যায্যভাবে ভাগ করে নিতে পারি। বাস্তবতা হল যে আমরা
                                            সবাই সমানভাবে উপকৃত হচ্ছি না। অনেক মানুষ আছে যারা অভাবী, এবং অনেক মানুষ আছে যারা সম্পদের পাহাড় গড়ছে। আমরা এই সমস্যা
                                            সমাধান করতে পারি যদি আমরা সবাই পরস্পরকে সহায়তা করি এবং আমাদের সম্পদ ন্যায্যভাবে ভাগ করে নিই। <br>
                                            
                                            আমরা চাই আমাদের সন্তানরা এমন এক পৃথিবীতে বড় হবে যেখানে তারা সকলকে ভালবাসবে, শ্রদ্ধা করবে এবং সহযোগিতা করবে। আমরা চাই
                                            তারা অর্থ-গরিমায় নয়, তাদের পরিচয় হবে একই শিক্ষা প্রতিষ্ঠানের অধ্যয়নরত শিক্ষার্থী হিসেবে। আমরা চাই তারা মানবিকতা,
                                            মূল্যবোধ, সামাজিক আদব-কায়দা, দেশ-জাতির প্রতি দায়িত্ববোধ এবং প্রকৃত ধর্মীয় চেতনায় বেড়ে উঠুক। আমরা বিশ্বাস করি যে শিক্ষাই
                                            আমাদের সন্তানদের এই পৃথিবীকে একটি ভাল জায়গা করে তোলার ক্ষমতা দেবে। আমরা তাদের ভাল মানুষ হিসেবে বেড়ে উঠতে সাহায্য করব,
                                            এবং আমরা তাদের পৃথিবীর জন্য দায়িত্বশীল নাগরিক হতে শেখাব। আমরা বিশ্বাস করি যে আমাদের সন্তানরা এই পৃথিবীকে একটি ভাল জায়গা
                                            করে তুলতে পারে। তারা আমাদের ভবিষ্যৎ, এবং আমরা তাদের জন্য প্রতিশ্রুতিবদ্ধ। পৃথিবী একটি সুন্দর জায়গা, কিন্তু এটি একটি
                                            চ্যালেঞ্জিং জায়গাও। এখানে অনেক সম্পদ আছে, কিন্তু এখানে অনেক দারিদ্র্যও আছে। এখানে অনেক ভালবাসা আছে, কিন্তু এখানে অনেক
                                            ঘৃণাও আছে। আমরা বিশ্বাস করি যে আমরা এই চ্যালেঞ্জগুলি কাটিয়ে উঠতে পারি যদি আমরা সবাই একসঙ্গে কাজ করি। আমরা বিশ্বাস করি যে
                                            আমরা একটি ভাল পৃথিবী তৈরি করতে পারি যেখানে সকলের জন্য সুযোগ থাকবে। আমরা আমাদের সন্তানদের জন্য এই পৃথিবীটিকে আরও ভাল করে
                                            তোলার জন্য প্রতিশ্রুতিবদ্ধ। আমরা তাদের ভাল মানুষ হিসেবে বেড়ে উঠতে সাহায্য করব, এবং আমরা তাদের পৃথিবীর জন্য দায়িত্বশীল
                                            নাগরিক হতে শেখাব। <br>
                                            
                                            আমরা বিশ্বাস করি যে আমাদের সন্তানরা এই পৃথিবীকে একটি ভাল জায়গা করে তুলতে পারে। তারা আমাদের ভবিষ্যৎ, এবং আমরা তাদের জন্য
                                            প্রতিশ্রুতিবদ্ধ। <br>
                                            <p><strong>Fr. Kamal Corraya</strong><br>
                                                Chairman<br>
                                            </p>
                                        </article>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="item === 'principalMessage'">
                                <h3>{{camelCaseToTitleCase(item)}}</h3>
                                <hr>
                                <div class="container card" style="padding: 20px;">
                                    <div class="row justify-content-center">
                                        <article class="col-md-12 text-justify  p-4 ">
                                            <div><img alt="Message From The Chairman" class="img-thumbnail img-fluid"
                                                    src="assets/img/school/principal.JPG"
                                                    style="float:left; height:200px; margin:0px 15px 10px 0px; width:200px">
                                            </div>

                                            <p style="text-align: justify;">I am truly honored to be the new principal of St. Gregory's High School and College. This school has a long and
                                            distinguished history, and I am committed to continuing its tradition of academic excellence and holistic human
                                            formation. <br>
                                            I would like to thank all of the teachers, staff, and parents for their hard work and dedication. I also want to thank
                                            the students for their enthusiasm and spirit. Together, we can create a school where all students can thrive. <br>
                                            I believe that a good student has three qualities: personality, knowledge, and skill. St. Gregory's High School and
                                            College is committed to helping students develop all three of these qualities. <br>
                                            In terms of personality, we want our students to be kind, compassionate, and respectful. We also want them to be
                                            confident and self-aware. <br>
                                            In terms of knowledge, we want our students to be well-rounded learners. They should have a strong foundation in the
                                            basics, but they should also be exposed to new ideas and perspectives. <br>
                                            In terms of skill, we want our students to be able to think critically and solve problems. We also want them to be able
                                            to communicate effectively and work collaboratively. <br>
                                            I believe that these three qualities are essential for success in today's world. A student with these qualities will be
                                            able to contribute to society in meaningful ways. <br>
                                            I am excited to work with the students, teachers, and staff of St. Gregory's High School and College to create a school
                                            where all students can thrive. Together, we can make a difference in the world. <br>
                                            Thank you. <br>
                                            </p>

                                            <p><strong>Brother Ujjal Placid Pereira, CSC</strong><br>
                                                Principal<br>
                                            </p>
                                        </article>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="item === 'vice-PrincipalMessage'">
                                <h3>{{camelCaseToTitleCase(item)}}</h3>
                                <hr>
                                <div class="container card" style="padding: 20px;">
                                    <div class="row justify-content-center">
                                        <article class="col-md-12 text-justify  p-4 ">
                                            <div><img alt="Message From The Chairman" class="img-thumbnail img-fluid"
                                                    src="assets/img/school/vp.JPG"
                                                    style="float:left; height:200px; margin:0px 15px 10px 0px; width:200px">
                                            </div>

                                            <p style="text-align: justify;">Welcome to our website, There's a proverb that says, "A tree is known by its fruits." Similarly, your teenage years are
                                            a time of self-formation that will shape your future life. <br>
                                            Dear Students, As experts in browsing social media, you have the ability to find whatever you want on the internet. All
                                            kinds of information are at your fingertips. If you study diligently at home, you can excel in your class and even
                                            achieve a GPA-5 in your board exams. On the other hand, you may lead a comfortable life, build relationships through
                                            Facebook, and have certain amenities at home or at coaching centers. However, what will be lacking in your life? <br>
                                            It's certain that you will have different experiences during your time at school and college. As you know, St. Gregory's
                                            High School and College is a renowned institution in the country. Many parents dream of their sons getting a chance to
                                            study here. <br>
                                            Take pride in being a Gregorian. What makes you proud are the abundant opportunities for studying and acquiring
                                            knowledge, the cultivation of discipline, a positive environment, co-curricular programs, and various facilities. Yes,
                                            dear students, along with academic education, we also focus on your overall development inside and outside the
                                            classroom. I believe that through participation in club activities and various co-curricular programs, you are learning
                                            basic courtesy and putting it into practice. <br>
                                            Dear Parents, Embark on a journey with your son and join us in shaping his bright future. Sometimes, it's important to
                                            say "No" to your son instead of always saying "Yes." As parents, you have the right to intervene and guide your
                                            children's actions. You are their role models, and you should mold them according to your aspirations. Here are some
                                            suggestions for the betterment of your sons: <br>
                                            Avoid giving your son a mobile phone at such an early stage. Set restrictions on the use of social media. <br>
                                            Monitor your son's friendships, study habits, and bedroom environment. <br>
                                            Ensure that your son dedicates at least four to five hours to studying every day. <br>
                                            Stay in touch with the class and subject teachers to track attendance, class notes, and other important matters. <br>
                                            Encourage your son to participate in various club activities and school programs. <br>
                                            Teach basic manners at home and engage in discussions about positive topics, always keeping your son informed. <br>
                                            Finally, I would like to remind you, dear students, that your life is a gift from God. Nurture it with a positive
                                            attitude and refrain from squandering opportunities that may lead to regret later on. <br>
</p>

                                            <p><strong>Brother Leonard Chandan Rozario, CSC</strong><br>
                                                Vice-Principal<br>
                                            </p>
                                        </article>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="item === 'formerPrincipal'">
                                <h3>{{camelCaseToTitleCase(item)}}</h3>
                                <hr>
                                <div class="container card" style="padding: 20px;">
                                    <div class="row justify-content-center">
                                        <div class="col-lg-4 col-md-6 col-sm-6">
                                            <div class="single-team-box">
                                                <div class="image">
                                                    <img src="assets/img/team-image/team19.jpg" alt="team">
                                                </div>
                                                <div class="content">
                                                    <h3>Rev. Fr. Kamal Corraya</h3>
                                                    <span>Former Pricipal</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6 col-sm-6">
                                            <div class="single-team-box">
                                                <div class="image">
                                                    <img src="assets/img/team-image/team19.jpg" alt="team">
                                                </div>
                                                <div class="content">
                                                    <h3>Mr. Richard Gomes</h3>
                                                    <span>Former Pricipal</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6 col-sm-6">
                                            <div class="single-team-box">
                                                <div class="image">
                                                    <img src="assets/img/team-image/team19.jpg" alt="team">
                                                </div>
                                                <div class="content">
                                                    <h3>Md. Abdul Mannan Lovlu</h3>
                                                    <span>Former Pricipal</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6 col-sm-6">
                                            <div class="single-team-box">
                                                <div class="image">
                                                    <img src="assets/img/team-image/team19.jpg" alt="team">
                                                </div>
                                                <div class="content">
                                                    <h3>Dr. Md. Kutub Uddin</h3>
                                                    <span>Former Pricipal</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6 col-sm-6">
                                            <div class="single-team-box">
                                                <div class="image">
                                                    <img src="assets/img/team-image/team19.jpg" alt="team">
                                                </div>
                                                <div class="content">
                                                    <h3>Dr. Ranajit Sen Chowdhury</h3>
                                                    <span>Former Pricipal</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6 col-sm-6">
                                            <div class="single-team-box">
                                                <div class="image">
                                                    <img src="assets/img/team-image/team19.jpg" alt="team">
                                                </div>
                                                <div class="content">
                                                    <h3>Mrs. Edline Corraya</h3>
                                                    <span>Former Pricipal</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6 col-sm-6">
                                            <div class="single-team-box">
                                                <div class="image">
                                                    <img src="assets/img/team-image/team19.jpg" alt="team">
                                                </div>
                                                <div class="content">
                                                    <h3>Brother Ujjal Placid Pereira, Csc</h3>
                                                    <span>Former Pricipal</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="item === 'brothers'">
                                <h3>{{camelCaseToTitleCase(item)}}</h3>
                                <hr>
                                <div class="container card" style="padding: 20px;">
                                    <div class="row justify-content-center">
                                        <div class="col-lg-4 col-md-6 col-sm-6">
                                            <div class="single-team-box">
                                                <div class="image">
                                                    <img src="assets/img/school/brothers/1.JPG" alt="team">
                                                </div>
                                                <div class="content">
                                                    <h3>Leonard Chandan Rozario, CSC</h3>
                                                    <span>Brother</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6 col-sm-6">
                                            <div class="single-team-box">
                                                <div class="image">
                                                    <img src="assets/img/school/brothers/2.JPG" alt="team">
                                                </div>
                                                <div class="content">
                                                    <h3>Shoylan J. Rozario, CSC, (Prefect of Discipline)</h3>
                                                    <span>Brother</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6 col-sm-6">
                                            <div class="single-team-box">
                                                <div class="image">
                                                    <img src="assets/img/school/brothers/3.JPG" alt="team">
                                                </div>
                                                <div class="content">
                                                    <h3>Bhubon Benjamin Costa, CSC</h3>
                                                    <span>Brother</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6 col-sm-6">
                                            <div class="single-team-box">
                                                <div class="image">
                                                    <img src="assets/img/school/brothers/4.JPG" alt="team">
                                                </div>
                                                <div class="content">
                                                    <h3>Nirmol Francis Gomes, CSC</h3>
                                                    <span>Brother</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6 col-sm-6">
                                            <div class="single-team-box">
                                                <div class="image">
                                                    <img src="assets/img/school/brothers/5.jpg" alt="team">
                                                </div>
                                                <div class="content">
                                                    <h3>Prodip Louis Rozario, CSC</h3>
                                                    <span>Brother</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>