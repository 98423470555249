import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, ParamMap } from "@angular/router";

interface Image {
    src: string;
    thumbnail: string;
    alt: string;
}
@Component({
    selector: "app-faq",
    templateUrl: "./faq.component.html",
    styleUrls: ["./faq.component.scss"],
})
export class FaqComponent implements OnInit {
    english23: Image[] = [
        {
            src: "assets/img/school/clubs/1.jpg",
            thumbnail: "assets/img/school/clubs/1.jpg",
            alt: "Image 01",
        },
        {
            src: "assets/img/school/clubs/2.jpg",
            thumbnail: "assets/img/school/clubs/2.jpg",
            alt: "Image 02",
        },
        {
            src: "assets/img/school/clubs/3.jpg",
            thumbnail: "assets/img/school/clubs/3.jpg",
            alt: "Image 03",
        },
    ];
    english22: Image[] = [
        {
            src: "assets/img/school/clubs/4.jpg",
            thumbnail: "assets/img/school/clubs/4.jpg",
            alt: "Image 01",
        },
        {
            src: "assets/img/school/clubs/5.jpg",
            thumbnail: "assets/img/school/clubs/5.jpg",
            alt: "Image 02",
        },
        {
            src: "assets/img/school/clubs/6.jpg",
            thumbnail: "assets/img/school/clubs/6.jpg",
            alt: "Image 03",
        },
    ];

    clubs: string[] = [
        "englishClub",
        "ycsClub",
        "sportsClub",
        "quizMathClub",
        "scienceClub",
        "artHandwritingClub",
        "ecologyClub",
        "artClub",
        "chessClub",
        "debateClub",
        "knowledgeFiestaClub",
        "culturalClub",
    ];
    selectedClub: string = "";

    camelCaseToTitleCase(name: string): string {
        // Convert the first character to uppercase
        let convertedName = name.charAt(0).toUpperCase() + name.slice(1);

        // Look for capital letters preceded by lowercase letters and insert a space
        convertedName = convertedName.replace(/([a-z])([A-Z])/g, "$1 $2");

        return convertedName;
    }

    constructor(private route: ActivatedRoute, private location: Location) {}

    ngOnInit() {
        this.route.paramMap.subscribe((params: ParamMap) => {
            const club = params.get("club");
            if (club && this.clubs.includes(club)) {
                this.selectedClub = club;
            } else {
                this.selectedClub = this.clubs[0];
                this.location.replaceState(`/clubs/${this.selectedClub}`);
            }
        });
    }

    selectClub(club: string) {
        this.selectedClub = club;
        this.location.go(`/clubs/${club}`);
    }
}
