<app-navbar-style-three></app-navbar-style-three>

<div class="bg-image">
    <div class="container" style="padding-top: 30px; padding-bottom: 30px;">
        <div class="row">
            <div class="col-md-3">
                <div align="left" style="padding-left: 20px">
                    <p style="font-size: 26px">News & Events</p>
                </div>
                <div class="list-group list-group-flush">
                    <button *ngFor="let item of about" (click)="selectItem(item)" [class.active]="selectedItem === item"
                        class="newA linkStyle list-group-item list-group-item-action">{{camelCaseToTitleCase(item)}}</button>
                </div>
            </div>
            <div class="col-md-9">
                <div style="min-height: 800px;">
                    <div *ngFor="let item of about">
                        <div *ngIf="selectedItem === item">
                            <h3>{{camelCaseToTitleCase(item)}}</h3>
                            <hr>
                            <ng-container [ngSwitch]="getContent(item)?.template">
                                <ng-container *ngSwitchCase="'history'">
                                    <div class="container card" style="padding: 20px;">
                                        <div>
                                            <img class="img-fluid" style="height: 300px; width: 100%; object-fit: cover; border-radius: 10px;"
                                                src="assets/img/school/history/4.JPG">
                                        </div>
                                        <br>
                                        <br>
                                            <div class="container" style="text-align: justify !important;">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <p>St. Gregory’s High School established by Rev. Father Gregory De Groote, a Benedictine Priest in January
                                                            1882 set many
                                                            historical records towards its journey of education as well as humanitarian activities. The school came
                                                            into being with
                                                            a handful of boys. But soon school had to be closed down twice. The school resumed then in 1889 and
                                                            Father Francis
                                                            Boeres CSC took the charge of the school. Within twenty years, he set the school upon its solid
                                                            foundation by his
                                                            untiring efforts. The school was initially used to teach only Eurasian and European children. Indian
                                                            students were only
                                                            allowed under special permission and that was only fifteen percent. In 1898 there were six Bengali boys
                                                            and two Bengali
                                                            girls among the students. In 1912 the girls section was separated from the boys and St. Francis Xavier’s
                                                            came into
                                                            existence. In 1924 Board’s recognition was granted and in 1928 six boys were sent for high school exam
                                                            and all came out
                                                            with good results. In 1934 Dalim Guha topped the list in the board exam. He was one of the best products
                                                            of St.
                                                            Gregory’s High School. He also stood first in ISC and stood First Class First in BSC with Honors in
                                                            Physics from
                                                            Calcutta University in 1938. In the same year his friend Protap Chandra stood third in the board
                                                            Examination with letter
                                                            marks in English, a very rare event. He also got a record of getting 97 percent marks in History from
                                                            the Board of
                                                            Intermediate, Calcutta University. In 1936, 37, 38 Nirmal Chandra Basu Roy, Jatindralal Das, Abdur Rouf,
                                                            stood First in
                                                            the Board Examination respectively. In 1941 Ashim Kumar Mukherjee came first in merit. After three years
                                                            (1944) St.</p>
                                                    </div>
                                                    <br>
                                                    <div class="col-md-12">
                                                        <img class="d-flex img-thumbnail float-start mb-3" style="height:300px; width:300px; margin:0px 15px 10px 0px;"
                                                            src="assets/img/school/history/2.jpg" alt="Image 2" />
                                                        <p>Gregory’s boys got 6 positions in the merit list including the first and second position occupied by
                                                            Tarak Das and Arun
                                                            kumar Basak respectively. Tajuddin Ahmed the First Prime Minister of Bangladesh secured 12th place in
                                                            the merit list. In
                                                            the year 1947 the first, second and third position in the merit list were captured by , Nirmal
                                                            Chakraborty, AQM
                                                            Badruddoza Chowdhury, the 13th President of the People’s Republic of Bangladesh and Samir Sen
                                                            respectively. The year
                                                            1959, 60, 61 were remarkable years in the history of the school. Syed Tareque Ali, Ahmed Shafee and
                                                            Mahboob Hossain Khan
                                                            topped the list again. In 1963 M. Sajjad Alam stood 2nd in the merit list from Science Group. After four
                                                            years again St.
                                                            Gregory’s Boys did marvelous result in the board examination. Zakir Ahmed stood first from Science Group
                                                            while Khan Md.
                                                            Farabi occupied the 2nd position in Humanities Group. After liberation in the year 1973 there was also a
                                                            remarkable
                                                            result in the board examination. Three top most positions were achieved by Khandaker Nazrul Kader, Saad
                                                            Akhter khan and
                                                            Md. Habibur Rahman respectively. The tradition of doing excellent result is still going in and it is now
                                                            valued with
                                                            different criteria like GPA 5. In the present grading system, the students are proving their excellence
                                                            also in the same
                                                            manner. Now in 2015 during the Headmastership of Brother Prodip Placid Gomes CSC, 211 students were sent
                                                            for the SSC
                                                            examination.</p>
                                                    </div>
                                                </div>
                                                <br>
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <img class="d-flex img-thumbnail float-end mb-3" style="height:300px; width:300px; margin:0px 0px 10px 15px;"
                                                            src="assets/img/school/history/3.jpg" alt="Image 3" />
                                                        <p>Excellent results with hundred percent pass is the real outcome of the school. The laurel of success was
                                                            not only
                                                            evident in the field of academic record but also in co-curricular activities St. Gregory’s boys set Hall
                                                            mark in the
                                                            history through their achievements. The co- curricular activities started basically just from the outset
                                                            of the 20th
                                                            century. The Scout Troop first in Dhaka was started at St. Gregory in 1914 just one year prior to the
                                                            1st World War and
                                                            within a month 40 Scouts became member of that troop. Br. Fabian , Mr. Alfred Palmer, Mr. Nicholas
                                                            Rozario, brought fame
                                                            and glory to our scout troop. By 1916 the Cadet Corps became strong and started flourishing and 33
                                                            cadets were rated as
                                                            extra efficient by the Army Officers. During the world War, many of the cadets were given Officers’
                                                            commission. This
                                                            year two scouts, Imtiaz Ahmed Sakib and Taufiqul Alam of St. Gregory’s High School have been nominated
                                                            to receive
                                                            President’s Scout Award for their excellent performance. Once in the 30th‘Bratachari Andalon’ a social
                                                            movement
                                                            influenced the whole country. Reverend Brother Walter realized the importance of the movement and
                                                            introduced it at
                                                            school.</p>
                                                            
                                                    </div>
                                                </div>
                                                <br>
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <img class="d-flex img-thumbnail float-start mb-3" style="height:300px; width:300px; margin:0px 15px 10px 0px;"
                                                            src="assets/img/school/history/5.jpg" alt="Image 4" />
                                                        <p>The Bratachari drill classes, St John’s Ambulance Brigade performances were held after school regularly.
                                                            At present the
                                                            co-curricular activities are going on in full swing with different missions to accommodate with the
                                                            perspectives of the
                                                            changed reality. The arrangements for co-curricular activities commence from the morning session and the
                                                            students have
                                                            to undergo with several co-curricular activities as attractive pursuits to bring forth their latent
                                                            genius to make
                                                            himself useful to him, the society and the country as well. The first club in the history of St. Gregory
                                                            came into being
                                                            is Gregorian Science Club. Gregorian Science Club is attributed with the prestigious effort to arrange
                                                            science fair
                                                            first at any educational institution. Still the effort to arrange science fair has been flourished and
                                                            the school is now
                                                            arranging Science& Art Festival annually where the participants around the country can take part to
                                                            exhibit their
                                                            talents. Gregorian Debating Club was not so old to come into being but the journey of it is now being
                                                            accelerated
                                                            through the arrangement of different debate competitions time to time and is going on in a formulated
                                                            manner. The young
                                                            debaters have flourished the name of the school especially in the arena of debate successfully. Many
                                                            renowned persons of
                                                            different walks of life who have occupied distinguished positions are the products of St. Gregory’s,
                                                            many of them were
                                                            good debaters at their school days. Gregorian Quiz and math Club is functioning recently but the genesis
                                                            of it came
                                                            through the sincere efforts of some of the teachers and students. The activities on Quiz are going on
                                                            fruitfully. The
                                                            students related to quiz are proving their mastery through their participation and achievements in
                                                            different
                                                            competitions. Gregorian English Club started functioning many years ago and the activities are still
                                                            going on. The
                                                            students are amply benefited by this club as well.</p>
                                                    </div>
                                                </div>
                                                <br>
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <img class="d-flex img-thumbnail float-end mb-3" style="height:300px; width:300px; margin:0px 0px 10px 15px;"
                                                            src="assets/img/school/history/1.jpg" alt="Image 1" />
                                                        <p>Gregorian Cultural club is one of the active clubs run by the teachers and students of the school. A
                                                            charitable cultural organization named ‘Fulki’ is also playing their part to nourish the master artists
                                                            to
                                                            flourish them to the limit. The activities of Gregorian Chess Club are also going on for many years. The
                                                            activities of this club are going on in regular basis to make the students involved with the game and to
                                                            perform better. Many national and internationally recognized chess players are the products of Gregorian
                                                            Chess Club. Gregorian Ecology Club started its activities in the recent past but the interest of the
                                                            students on the contemporary issue is growing rapidly. The students of this club usually took part in
                                                            different seminars and competitions nationally and internationally. There are also other clubs that are
                                                            being run in the same manner to meet the demand of the students. The students feel free to accommodate
                                                            with
                                                            these clubs to pursue their interests and impulses through different pursuits. St. Gregory’s High School
                                                            is
                                                            fortunate to get a good number of Holy Cross Fathers Brothers of noble soul and wonderful teachers
                                                            through
                                                            whose devotional service St. Gregory’s earned unviable reputation and present status. Only academic
                                                            excellence is not barely enough for the school. The remarkable achievements in different co-curricular
                                                            activities are the matters to be acknowledged around the country as well as the world. The students here
                                                            are
                                                            nurtured with holistic touch and with highly valued morality that have made them example for the fellow
                                                            students not only in the country but also in the world as well. They are given education in such a way
                                                            that
                                                            they will be able to compete anywhere in the world. Now one can easily perceive Gregorian team spirit
                                                            among
                                                            our teachers and students. All are proud to belong to this great institution. Thus students are proud to
                                                            be
                                                            a Gregorian. Father Boeres, Father Crowly (later he became the Bishop of Dhaka) Fr. Hennsey (Secretary
                                                            of
                                                            the school from 1911to 28), Brother Walter, a man of iron but beloved by all. Br. Jude, (a gem), Br.
                                                            James,
                                                            A friend, philosopher and a poet, Br. Martinian & Br. Fullgence, (still alive, living in the USA), Br.
                                                            Thomas More, (pioneer of basketball in Bangladesh and a true lover of games and sports) Br. Robert, Br.
                                                            John
                                                            (himself a Gregorian), Br. Gerald, Br. Marcel, Br. Bijoy, Fr. Peixotto, Br. Robi, Bro. Leo continued the
                                                            glorious tradition of the school and uphold the flag of St. Gregory’s a top.</p>
                                                           
                                                    </div>
                                                </div>
                                            </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngSwitchCase="'legend'">
                                    <section class="team-area card" style="padding: 10px;">
                                        <div class="container">
                                            <div class="row justify-content-center">
                                                <div class="col-lg-4 col-md-6 col-sm-6">
                                                    <div class="single-team-box">
                                                        <div class="image">
                                                            <img src="assets/img/team-image/team19.jpg" alt="team">
                                                            <div class="social">
                                                                <a href="#" target="_blank"><i
                                                                        class="fab fa-facebook-f"></i></a>
                                                                <a href="#" target="_blank"><i
                                                                        class="fab fa-twitter"></i></a>
                                                                <a href="#" target="_blank"><i
                                                                        class="fab fa-instagram"></i></a>
                                                            </div>
                                                        </div>
                                                        <div class="content">
                                                            <h3>John Smith</h3>
                                                            <span>Web Developer</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-4 col-md-6 col-sm-6">
                                                    <div class="single-team-box">
                                                        <div class="image">
                                                            <img src="assets/img/team-image/team19.jpg" alt="team">
                                                            <div class="social">
                                                                <a href="#" target="_blank"><i
                                                                        class="fab fa-facebook-f"></i></a>
                                                                <a href="#" target="_blank"><i
                                                                        class="fab fa-twitter"></i></a>
                                                                <a href="#" target="_blank"><i
                                                                        class="fab fa-instagram"></i></a>
                                                            </div>
                                                        </div>
                                                        <div class="content">
                                                            <h3>Lucy Eva</h3>
                                                            <span>Manager</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-4 col-md-6 col-sm-6">
                                                    <div class="single-team-box">
                                                        <div class="image">
                                                            <img src="assets/img/team-image/team19.jpg" alt="team">
                                                            <div class="social">
                                                                <a href="#" target="_blank"><i
                                                                        class="fab fa-facebook-f"></i></a>
                                                                <a href="#" target="_blank"><i
                                                                        class="fab fa-twitter"></i></a>
                                                                <a href="#" target="_blank"><i
                                                                        class="fab fa-instagram"></i></a>
                                                            </div>
                                                        </div>
                                                        <div class="content">
                                                            <h3>Steven Smith</h3>
                                                            <span>Web Developer</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-4 col-md-6 col-sm-6">
                                                    <div class="single-team-box">
                                                        <div class="image">
                                                            <img src="assets/img/team-image/team19.jpg" alt="team">
                                                            <div class="social">
                                                                <a href="#" target="_blank"><i
                                                                        class="fab fa-facebook-f"></i></a>
                                                                <a href="#" target="_blank"><i
                                                                        class="fab fa-twitter"></i></a>
                                                                <a href="#" target="_blank"><i
                                                                        class="fab fa-instagram"></i></a>
                                                            </div>
                                                        </div>
                                                        <div class="content">
                                                            <h3>Sarah Lucy</h3>
                                                            <span>Web Developer</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-4 col-md-6 col-sm-6">
                                                    <div class="single-team-box">
                                                        <div class="image">
                                                            <img src="assets/img/team-image/team19.jpg" alt="team">
                                                            <div class="social">
                                                                <a href="#" target="_blank"><i
                                                                        class="fab fa-facebook-f"></i></a>
                                                                <a href="#" target="_blank"><i
                                                                        class="fab fa-twitter"></i></a>
                                                                <a href="#" target="_blank"><i
                                                                        class="fab fa-instagram"></i></a>
                                                            </div>
                                                        </div>
                                                        <div class="content">
                                                            <h3>David Warner</h3>
                                                            <span>Manager</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-4 col-md-6 col-sm-6">
                                                    <div class="single-team-box">
                                                        <div class="image">
                                                            <img src="assets/img/team-image/team19.jpg" alt="team">
                                                            <div class="social">
                                                                <a href="#" target="_blank"><i
                                                                        class="fab fa-facebook-f"></i></a>
                                                                <a href="#" target="_blank"><i
                                                                        class="fab fa-twitter"></i></a>
                                                                <a href="#" target="_blank"><i
                                                                        class="fab fa-instagram"></i></a>
                                                            </div>
                                                        </div>
                                                        <div class="content">
                                                            <h3>Meg Lanning</h3>
                                                            <span>Web Developer</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-4 col-md-6 col-sm-6">
                                                    <div class="single-team-box">
                                                        <div class="image">
                                                            <img src="assets/img/team-image/team19.jpg" alt="team">
                                                            <div class="social">
                                                                <a href="#" target="_blank"><i
                                                                        class="fab fa-facebook-f"></i></a>
                                                                <a href="#" target="_blank"><i
                                                                        class="fab fa-twitter"></i></a>
                                                                <a href="#" target="_blank"><i
                                                                        class="fab fa-instagram"></i></a>
                                                            </div>
                                                        </div>
                                                        <div class="content">
                                                            <h3>Jason Roy</h3>
                                                            <span>Web Developer</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-4 col-md-6 col-sm-6">
                                                    <div class="single-team-box">
                                                        <div class="image">
                                                            <img src="assets/img/team-image/team19.jpg" alt="team">
                                                            <div class="social">
                                                                <a href="#" target="_blank"><i
                                                                        class="fab fa-facebook-f"></i></a>
                                                                <a href="#" target="_blank"><i
                                                                        class="fab fa-twitter"></i></a>
                                                                <a href="#" target="_blank"><i
                                                                        class="fab fa-instagram"></i></a>
                                                            </div>
                                                        </div>
                                                        <div class="content">
                                                            <h3>Joe Marta</h3>
                                                            <span>Manager</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-4 col-md-6 col-sm-6">
                                                    <div class="single-team-box">
                                                        <div class="image">
                                                            <img src="assets/img/team-image/team19.jpg" alt="team">
                                                            <div class="social">
                                                                <a href="#" target="_blank"><i
                                                                        class="fab fa-facebook-f"></i></a>
                                                                <a href="#" target="_blank"><i
                                                                        class="fab fa-twitter"></i></a>
                                                                <a href="#" target="_blank"><i
                                                                        class="fab fa-instagram"></i></a>
                                                            </div>
                                                        </div>
                                                        <div class="content">
                                                            <h3>Joe Root</h3>
                                                            <span>Web Developer</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </ng-container>
                                <ng-container *ngSwitchCase="'saint'">
                                        <section class="about-area card" style="padding: 10px;">
                                            <div class="row">
                                                <div class="about-content">
                                                    <div class="col-lg-12">
                                                        <img class="img-fluid" style="height: 300px; width: 100%; object-fit: fill; border-radius: 10px;"
                                                            src="assets/img/school/saint_gregory.jpg">
                                                    </div>
                                                    <br>
                                                    <p>
                                                        Saint Gregory's High School was founded in 1882 to honor Pope Saint Gregory I, also known as St. Gregory the Great. He
                                                        was born in Rome in 540 to a wealthy and prosperous family. His father was a senator and a Prefect of Rome, and his
                                                        mother was from a noble family. Gregory was well educated and excelled in all his studies. He became an expert in law
                                                        and served as the Prefect of Rome himself. <br>
                                                        In 579, Pope Pelagius II appointed Gregory as an ambassador to the imperial court in Constantinople. After Pelagius's
                                                        death in 590, Gregory was elected pope. He was reluctant to accept the position, but he eventually agreed, believing it
                                                        to be God's will. <br>
                                                        As pope, Gregory was known for his emphasis on missionary work. He sent many people out to bring the people in the path
                                                        of honesty and truthfulness. He also made many changes to the Mass, some of which remain today. He emphasized the aspect
                                                        of service to the poor for deacons. He also established "cantus planus," known in English as plainchant. Most today know
                                                        this style of singing as Gregorian Chant. <br>
                                                        Gregory was also known for his alms to the poor. He gave quite generously of the riches donated to the Church by the
                                                        wealthy people of Rome. Everything from money to land was given to the poor. He made clear to his subordinates that
                                                        their duty was to relieve the distress faced by the poor. He ordered his clergy to go out into the streets to find and
                                                        care for the poor. When a famine struck Rome in the 590s, Gregory ordered the Church to use its assets to feed the poor.
                                                        Thus, he saved thousands of people from certain death. <br>
                                                        Gregory was a prolific writer and is considered one of the most important figures in the development of Western
                                                        Christianity. His writings include the "Dialogues," "Moralia on Job," and "Pastoral Rule." He was also a skilled
                                                        diplomat and administrator. He helped to improve relations between the papacy and the Byzantine Empire, and he played a
                                                        key role in the conversion of the Anglo-Saxons to Christianity. <br>
                                                        Gregory died on March 12, 604. He was canonized as a saint in 605. His feast day is celebrated on September 3. <br>
                                                        Saint Gregory's High School hopes that its students will practice the qualities of their patron saint in their daily
                                                        lives. These qualities include compassion, generosity, and a commitment to service. The school believes that these
                                                        qualities are essential for creating a more just and equitable world. <br>
                                                    </p>
                                                </div>
                                            </div>
                                        </section>
                                </ng-container>
                                <ng-container *ngSwitchCase="'whyStudyHere'">
                                    <section class="about-area card" style="padding: 10px;">
                                        <div class="row">
                                            <div class="about-content">
                                                <div class="col-lg-12">
                                                    <img class="img-fluid" style="height: 300px; width: 100%; object-fit: cover; border-radius: 10px;"
                                                        src="assets/img/school/whyStudyHere.jpg">
                                                </div>
                                                <br>
                                                <p>
                                                    St. Gregory's High School is a well-equipped institution that provides students with all the opportunities they need to
                                                    flourish their latent talents. Our school has a well-disciplined and pleasant atmosphere, highly qualified and devoted
                                                    teachers who are very encouraging and supportive, an enriched library, modern science laboratories, a large playground,
                                                    a big basketball court, and facilities for playing other indoor games and sports. <br>
                                                    We believe that every student deserves to get an education in a supportive environment where they can reach their full
                                                    potential. At St. Gregory's, we are committed to providing our students with the resources and opportunities they need
                                                    to succeed. <br>
                                                    If you are looking for a school that will challenge you academically, nurture your talents, and help you become a
                                                    well-rounded individual, then St. Gregory's High School is the perfect place for you. <br>
                                                    Here are some of the reasons why you should study at St. Gregory's High School: <br>
                                                    </p>
                                                    <ul>
                                                        <li><span style="font-weight: bolder;">Academic excellence:</span> We have a strong academic tradition and our students consistently achieve high marks in national
                                                        exams.</li>
                                                        <li><span style="font-weight: bolder;">Co-curricular activities:</span> We offer a wide range of co-curricular activities, including sports, music, drama, and
                                                        debate.</li>
                                                        <li><span style="font-weight: bolder;">Supportive environment:</span> Our teachers are dedicated to helping our students succeed and they provide individual
                                                        attention to every student.</li>
                                                        <li><span style="font-weight: bolder;">Dedicated faculty:</span> Our faculty are highly qualified and experienced teachers who are passionate about teaching.</li>
                                                        <li><span style="font-weight: bolder;">Modern facilities:</span> We have modern facilities, including a well-equipped library, science laboratories, and sports
                                                        facilities.</li>
                                                    </ul>
                                                    <p>
                                                        If you are looking for a school that will help you reach your full potential, then St. Gregory's High School is the
                                                        perfect place for you.
                                                    </p>

                                             
                                            </div>
                                        </div>
                                    </section>
                                </ng-container>
                                <ng-container *ngSwitchCase="'missionAndVision'">
                                    <section class="about-area card" style="padding: 10px;">
                                        <div class="row">
                                            <div class="about-content">
                                                <div class="col-lg-12">
                                                    <img class="img-fluid" style="height: 300px; width: 100%; object-fit: cover; border-radius: 10px;"
                                                        src="assets/img/school/missionAndVision.jpg">
                                                </div>
                                                <br>
                                                <p>
                                                    St. Gregory's High School & College is a private Catholic school in Dhaka, Bangladesh. It was founded in 1882 by Rev.
                                                    Father Gregory De Groote, a Belgian Benedictine priest. The school has a long and distinguished history, with many
                                                    notable alumni, including the first Prime Minister of Bangladesh, Tajuddin Ahmed, and the 13th President of Bangladesh,
                                                    AQM Badruddoza Chowdhury. <br>
                                                    The school's vision is to "provide a holistic education that prepares students to be responsible citizens, leaders, and
                                                    global citizens." The school's mission is to "foster academic excellence, moral and spiritual development, and physical
                                                    well-being in a caring and supportive environment." <br>
                                                    To achieve its vision and mission, St. Gregory's High School & College offers a rigorous academic curriculum, a variety
                                                    of co-curricular activities, and a strong emphasis on character development. The school also has a strong alumni network
                                                    that provides support to students and the school community. <br>
                                                    <span style="font-weight: bolder;">Vision</span><br>
                                                    St. Gregory's High School & College envisions a world where all people are treated with dignity and respect, regardless
                                                    of their race, religion, or background. The school believes that education is the key to creating a more just and
                                                    equitable world, and it is committed to providing its students with the knowledge and skills they need to be successful
                                                    in college, career, and life. <br>
                                                    <span style="font-weight: bolder;">Mission</span><br>
                                                    The mission of St. Gregory's High School & College is to provide a holistic education that prepares students to be
                                                    responsible citizens, leaders, and global citizens. The school believes that all students have the potential to succeed,
                                                    and it is committed to providing its students with the resources they need to reach their full potential. <br>
                                                    <span style="font-weight: bolder;">Academic Excellence</span><br>
                                                    St. Gregory's High School & College offers a rigorous academic curriculum that challenges students to think critically
                                                    and creatively. The school's teachers are highly qualified and dedicated to helping students succeed. The school also
                                                    offers a variety of extracurricular activities that allow students to explore their interests and talents. <br>
                                                    <span style="font-weight: bolder;">Moral and Spiritual Development</span><br>
                                                    St. Gregory's High School & College believes that moral and spiritual development is essential for a well-rounded
                                                    education. The school offers a variety of opportunities for students to learn about different religions and cultures.
                                                    The school also emphasizes the importance of service to others, and it encourages students to get involved in community
                                                    service projects. <br>
                                                    <span style="font-weight: bolder;">Physical Well-Being</span><br>
                                                    St. Gregory's High School & College believes that physical well-being is essential for a healthy and happy life. The
                                                    school offers a variety of sports and physical education classes. <br>
                                                    
                                                    <span style="font-weight: bolder;">Character Development</span><br>
                                                    St. Gregory's High School & College believes that character development is essential for success in college, career, and
                                                    life. The school emphasizes the importance of honesty, integrity, respect, and responsibility. The school also offers a
                                                    variety of programs that help students develop their character. <br>
                                                    
                                                    St. Gregory's High School & College is a premier educational institution that is committed to providing its students
                                                    with the knowledge, skills, and character they need to succeed in college, career, and life. The school's vision and
                                                    mission are clear, and the school is well-positioned to achieve its goals.
                                                </p>
                                            </div>
                                        </div>
                                    </section>
                                </ng-container>
                                <ng-container *ngSwitchDefault>
                                    <!-- Default template for other keys -->
                                    <p class="card" style="padding: 30px; text-align: justify;">
                                        {{ getContent(item)?.description }}
                                    </p>
                                    <img *ngIf="getContent(item)?.imageUrl !== ''" [src]="getContent(item)?.imageUrl"
                                        alt="Club Image">
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>