<app-navbar-style-three></app-navbar-style-three>

<div class="page-title-area bg-image" style="height: 250px;">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Admission</h2>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="subscribe-wrap-area">
    <div class="container">
        <div class="subscribe-wrap-inner-box">
            <p>For smooth distribution no guardian will be allowed to collect more than one application form.
                Parents are requested to submit the application form along with two 1.5 x 1.5 size coloured pictures and
                the
                photocopy
                of birth certificate of the candidate according to the date and time given on the application form.
                Parents must
                bring
                their ward and the original birth certificate of the candidate while submitting the application form.
            </p>
        </div>
    </div>
</div>