import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { EmitterService } from 'src/app/EmitterService';

@Component({
  selector: 'app-notice',
  templateUrl: './notice.component.html',
  styleUrls: ['./notice.component.scss'],
})
export class NoticeComponent implements OnInit
{
  Notices = [];
  subscription: Subscription;
  src = 'https://sghsc-edu-bd.s3.ap-southeast-1.amazonaws.com/dws/2023/notice/Notice_sghsc%20t01_23-08-2023-04-07-33_s.pdf';

  urlSafe: SafeResourceUrl;


  dynamicTape = [
    {
      Id: 1,
      Text: 'Holiday for Hijri New Year, Summer Vacation and Ashura',
      Link: '1.jpg',
      Date: new Date('2023-05-22'),
    },
    {
      Id: 2,
      Text: 'Second Terminal Examination - 2023',
      Link: '2.jpg',
      Date: new Date('2023-06-15'),
    },
    {
      Id: 3,
      Text: 'Routine for Pre-test Examination - 2023',
      Link: '3.jpg',
      Date: new Date('2023-06-15'),
    },
    {
      Id: 4,
      Text: 'Practical Board Exam Routine - 2023',
      Link: '4.jpg',
      Date: new Date('2023-06-15'),
    },
    {
      Id: 5,
      Text: 'Faculty Meeting',
      Link: '5.jpg',
      Date: new Date('2023-06-15'),
    },
    {
      Id: 6,
      Text: 'এইচএসসি ২০২৩ পরীক্ষার্থীদের জন্য বিশেষ বিজ্ঞপ্তি',
      Link: '6.jpg',
      Date: new Date('2023-06-15'),
    },
    {
      Id: 7,
      Text: '৯ম শ্রেণীতে রেজিস্ট্রেশন বিষয়ক বিশেষ বিজ্ঞপ্তি ',
      Link: '7.jpg',
      Date: new Date('2023-06-15'),
    },
    {
      Id: 8,
      Text: 'Thanksgiving Program',
      Link: '8.jpg',
      Date: new Date('2023-06-15'),
    },
    {
      Id: 9,
      Text: 'লেখা আহ্বান ',
      Link: '9.jpg',
      Date: new Date('2023-06-15'),
    },
    {
      Id: 10,
      Text: 'এইচএসসি ২০২৩ পরীক্ষার্থীদের জন্য বিশেষ বিজ্ঞপ্তি',
      Link: '10.jpg',
      Date: new Date('2023-06-15'),
    },
    {
      Id: 11,
      Text: 'Faculty Meeting',
      Link: '11.jpg',
      Date: new Date('2023-06-15'),
    },
    {
      Id: 12,
      Text: '1st Round Assessment of Year Final Examination 2023',
      Link: '12.jpg',
      Date: new Date('2023-07-23'),
    },
    {
      Id: 13,
      Text: '1st Round Assessment of Year Final Examination 2023, Classes: V, VIII & IX',
      Link: '13.jpeg',
      Date: new Date('2023-07-26'),
    },
    {
      Id: 14,
      Text: 'Make-Up Test - 2023',
      Link: '14.jpeg',
      Date: new Date('2023-07-26'),
    },
    {
      Id: 15,
      Text: 'Promotion Test for Class XI - 2023',
      Link: '15.jpeg',
      Date: new Date('2023-07-26'),
    },
    {
      Id: 16,
      Text: "HSC Candidate (Batch'2023) & All Teachers",
      Link: '16.jpeg',
      Date: new Date('2023-08-01'),
    },
    {
      Id: 17,
      Text: 'Students, Teachers and Parents of the Morning Shift',
      Link: '17.jpeg',
      Date: new Date('2023-08-01'),
    },
    {
      Id: 18,
      Text: 'Result of SSC Examination - 2023',
      Link: '18.jpeg',
      Date: new Date('2023-07-27'),
    },
    {
      Id: 19,
      Text: 'Handing out assessment report and parent-teacher meet of class V, VIII & IX',
      Link: '19.jpeg',
      Date: new Date('2023-08-02'),
    },
    {
      Id: 20,
      Text: 'New Class Schedule for Class XI (College Section)',
      Link: '20.jpeg',
      Date: new Date('2023-08-03'),
    },
    {
      Id: 21,
      Text: '২০২৩-২০২৪ শিক্ষাবর্ষে একাদশ শ্রেণীতে ভর্তি বিজ্ঞপ্তি',
      Link: '21.jpeg',
      Date: new Date('2023-08-08'),
    },
    {
      Id: 22,
      Text: 'একাদশ শ্রেণির ভর্তি প্রক্রিয়া ও নির্দেশিকা',
      Link: '22.jpg',
      Date: new Date('2023-08-08'),
    },
    {
      Id: 23,
      Text: '২০২৩ - ২০২৪ শিক্ষাবর্ষে একাদশ শ্রেণিতে ভর্তি (শুধুমাত্র গ্রেগরীয়ানদের জন্য)',
      Link: '23.jpeg',
      Date: new Date('2023-08-09'),
    },
    {
      Id: 24,
      Text: '২০২৩-২০২৪ শিক্ষাবর্ষে একাদশ শ্রেণীতে ভর্তি',
      Link: '24.jpeg',
      Date: new Date('2023-08-09'),
    },
    {
      Id: 25,
      Text: 'মানসিক দৃঢ়তা ও ধৈর্য প্রয়োজন',
      Link: '25.jpeg',
      Date: new Date('2023-08-09'),
    },
    {
      Id: 26,
      Text: 'এইচএসসি-২০২৩ শিক্ষাবর্ষের পরীক্ষার্থীদের প্রবেশ পত্র বিষয়ক বিশেষ বিজ্ঞপ্তি',
      Link: '26.jpeg',
      Date: new Date('2023-08-10'),
    },
  ];

  selectedNotice: any;

  constructor(private emitterService: EmitterService,
    public sanitizer: DomSanitizer)
  {
    this.dynamicTape.sort((a, b) => b.Date.getTime() - a.Date.getTime());
    this.dynamicTape.sort((a, b) =>
    {
      if (a.Date.getTime() === b.Date.getTime())
      {
        return b.Id - a.Id;
      }
    });


     this.subscription = this.emitterService.currentMessage.subscribe(
       (message) => {
         if (message === true) {
           let noticeData = localStorage.getItem('noticeData');
           let parsedData: any = JSON.parse(noticeData);

           this.Notices = parsedData.filter((x) => x.ShowScroll === true && x.Id !== 1045);

           this.Notices.sort((a, b) => b.Id - a.Id);

          //  console.log(this.Notices, 'this.Notices');
         }
       }
     );


    // this.subscription = this.emitterService.currentMessage.subscribe(message =>
    // {
    //   if (message == true)
    //   {
    //     let noticeData = localStorage.getItem("noticeData");

    //     let parsedData: any = JSON.parse(noticeData);

    //     this.Notices = parsedData.filter((x) =>
    //       x.OutOfNotice == true
    //     );

    //     this.Notices.sort((a, b) => b.CreatedDate - a.CreatedDate);
    //     this.Notices.sort((a, b) =>
    //     {
    //       if (a.CreatedDate === b.CreatedDate)
    //       {
    //         return b.Id - a.Id;
    //       }
    //     });
    //     // console.log(this.Notices, 'this.Notices');
    //   }
    // })
  }

  ngOnInit(): void { }

  showNotice(event: Event, notice: any)
  {
    event.preventDefault();
    this.selectedNotice = notice;

    let val = "";
    if (this.selectedNotice.FileLink.includes('https://'))
    {
      val = this.selectedNotice.FileLink;
    }
    else
    {
      val = `https://${this.selectedNotice.FileLink}`
    }

    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(val);

  }

  isImage(link: string): boolean
  {
    return /\.(jpe?g|png|gif)$/i.test(link);
  }

  TransformUrl(value)
  {
    let val = "";
    if (value.includes('https://'))
    {
      val = value;
    }
    else
    {
      val = `https://${value}`
    }

    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(val);

    return this.urlSafe;

  }
}
