<app-navbar-style-three></app-navbar-style-three>

<div class="bg-image"
  style="height: 100px">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="page-title-content">
          <h2>Notice</h2>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container">
  <div class="row">
    <div class="col-md-4">
      <article class="item">
        <div class="ticker-wrapper-v-image">
          <ul class="news-ticker-v-image">
            <li *ngFor="let notice of Notices"
              (click)="showNotice($event, notice)"
              style="border: 1px solid #eeeeee; padding: 10px">
              <div class="thumbnail">
                <div style="border: 1px solid #eeeeee; width: 80px">
                  <div style="background-color: #082c5c"
                    class="text-center">
                    <span style="text-align: center; color: #ffffff">{{notice.CreatedDate | date:'dd'}}</span>
                  </div>
                  <div style="background-color: #f2f2f2"
                    class="text-center">
                    <span style="text-align: center; color: #caa545">{{notice.CreatedDate | date:'MMM'}}</span>
                  </div>
                </div>
              </div>
              <div>
                <div style="
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  ">
                  {{ notice.EnglishTitle }}
                </div>
              </div>
              <div class="clear"></div>
            </li>
          </ul>
        </div>
      </article>
    </div>

    <div class="col-md-8">

      <div *ngIf="selectedNotice; else noNotice">
        <div class="notice-title">{{ selectedNotice.EnglishTitle }}</div>
        <div class="notice-details">
          <div class="notice-content">
            <!-- Display the notice image or pdf here -->
            <img *ngIf="isImage(selectedNotice.FileLink)"
              [src]="'assets/img/school/notice/' + selectedNotice.FileLink"
              alt="Notice" />
            <div *ngIf="!isImage(selectedNotice.FileLink)">
              <!-- PDF Content (Replace this with appropriate PDF viewer) -->

              <iframe class="viewport"
                width="100%"
                frameBorder="0"
                [src]="urlSafe"></iframe>
            </div>
          </div>
        </div>
      </div>
      <ng-template #noNotice>
        <div class="notice-title">Please select a notice to view</div>
      </ng-template>

    </div>
  </div>
</div>
