import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiService
{
  // apiUrl = 'https://aiapisandbox.cloudcampus24.com/api/';
  // apiUrlV1 = 'https://aiapisandbox.cloudcampus24.com/api/v1/';
  
  apiUrl = 'https://portal2websiteapi.cloudcampus24.com/api/';
  apiUrlV1 = 'https://portal2websiteapi.cloudcampus24.com/api/v1/';

  private readonly corsProxyUrl = 'https://cors-anywhere.herokuapp.com/';

  constructor(private http: HttpClient) { }

  getToken(post: any): Observable<any>
  {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    let options = { headers: headers };
    return this.http.post<any>(`${this.apiUrl}UserAuth/login`, post, options);
  }

  getNotices(clientData): Observable<any[]>
  {
    let parsedData = JSON.parse(clientData);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${parsedData.AccessToken}`,
    });
    let options = { headers: headers };
    return this.http.get<any[]>(`${this.apiUrlV1}Notice/notices`, options);
  }

  getNotices_2(key, cli): Observable<any[]>
  {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-App-Key': key,
      'X-App-Client': cli,
    });
    let options = { headers: headers };
    return this.http.get<any[]>(`${this.apiUrlV1}NoticeClient/notices`, options);
  }

  getAlbums(clientData): Observable<any[]>
  {
    let parsedData = JSON.parse(clientData);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${parsedData.AccessToken}`,
    });
    let options = { headers: headers };
    return this.http.get<any[]>(`${this.apiUrlV1}Album/image-albums`, options);
  }

  getAlbumById(clientData, albumId): Observable<any[]>
  {
    let parsedData = JSON.parse(clientData);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${parsedData.AccessToken}`,
    });
    let options = { headers: headers };
    return this.http.get<any[]>(
      `${this.apiUrlV1}Album/images/${albumId}`,
      options
    );
  }

  getPostById(id: number): Observable<any>
  {
    return this.http.get<any>(`${this.apiUrl}/posts/${id}`);
  }

  addPost(post: any): Observable<any>
  {
    return this.http.post<any>(`${this.apiUrl}/posts`, post);
  }

  updatePost(id: number, post: any): Observable<any>
  {
    return this.http.put<any>(`${this.apiUrl}/posts/${id}`, post);
  }

  deletePost(id: number): Observable<any>
  {
    return this.http.delete<any>(`${this.apiUrl}/posts/${id}`);
  }

  fetchData()
  {
    const url = 'https://cloudcampus24-cdn.s3.ap-southeast-1.amazonaws.com/cdn/GridAllowedClients.json';
    return this.http.get(url);
  }

  getMenuData(): Promise<any> {
    return this.http.get<any>('https://sghscdhaka.edu.bd/temp.json').toPromise()
   }

  isTokenExpired(clientData: any): boolean
  {
    let parsedData = JSON.parse(clientData);

    if (parsedData == null)
    {
      return true;
    }

    // Return if there is no token
    if (parsedData.AccessToken === '')
    {
      return true;
    }

    // Get the expiration date
    const expirationDate = new Date(parsedData.Expiration);

    if (expirationDate === null)
    {
      return true;
    }

    // Check if the token is expired
    const diff = new Date().getTime() - expirationDate.getTime();
    return diff > 0;
  }

  TransformFolder_Data(data)
  {
    let object = data.map((element: any) =>
    {
      let obj: any = {};
      // obj.term = element[0].TermName;

      obj.Id = element.Id;
      obj.ParentId = element.ParentId != null ? element.ParentId : null;
      obj.Name = element.Name != null ? element.Name : element.AlbumName;
      obj.BanglaName = element.BanglaName != null ? element.BanglaName : null;
      obj.ThumbnailPath =
        element.ThumbnailPath != null ? element.ThumbnailPath : null;

      obj.ThumbnailLink =
        element.ThumbnailLink != null
          ? this.TransformUrl(element.ThumbnailLink)
          : null;

      obj.AlbumType = element.AlbumType != null ? element.AlbumType : null;
      obj.AlbumOrder = element.AlbumOrder != null ? element.AlbumOrder : null;
      obj.TotalHitCount =
        element.TotalHitCount != null ? element.TotalHitCount : null;
      obj.IsActive = element.IsActive != null ? element.IsActive : null;
      obj.TotalSubFolder =
        element.TotalSubFolder != null ? element.TotalSubFolder : null;
      obj.TotalImage = element.TotalImage != null ? element.TotalImage : null;
      obj.LastUpdated =
        element.LastUpdated != null ? element.LastUpdated : null;

      obj.AlbumId = element.AlbumId != null ? element.AlbumId : null;

      obj.ImageOrder = element.ImageOrder != null ? element.ImageOrder : null;
      obj.ImagePath = element.ImagePath != null ? element.ImagePath : null;
      obj.ImageLink =
        element.ImageLink != null ? this.TransformUrl(element.ImageLink) : null;
      obj.IsAlbumFolder =
        element.IsAlbumFolder != null ? element.IsAlbumFolder : true;

      return obj;
    });

    return object;
  }

  TransformUrl(value)
  {
    if (value.includes('https://'))
    {
      return value;
    } else
    {
      return `https://${value}`;
    }
  }
}
