<div class="object">
    <div class="object-rope"></div>
    <div class="object-shape">
        Coming <span class="soon">Soon</span>
    </div>
</div>

<div class="content">
    <div class="error-content">
        <img src="assets/img/school/underc.jpg" alt="error">
        <h3>This Page Is Under Construction</h3>
        <p>The page you are looking for is not ready to view. Please come back later.</p>
        <a href="/" class="default-btn">Go to Home <span></span></a>
    </div>
</div>