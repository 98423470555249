<div class="toolbar d-flex flex-wrap justify-content-center justify-content-md-between">
  <ng-container *ngIf="isMiddle">
    <div class="col-lg-4 col-md-6 text-center text-md-left mb-3 mb-md-0">
      <a routerLink="/admission-details"
        class="default-btn-scroll"
        class="button default-btn-scroll">Click here for admission</a>
    </div>
  </ng-container>

  <ng-container *ngIf="isLeft">
    <div class="col-lg-3 col-md-6 col-12">
      <a routerLink="/admission-details"
        class="default-btn-scroll"
        class="button default-btn-scroll">Click here for admission</a>
    </div>
  </ng-container>

  <div class="col-lg-7 col-md-6 col-12 d-flex justify-content-center mb-3 mb-md-0">
    <div style="width: 80%">
      <marquee behavior="scroll"
        direction="left"
        onmouseover="this.stop();"
        onmouseleave="this.start();">
        <table class="border-0 align-items-center">
          <tbody class="align-items-center"
            *ngIf="Notices.length ==0">
            <tr>
              <td class="border-0">
                <a class="scroll-text">No Notices Found</a>
                &nbsp; &nbsp; &nbsp;
              </td>
            </tr>
          </tbody>
          <tbody class="align-items-center"
            *ngIf="Notices.length !=0">
            <tr>
              <td class="border-0"
                *ngFor="let data of Notices">
                <a (click)="onNavigate(data.FileLink)"
                  class="scroll-text">{{data.EnglishTitle}}</a>
                &nbsp; &nbsp; &nbsp;
              </td>
            </tr>
            <!-- <tr>
              <td class="border-0">
                <a
                  href="assets/img/school/notice/26.jpeg"
                  target="_blank"
                  class="scroll-text"
                  >এইচএসসি-২০২৩ শিক্ষাবর্ষের পরীক্ষার্থীদের প্রবেশ পত্র বিষয়ক
                  বিশেষ বিজ্ঞপ্তি</a
                >
                &nbsp; &nbsp; &nbsp;
              </td>
              <td class="border-0">
                <a
                  href="assets/img/school/notice/25.jpeg"
                  target="_blank"
                  class="scroll-text"
                  >মানসিক দৃঢ়তা ও ধৈর্য প্রয়োজন</a
                >
                &nbsp; &nbsp; &nbsp;
              </td>
              <td class="border-0">
                <a
                  href="assets/img/school/notice/24.jpeg"
                  target="_blank"
                  class="scroll-text"
                  >২০২৩-২০২৪ শিক্ষাবর্ষে একাদশ শ্রেণীতে ভর্তি</a
                >
                &nbsp; &nbsp; &nbsp;
              </td>
              <td class="border-0">
                <a
                  href="assets/img/school/notice/23.jpeg"
                  target="_blank"
                  class="scroll-text"
                  >২০২৩ - ২০২৪ শিক্ষাবর্ষে একাদশ শ্রেণিতে ভর্তি (শুধুমাত্র
                  গ্রেগরীয়ানদের জন্য)
                </a>
                &nbsp; &nbsp; &nbsp;
              </td>
              <td class="border-0">
                <a
                  href="assets/img/school/notice/22.jpg"
                  target="_blank"
                  class="scroll-text"
                  >একাদশ শ্রেণির ভর্তি প্রক্রিয়া ও নির্দেশিকা</a
                >
                &nbsp; &nbsp; &nbsp;
              </td>
              <td class="border-0">
                <a
                  href="assets/img/school/notice/21.jpeg"
                  target="_blank"
                  class="scroll-text"
                  >২০২৩-২০২৪ শিক্ষাবর্ষে একাদশ শ্রেণীতে ভর্তি বিজ্ঞপ্তি</a
                >
                &nbsp; &nbsp; &nbsp;
              </td>
              <td class="border-0">
                <a
                  href="assets/img/school/notice/20.jpeg"
                  target="_blank"
                  class="scroll-text"
                  >New Class Schedule for Class XI (College Section)</a
                >
                &nbsp; &nbsp; &nbsp;
              </td>
              <td class="border-0">
                <a
                  href="assets/img/school/notice/19.jpeg"
                  target="_blank"
                  class="scroll-text"
                  >Handing out assessment report and parent-teacher meet of
                  class V, VIII & IX</a
                >
                &nbsp; &nbsp; &nbsp;
              </td>
              <td class="border-0">
                <a
                  href="assets/img/school/notice/18.jpeg"
                  target="_blank"
                  class="scroll-text"
                  >Result of SSC Examination - 2023</a
                >
                &nbsp; &nbsp; &nbsp;
              </td>
              <td class="border-0">
                <a
                  href="assets/img/school/notice/17.jpeg"
                  target="_blank"
                  class="scroll-text"
                  >Students, Teachers and Parents of the Morning Shift</a
                >
                &nbsp; &nbsp; &nbsp;
              </td>
              <td class="border-0">
                <a
                  href="assets/img/school/notice/16.jpeg"
                  target="_blank"
                  class="scroll-text"
                  >HSC Candidate (Batch'2023) & All Teachers</a
                >
                &nbsp; &nbsp; &nbsp;
              </td>
              <td class="border-0">
                <a
                  href="assets/img/school/notice/13.jpeg"
                  target="_blank"
                  class="scroll-text"
                  >1st Round Assessment of Year Final Examination 2023, Classes:
                  V, VIII & IX</a
                >
                &nbsp; &nbsp; &nbsp;
              </td>
              <td class="border-0">
                <a
                  href="assets/img/school/notice/14.jpeg"
                  target="_blank"
                  class="scroll-text"
                  >Make-Up Test - 2023</a
                >
                &nbsp; &nbsp; &nbsp;
              </td>
              <td class="border-0">
                <a
                  href="assets/img/school/notice/15.jpeg"
                  target="_blank"
                  class="scroll-text"
                  >Promotion Test for Class XI - 2023</a
                >
                &nbsp; &nbsp; &nbsp;
              </td>
              <td class="border-0">
                <a
                  href="assets/img/school/notice/12.jpg"
                  target="_blank"
                  class="scroll-text"
                  >1st Round Assessment of Year Final Examination 2023</a
                >
                &nbsp; &nbsp; &nbsp;
              </td>
              <td class="border-0">
                <a
                  href="assets/img/school/notice/1.jpg"
                  target="_blank"
                  class="scroll-text"
                  >Holiday for Hijri New Year, Summer Vacation and Ashura</a
                >
                &nbsp; &nbsp; &nbsp;
              </td>
              <td class="border-0">
                <a
                  href="assets/img/school/notice/2.jpg"
                  target="_blank"
                  class="scroll-text"
                  >Second Terminal Examination - 2023</a
                >
                &nbsp; &nbsp; &nbsp;
              </td>
              <td class="border-0">
                <a
                  href="assets/img/school/notice/3.jpg"
                  target="_blank"
                  class="scroll-text"
                  >Routine for Pre-test Examination - 2023</a
                >
                &nbsp; &nbsp; &nbsp;
              </td>
              <td class="border-0">
                <a
                  href="assets/img/school/notice/4.jpg"
                  target="_blank"
                  class="scroll-text"
                  >Practical Board Exam Routine - 2023</a
                >
                &nbsp; &nbsp; &nbsp;
              </td>
              <td class="border-0">
                <a
                  href="assets/img/school/notice/5.jpg"
                  target="_blank"
                  class="scroll-text"
                  >Faculty Meeting</a
                >
                &nbsp; &nbsp; &nbsp;
              </td>
              <td class="border-0">
                <a
                  href="assets/img/school/notice/6.jpg"
                  target="_blank"
                  class="scroll-text"
                  >এইচএসসি ২০২৩ পরীক্ষার্থীদের জন্য বিশেষ বিজ্ঞপ্তি</a
                >
                &nbsp; &nbsp; &nbsp;
              </td>
              <td class="border-0">
                <a
                  href="assets/img/school/notice/7.jpg"
                  target="_blank"
                  class="scroll-text"
                  >৯ম শ্রেণীতে রেজিস্ট্রেশন বিষয়ক বিশেষ বিজ্ঞপ্তি</a
                >
                &nbsp; &nbsp; &nbsp;
              </td>
              <td class="border-0">
                <a
                  href="assets/img/school/notice/8.jpg"
                  target="_blank"
                  class="scroll-text"
                  >Thanksgiving Program</a
                >
                &nbsp; &nbsp; &nbsp;
              </td>
              <td class="border-0">
                <a
                  href="assets/img/school/notice/9.jpg"
                  target="_blank"
                  class="scroll-text"
                  >লেখা আহ্বান</a
                >
                &nbsp; &nbsp; &nbsp;
              </td>
              <td class="border-0">
                <a
                  href="assets/img/school/notice/10.jpg"
                  target="_blank"
                  class="scroll-text"
                  >এইচএসসি ২০২৩ পরীক্ষার্থীদের জন্য বিশেষ বিজ্ঞপ্তি</a
                >
                &nbsp; &nbsp; &nbsp;
              </td>
              <td class="border-0">
                <a
                  href="assets/img/school/notice/11.jpg"
                  target="_blank"
                  class="scroll-text"
                  >Faculty Meeting</a
                >
                &nbsp; &nbsp; &nbsp;
              </td>
            </tr> -->
          </tbody>
        </table>
      </marquee>
    </div>
  </div>

  <div class="col-lg-2 col-md-12 col-12 d-flex justify-content-center justify-content-md-end">
    <ul class="topbar-social-links-scroll">
      <li>
        <a href="#"
          target="_blank"><i class="bx bxl-facebook"></i></a>
      </li>
      <li>
        <a href="#"
          target="_blank"><i class="bx bxl-twitter"></i></a>
      </li>
      <li>
        <a href="#"
          target="_blank"><i class="bx bxl-linkedin"></i></a>
      </li>
      <li>
        <a href="#"
          target="_blank"><i class="bx bxl-instagram"></i></a>
      </li>
    </ul>
  </div>
</div>

<div class="topbar-wrap-area box">
  <div class="container-fluid">
    <div class="row align-items-center justify-content-center topbar-action-list">
      <div class="col-lg-8 col-md-4 sname">
        <a routerLink="/"
          routerLinkActive="active"
          class="nav-link text-start schoolName"
          style="color: #327ab5">St. Gregory's High School & College</a>
      </div>
      <div class="col-lg-4 col-md-12">
        <ul class="topbar-action-list">
          <li class="navIcon">
            <a href="#"><button class="btn old-btn">Old Website</button></a>
          </li>
          <li class="navIcon">
            <a href="#"><i class="fa fa-envelope"></i><span class="d-none d-md-inline d-xs-none"> Web Mail</span></a>
          </li>
          <li class="navIcon">
            <a href="/contact"><i class="fa fa-address-book"></i><span class="d-none d-md-inline d-xs-none"> Contact
                Us</span></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<div class="navbar-area navbar-area-with-position-relative">
  <div class="karlson-nav">
    <div class="container-fluid">
      <nav class="navbar navbar-expand-lg navbar-light">
        <div class="d-lg-none">
          <a class="navbar-brand d-"
            routerLink="/">
            <img src="assets/img/school/sghsc_logo.png"
              alt="logo"
              class="logo img-fluid" />
          </a>
        </div>

        <button class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse mean-menu"
          id="navbarSupportedContent"
          *ngIf="isBig">
          <ul class="navbar-nav">
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle"
                href="javascript:void(0)"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                routerLink="/about">
                About <i class="fas fa-chevron-down"></i>
              </a>
              <ul class="dropdown-menu">
                <li class="nav-item"
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show">
                  <a routerLink="/about/history"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{
                      exact: true
                    }"
                    class="nav-link">History</a>
                </li>
                <li class="nav-item"
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show">
                  <a routerLink="/about/saintGregory"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{
                      exact: true
                    }"
                    class="nav-link">Saint Gregory</a>
                </li>
                <li class="nav-item"
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show">
                  <a routerLink="/about/theLegendGregorian's"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{
                      exact: true
                    }"
                    class="nav-link">The Legend Gregorian's</a>
                </li>
                <li class="nav-item"
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show">
                  <a routerLink="/about/missionAndVision"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{
                      exact: true
                    }"
                    class="nav-link">Mission and Vision</a>
                </li>
                <li class="nav-item"
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show">
                  <a routerLink="/about/whyStudyHere"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{
                      exact: true
                    }"
                    class="nav-link">Why Study here</a>
                </li>
              </ul>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle"
                href="javascript:void(0)"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                routerLink="/academic">
                Academic <i class="fas fa-chevron-down"></i>
              </a>
              <ul class="dropdown-menu">
                <li class="nav-item">
                  <a routerLink="/academic/academicCalender"
                    routerLinkActive="active"
                    class="nav-link">Academic Calender</a>
                </li>
                <li class="nav-item">
                  <a routerLink="/academic/academicRegulation"
                    routerLinkActive="active"
                    class="nav-link">Academic Regulation</a>
                </li>
                <li class="nav-item">
                  <a routerLink="/academic/curriculum"
                    routerLinkActive="active"
                    class="nav-link">Curriculum</a>
                </li>
                <li class="nav-item">
                  <a routerLink="/academic/under-construction"
                    routerLinkActive="active"
                    class="nav-link">Teachers Information</a>
                </li>
                <li class="nav-item">
                  <a routerLink="/academic/under-construction"
                    routerLinkActive="active"
                    class="nav-link">Staff Information</a>
                </li>
                <li class="nav-item">
                  <a routerLink="/academic/under-construction"
                    routerLinkActive="active"
                    class="nav-link">Employee Information</a>
                </li>
              </ul>
            </li>
            <li class="nav-item">
              <a routerLink="/admission-details"
                class="nav-link">Admission</a>
            </li>
            <li class="nav-item">
              <a routerLink="/on-campus"
                routerLinkActive="active"
                class="nav-link">On Campus</a>
            </li>
          </ul>
          <div class="d-none d-lg-block">
            <a routerLink="/">
              <img src="assets/img/school/sghsc_ro.png"
                alt="logo"
                class="logo-image navbar-logo" />
            </a>
          </div>
          <ul class="navbar-nav">
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle"
                href="javascript:void(0)"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                routerLink="/administration">
                Administration
                <i class="fas fa-chevron-down"></i>
              </a>
              <ul class="dropdown-menu">
                <li class="nav-item">
                  <a routerLink="/administration/governingBody"
                    routerLinkActive="active"
                    class="nav-link">Governing Body</a>
                </li>
                <li class="nav-item">
                  <a routerLink="/administration/charimanMessage"
                    routerLinkActive="active"
                    class="nav-link">Chairman Message</a>
                </li>
                <li class="nav-item">
                  <a routerLink="/administration/principalMessage"
                    routerLinkActive="active"
                    class="nav-link">Principal Message</a>
                </li>
                <li class="nav-item">
                  <a routerLink="/administration/vice-PrincipalMessage"
                    routerLinkActive="active"
                    class="nav-link">Vice-Principal Message</a>
                </li>
                <li class="nav-item">
                  <a routerLink="/administration/formerPrincipal"
                    routerLinkActive="active"
                    class="nav-link">Former Principal</a>
                </li>
                <li class="nav-item">
                  <a routerLink="/administration/brothers"
                    routerLinkActive="active"
                    class="nav-link">Brothers</a>
                </li>
              </ul>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle"
                href="javascript:void(0)"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false">
                Student
                <i class="fas fa-chevron-down"></i>
              </a>
              <ul class="dropdown-menu">
                <li class="nav-item">
                  <a href="https://drive.google.com/file/d/19GtnypR7nY1ktF_JjlTeiRnl_f36LNvg/view?usp=sharing"
                    target="_blank"
                    routerLinkActive="active"
                    class="nav-link">Bangla Version</a>
                </li>
                <li class="nav-item">
                  <a href="https://drive.google.com/file/d/19rvsYBs3oT0JVf44ApNBWUem39Ju86Rt/view?usp=sharing"
                    target="_blank"
                    routerLinkActive="active"
                    class="nav-link">English Version</a>
                </li>
              </ul>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle"
                href="javascript:void(0)"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false">
                Result
                <i class="fas fa-chevron-down"></i>
              </a>
              <ul class="dropdown-menu">
                <li class="nav-item">
                  <a href="https://drive.google.com/file/d/1z7bAyPCv085B8YjFx_jZo7ZEAXEAJlRF/view?usp=sharing"
                    target="_blank"
                    routerLinkActive="active"
                    class="nav-link">SSC</a>
                </li>
                <li class="nav-item">
                  <a href="https://drive.google.com/file/d/1bKYHuiHSsyJlFwAabLowrlhU0j-G-tK-/view?usp=sharing"
                    target="_blank"
                    routerLinkActive="active"
                    class="nav-link">HSC</a>
                </li>
              </ul>
            </li>
            <li class="nav-item">
              <a routerLink="/under-construction"
                routerLinkActive="active"
                class="nav-link">সুবর্ণ জয়ন্তী কর্নার</a>
            </li>
            <!-- <li class="nav-item">
              <a routerLink="/imageGallery"
                routerLinkActive="active"
                class="nav-link">Image Gallery</a>
            </li> -->
          </ul>
        </div>
        <div class="collapse navbar-collapse mean-menu-new"
          id="navbarSupportedContent"
          *ngIf="isSmall">
          <ul class="navbar-nav">
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle"
                href="javascript:void(0)"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false">
                About <i class="fas fa-chevron-down"></i>
              </a>
              <ul class="dropdown-menu">
                <li class="nav-item"
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show">
                  <a routerLink="/about/history"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{
                      exact: true
                    }"
                    class="nav-link">History</a>
                </li>
                <li class="nav-item"
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show">
                  <a routerLink="/about/saintGregory"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{
                      exact: true
                    }"
                    class="nav-link">Saint Gregory</a>
                </li>
                <li class="nav-item"
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show">
                  <a routerLink="/about/theLegendGregorian's"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{
                      exact: true
                    }"
                    class="nav-link">The Legend Gregorian's</a>
                </li>
                <li class="nav-item"
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show">
                  <a routerLink="/about/missionAndVision"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{
                      exact: true
                    }"
                    class="nav-link">Mission and Vision</a>
                </li>
                <li class="nav-item"
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show">
                  <a routerLink="/about/whyStudyHere"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{
                      exact: true
                    }"
                    class="nav-link">Why Study here</a>
                </li>
              </ul>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle"
                href="javascript:void(0)"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false">
                Academic <i class="fas fa-chevron-down"></i>
              </a>
              <ul class="dropdown-menu">
                <li class="nav-item">
                  <a routerLink="/academic/academicCalender"
                    routerLinkActive="active"
                    class="nav-link">Academic Calender</a>
                </li>
                <li class="nav-item">
                  <a routerLink="/academic/academicRegulation"
                    routerLinkActive="active"
                    class="nav-link">Academic Regulation</a>
                </li>
                <li class="nav-item">
                  <a routerLink="/academic/curriculum"
                    routerLinkActive="active"
                    class="nav-link">Curriculum</a>
                </li>
                <li class="nav-item">
                  <a routerLink="/academic/under-construction"
                    routerLinkActive="active"
                    class="nav-link">Teachers Information</a>
                </li>
                <li class="nav-item">
                  <a routerLink="/academic/under-construction"
                    routerLinkActive="active"
                    class="nav-link">Staff Information</a>
                </li>
                <li class="nav-item">
                  <a routerLink="/academic/under-construction"
                    routerLinkActive="active"
                    class="nav-link">Employee Information</a>
                </li>
              </ul>
            </li>
            <li class="nav-item">
              <a routerLink="/admission-details"
                class="nav-link">Admission</a>
            </li>
            <li class="nav-item">
              <a routerLink="/on-campus"
                routerLinkActive="active"
                class="nav-link">On Campus</a>
            </li>
            <div class="d-none d-lg-block">
              <a routerLink="/">
                <img src="assets/img/school/sghsc_ro.png"
                  alt="logo"
                  class="logo-image navbar-logo" />
              </a>
            </div>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle"
                href="javascript:void(0)"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false">
                Administration
                <i class="fas fa-chevron-down"></i>
              </a>
              <ul class="dropdown-menu">
                <li class="nav-item">
                  <a routerLink="/administration/governingBody"
                    routerLinkActive="active"
                    class="nav-link">Governing Body</a>
                </li>
                <li class="nav-item">
                  <a routerLink="/administration/charimanMessage"
                    routerLinkActive="active"
                    class="nav-link">Chairman Message</a>
                </li>
                <li class="nav-item">
                  <a routerLink="/administration/principalMessage"
                    routerLinkActive="active"
                    class="nav-link">Principal Message</a>
                </li>
                <li class="nav-item">
                  <a routerLink="/administration/vice-PrincipalMessage"
                    routerLinkActive="active"
                    class="nav-link">Vice-Principal Message</a>
                </li>
                <li class="nav-item">
                  <a routerLink="/administration/formerPrincipal"
                    routerLinkActive="active"
                    class="nav-link">Former Principal</a>
                </li>
                <li class="nav-item">
                  <a routerLink="/administration/brothers"
                    routerLinkActive="active"
                    class="nav-link">Brothers</a>
                </li>
              </ul>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle"
                href="javascript:void(0)"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false">
                Student
                <i class="fas fa-chevron-down"></i>
              </a>
              <ul class="dropdown-menu">
                <li class="nav-item">
                  <a href="https://drive.google.com/file/d/19GtnypR7nY1ktF_JjlTeiRnl_f36LNvg/view?usp=sharing"
                    target="_blank"
                    routerLinkActive="active"
                    class="nav-link">Bangla Version</a>
                </li>
                <li class="nav-item">
                  <a href="https://drive.google.com/file/d/19rvsYBs3oT0JVf44ApNBWUem39Ju86Rt/view?usp=sharing"
                    target="_blank"
                    routerLinkActive="active"
                    class="nav-link">English Version</a>
                </li>
              </ul>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle"
                href="javascript:void(0)"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false">
                Result
                <i class="fas fa-chevron-down"></i>
              </a>
              <ul class="dropdown-menu">
                <li class="nav-item">
                  <a href="https://drive.google.com/file/d/1z7bAyPCv085B8YjFx_jZo7ZEAXEAJlRF/view?usp=sharing"
                    target="_blank"
                    routerLinkActive="active"
                    class="nav-link">SSC</a>
                </li>
                <li class="nav-item">
                  <a href="https://drive.google.com/file/d/1bKYHuiHSsyJlFwAabLowrlhU0j-G-tK-/view?usp=sharing"
                    target="_blank"
                    routerLinkActive="active"
                    class="nav-link">HSC</a>
                </li>
              </ul>
            </li>
            <li class="nav-item">
              <a routerLink="/under-construction"
                routerLinkActive="active"
                class="nav-link">সুবর্ণ জয়ন্তী কর্নার</a>
            </li>
            <!-- <li class="nav-item">
              <a routerLink="/imageGallery"
                routerLinkActive="active"
                class="nav-link">Image Gallery</a>
            </li> -->
          </ul>
        </div>
      </nav>
    </div>
  </div>
</div>

<div class="d-flex justify-content-around sticky-navbar-bottom box">
  <div class="p-2">
    <a routerLink="/administration/governingBody"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      class="nav-link">Governing Body</a>
  </div>
  <div class="p-2">
    <a routerLink="/administration/principalMessage"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      class="nav-link">Principal</a>
  </div>
  <div class="p-2">
    <a routerLink="/administration/vice-PrincipalMessage"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      class="nav-link">Vice Principal</a>
  </div>
  <div class="p-2">
    <a routerLink="/about/history"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      class="nav-link">History</a>
  </div>
</div>
