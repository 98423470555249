import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, ParamMap } from "@angular/router";

@Component({
    selector: "app-features",
    templateUrl: "./features.component.html",
    styleUrls: ["./features.component.scss"],
})
export class FeaturesComponent implements OnInit {
    administration: string[] = [
        "governingBody",
        "charimanMessage",
        "principalMessage",
        "vice-PrincipalMessage",
        "formerPrincipal",
        "brothers",
    ];

    selectedItem: string = "";

    camelCaseToTitleCase(name: string): string {
        // Convert the first character to uppercase
        let convertedName = name.charAt(0).toUpperCase() + name.slice(1);

        // Look for capital letters preceded by lowercase letters and insert a space
        convertedName = convertedName.replace(/([a-z])([A-Z])/g, "$1 $2");

        return convertedName;
    }

    constructor(private route: ActivatedRoute, private location: Location) {}

    ngOnInit() {
        this.route.paramMap.subscribe((params: ParamMap) => {
            const item = params.get("item");
            if (item && this.administration.includes(item)) {
                this.selectedItem = item;
            } else {
                this.selectedItem = this.administration[0];
                this.location.replaceState(`/administration/${this.selectedItem}`);
            }
        });
    }

    selectItem(item: string) {
        this.selectedItem = item;
        this.location.go(`/administration/${item}`);
    }
}
