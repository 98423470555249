import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, ParamMap } from "@angular/router";

@Component({
    selector: "app-home-demo-nine",
    templateUrl: "./home-demo-nine.component.html",
    styleUrls: ["./home-demo-nine.component.scss"],
})
export class HomeDemoNineComponent implements OnInit {
    about: string[] = [
        "history",
        "saintGregory",
        "theLegendGregorian's",
        "missionAndVision",
        "whyStudyHere",
    ];
    contents: {
        [key: string]: {
            template: string;
            description: string;
            imageUrl: string;
        };
    }[] = [
        {
            history: {
                template: "history",
                description: "",
                imageUrl: "",
            },
        },
        {
            saintGregory: {
                template: "saint",
                description: "",
                imageUrl: "",
            },
        },
        {
            "theLegendGregorian's": {
                template: "legend",
                description: "",
                imageUrl: "",
            },
        },
        {
            missionAndVision: {
                template: "missionAndVision",
                description: "",
                imageUrl: "",
            },
        },
        {
            whyStudyHere: {
                template: "whyStudyHere",
                description: "",
                imageUrl: "",
            },
        },
    ];

    selectedItem: string = "";

    getContent(item: string): { description: string; imageUrl: string } {
        const content = this.contents.find((c) => Object.keys(c)[0] === item);
        return content ? content[item] : { description: "", imageUrl: "" };
    }

    camelCaseToTitleCase(name: string): string {
        // Convert the first character to uppercase
        let convertedName = name.charAt(0).toUpperCase() + name.slice(1);

        // Look for capital letters preceded by lowercase letters and insert a space
        convertedName = convertedName.replace(/([a-z])([A-Z])/g, "$1 $2");

        return convertedName;
    }

    constructor(private route: ActivatedRoute, private location: Location) {}

    ngOnInit() {
        this.route.paramMap.subscribe((params: ParamMap) => {
            const item = params.get("item");
            if (item && this.about.includes(item)) {
                this.selectedItem = item;
            } else {
                this.selectedItem = this.about[0];
                this.location.replaceState(`/about/${this.selectedItem}`);
            }
        });
    }

    selectItem(item: string) {
        this.selectedItem = item;
        this.location.go(`/about/${item}`);
    }
}
