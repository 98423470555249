import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-modal-component",
    templateUrl: "./modal-component.component.html",
    styleUrls: ["./modal-component.component.scss"],
})
export class ModalComponentComponent implements OnInit {
    modalShown: boolean;

    ngOnInit() {
        // Check if the modal was shown in the current session
        this.modalShown = !!sessionStorage.getItem("modalShown");

        if (!this.modalShown) {
            // Show the modal here (you can use Bootstrap classes to control the modal display)
            // For example, you can add a class to your modal HTML like "show-modal" and use ngClass to toggle it
            // For now, I'll add the code to show the modal by default without any class toggling.
            this.showModal();
        }
    }

    closeModal() {
        // Close the modal and set the flag in session storage
        this.modalShown = true;
        sessionStorage.setItem("modalShown", "true");
        this.hideModal();
    }

    private showModal() {
        const myModal = document.getElementById("myModal");
        if (myModal) {
            myModal.style.display = "block";
            myModal.classList.add("show"); // Add the "show" class to display the modal
        }
    }

    private hideModal() {
        const myModal = document.getElementById("myModal");
        if (myModal) {
            myModal.style.display = "none";
        }
    }
}