<app-navbar-style-three></app-navbar-style-three>

<div class="bg-image">
    <div class="container" style="padding-top: 30px; padding-bottom: 30px;">
        <div class="row">
            <div class="col-md-3">
                <div align="left" style="padding-left: 20px">
                    <p style="font-size: 26px">News & Events</p>
                </div>
                <div class="list-group list-group-flush">
                    <button *ngFor="let year of newsandevents" (click)="selectNewsYear(year)"
                        [class.active]="selectedNewsYear === year"
                        class="newA linkStyle list-group-item list-group-item-action">{{year}}</button>
                </div>
            </div>
            <div class="col-md-9">
                <div [hidden]="selectedNewsYear !== '2023'">
                    <div class="row" style="margin-top: 20px;">
                        <div class="accordion" id="accordionExample">
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingOne">
                                    <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        School
                                    </button>
                                </h2>
                                <div id="collapseOne" class="accordion-collapse collapse show"
                                    aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ad, harum. Odit,
                                            blanditiis
                                            quam autem
                                            nostrum minus molestias incidunt porro fuga voluptates minima eveniet quo
                                            ratione sint
                                            expedita
                                            ullam quisquam voluptatibus?</p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingTwo">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        High School
                                    </button>
                                </h2>
                                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                                    data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <p><span>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque culpa
                                                iure
                                                ipsum,
                                                enim quas
                                                accusamus autem quidem. Illo, illum consequatur! Debitis cumque
                                                assumenda
                                                aut earum
                                                possimus
                                                quos dolorem laboriosam excepturi!Amet mollitia consectetur quos
                                                delectus
                                                blanditiis dignissimos totam illo eos nobis molestias similique
                                                repellendus,
                                                iusto
                                                natus
                                                pariatur alias excepturi minima quam earum eius voluptates? Repellat
                                                quae
                                                quod
                                                dignissimos
                                                voluptas recusandae!</span></p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingThree">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseThree" aria-expanded="false"
                                        aria-controls="collapseThree">
                                        College
                                    </button>
                                </h2>
                                <div id="collapseThree" class="accordion-collapse collapse"
                                    aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime, odio
                                            doloremque
                                            laudantium unde
                                            tempora id voluptatem repellendus ipsa enim accusantium praesentium ut! Quia
                                            quasi in ab
                                            sint
                                            accusantium quam nulla!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div [hidden]="selectedNewsYear !== '2022'">
                    <div class="row" style="margin-top: 20px;">
                        <div class="accordion" id="accordionExample">
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingOne">
                                    <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        School
                                    </button>
                                </h2>
                                <div id="collapseOne" class="accordion-collapse collapse show"
                                    aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ad, harum. Odit,
                                            blanditiis
                                            quam autem
                                            nostrum minus molestias incidunt porro fuga voluptates minima eveniet quo
                                            ratione sint
                                            expedita
                                            ullam quisquam voluptatibus?</p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingTwo">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        High School
                                    </button>
                                </h2>
                                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                                    data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <p><span>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque culpa
                                                iure
                                                ipsum,
                                                enim quas
                                                accusamus autem quidem. Illo, illum consequatur! Debitis cumque
                                                assumenda
                                                aut earum
                                                possimus
                                                quos dolorem laboriosam excepturi!Amet mollitia consectetur quos
                                                delectus
                                                blanditiis dignissimos totam illo eos nobis molestias similique
                                                repellendus,
                                                iusto
                                                natus
                                                pariatur alias excepturi minima quam earum eius voluptates? Repellat
                                                quae
                                                quod
                                                dignissimos
                                                voluptas recusandae!</span></p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingThree">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseThree" aria-expanded="false"
                                        aria-controls="collapseThree">
                                        College
                                    </button>
                                </h2>
                                <div id="collapseThree" class="accordion-collapse collapse"
                                    aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime, odio
                                            doloremque
                                            laudantium unde
                                            tempora id voluptatem repellendus ipsa enim accusantium praesentium ut! Quia
                                            quasi in ab
                                            sint
                                            accusantium quam nulla!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div [hidden]="selectedNewsYear !== '2021'">
                    <div class="row" style="margin-top: 20px;">
                        <div class="accordion" id="accordionExample">
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingOne">
                                    <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        School
                                    </button>
                                </h2>
                                <div id="collapseOne" class="accordion-collapse collapse show"
                                    aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ad, harum. Odit,
                                            blanditiis
                                            quam autem
                                            nostrum minus molestias incidunt porro fuga voluptates minima eveniet quo
                                            ratione sint
                                            expedita
                                            ullam quisquam voluptatibus?</p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingTwo">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        High School
                                    </button>
                                </h2>
                                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                                    data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <p><span>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque culpa
                                                iure
                                                ipsum,
                                                enim quas
                                                accusamus autem quidem. Illo, illum consequatur! Debitis cumque
                                                assumenda
                                                aut earum
                                                possimus
                                                quos dolorem laboriosam excepturi!Amet mollitia consectetur quos
                                                delectus
                                                blanditiis dignissimos totam illo eos nobis molestias similique
                                                repellendus,
                                                iusto
                                                natus
                                                pariatur alias excepturi minima quam earum eius voluptates? Repellat
                                                quae
                                                quod
                                                dignissimos
                                                voluptas recusandae!</span></p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingThree">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseThree" aria-expanded="false"
                                        aria-controls="collapseThree">
                                        College
                                    </button>
                                </h2>
                                <div id="collapseThree" class="accordion-collapse collapse"
                                    aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime, odio
                                            doloremque
                                            laudantium unde
                                            tempora id voluptatem repellendus ipsa enim accusantium praesentium ut! Quia
                                            quasi in ab
                                            sint
                                            accusantium quam nulla!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div [hidden]="selectedNewsYear !== '2020'">
                    <div class="row" style="margin-top: 20px;">
                        <div class="accordion" id="accordionExample">
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingOne">
                                    <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        School
                                    </button>
                                </h2>
                                <div id="collapseOne" class="accordion-collapse collapse show"
                                    aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ad, harum. Odit,
                                            blanditiis
                                            quam autem
                                            nostrum minus molestias incidunt porro fuga voluptates minima eveniet quo
                                            ratione sint
                                            expedita
                                            ullam quisquam voluptatibus?</p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingTwo">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        High School
                                    </button>
                                </h2>
                                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                                    data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <p><span>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque culpa
                                                iure
                                                ipsum,
                                                enim quas
                                                accusamus autem quidem. Illo, illum consequatur! Debitis cumque
                                                assumenda
                                                aut earum
                                                possimus
                                                quos dolorem laboriosam excepturi!Amet mollitia consectetur quos
                                                delectus
                                                blanditiis dignissimos totam illo eos nobis molestias similique
                                                repellendus,
                                                iusto
                                                natus
                                                pariatur alias excepturi minima quam earum eius voluptates? Repellat
                                                quae
                                                quod
                                                dignissimos
                                                voluptas recusandae!</span></p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingThree">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseThree" aria-expanded="false"
                                        aria-controls="collapseThree">
                                        College
                                    </button>
                                </h2>
                                <div id="collapseThree" class="accordion-collapse collapse"
                                    aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime, odio
                                            doloremque
                                            laudantium unde
                                            tempora id voluptatem repellendus ipsa enim accusantium praesentium ut! Quia
                                            quasi in ab
                                            sint
                                            accusantium quam nulla!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div [hidden]="selectedNewsYear !== '2019'">
                    <div class="row" style="margin-top: 20px;">
                        <div class="accordion" id="accordionExample">
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingOne">
                                    <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        School
                                    </button>
                                </h2>
                                <div id="collapseOne" class="accordion-collapse collapse show"
                                    aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ad, harum. Odit,
                                            blanditiis
                                            quam autem
                                            nostrum minus molestias incidunt porro fuga voluptates minima eveniet quo
                                            ratione sint
                                            expedita
                                            ullam quisquam voluptatibus?</p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingTwo">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        High School
                                    </button>
                                </h2>
                                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                                    data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <p><span>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque culpa
                                                iure
                                                ipsum,
                                                enim quas
                                                accusamus autem quidem. Illo, illum consequatur! Debitis cumque
                                                assumenda
                                                aut earum
                                                possimus
                                                quos dolorem laboriosam excepturi!Amet mollitia consectetur quos
                                                delectus
                                                blanditiis dignissimos totam illo eos nobis molestias similique
                                                repellendus,
                                                iusto
                                                natus
                                                pariatur alias excepturi minima quam earum eius voluptates? Repellat
                                                quae
                                                quod
                                                dignissimos
                                                voluptas recusandae!</span></p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingThree">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseThree" aria-expanded="false"
                                        aria-controls="collapseThree">
                                        College
                                    </button>
                                </h2>
                                <div id="collapseThree" class="accordion-collapse collapse"
                                    aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime, odio
                                            doloremque
                                            laudantium unde
                                            tempora id voluptatem repellendus ipsa enim accusantium praesentium ut! Quia
                                            quasi in ab
                                            sint
                                            accusantium quam nulla!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>