<!-- Preloader -->
<div class="preloader">
    <div class="loader" style="height: 180px;
        width: 180px;">
        <!-- <div class="shadow"></div>
            <div class="box"></div> -->
        <img src="assets/img/school/sghsc_ro.png" class="img-fluid" alt="image">
    </div>
</div>
<!-- End Preloader -->
