<app-navbar-style-three></app-navbar-style-three>

<div class="page-title-area bg-image" style="height: 250px;">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Academic Regulation</h2>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="subscribe-wrap-area">
    <div class="container">
        <div class="subscribe-wrap-inner-box">
            <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                        <button class="accordion-button" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            School
                        </button>
                    </h2>
                    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ad, harum. Odit, blanditiis
                                quam autem
                                nostrum minus molestias incidunt porro fuga voluptates minima eveniet quo ratione sint
                                expedita
                                ullam quisquam voluptatibus?</p>
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTwo">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            High School
                        </button>
                    </h2>
                    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            <p><span>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque culpa iure ipsum,
                                    enim quas
                                    accusamus autem quidem. Illo, illum consequatur! Debitis cumque assumenda aut earum
                                    possimus
                                    quos dolorem laboriosam excepturi!</span><span>Amet mollitia consectetur quos
                                    delectus
                                    blanditiis dignissimos totam illo eos nobis molestias similique repellendus, iusto
                                    natus
                                    pariatur alias excepturi minima quam earum eius voluptates? Repellat quae quod
                                    dignissimos
                                    voluptas recusandae!</span><span>Ducimus consectetur consequuntur alias dolores
                                    saepe fuga
                                    nesciunt libero vitae perspiciatis, ea ab repellendus provident quas, labore
                                    molestias corporis
                                    mollitia corrupti? Exercitationem iste quasi reprehenderit modi eveniet ut
                                    consequuntur
                                    rerum?</span><span>Alias voluptatem officiis fuga veritatis soluta praesentium
                                    impedit illum
                                    molestiae nesciunt reiciendis. Quis nam nulla vero ab quo perferendis exercitationem
                                    ipsum sequi
                                    aspernatur? Excepturi perferendis aperiam quasi rem, nulla quo?</span><span>Eum
                                    adipisci esse
                                    non similique ea a quod corporis quisquam. Corporis molestiae, natus cumque quod
                                    excepturi
                                    doloremque nesciunt quis laborum, debitis porro maiores ullam recusandae voluptatem
                                    fugiat,
                                    placeat repellendus perferendis.</span><span>Sit a odio aliquid voluptate nemo?
                                    Repellendus
                                    similique excepturi saepe porro, pariatur perferendis nisi dolorum modi iure
                                    assumenda.
                                    Praesentium odit dolor aliquid provident voluptas nostrum soluta maxime numquam quia
                                    nesciunt.</span><span>Tempore rem neque adipisci assumenda sit eligendi soluta eaque
                                    fuga fugit
                                    dolor optio, aliquam veritatis nostrum, nobis totam. Ratione asperiores eum soluta
                                    sunt corrupti
                                    nam voluptates cumque blanditiis repellendus fugit.</span><span>Enim repudiandae
                                    officiis unde
                                    eum inventore provident laboriosam illo incidunt qui id voluptatem officia explicabo
                                    perferendis
                                    repellendus, voluptas consequatur. Quam quibusdam necessitatibus pariatur? Ipsum ut
                                    laboriosam
                                    eveniet error veritatis unde!</span><span>Laudantium aperiam, accusantium molestias
                                    deleniti
                                    necessitatibus repellat cupiditate quos ullam magni placeat molestiae sit itaque
                                    similique
                                    debitis numquam porro dolorum officia dolores praesentium ab quia. Eligendi aut
                                    beatae vero
                                    sunt.</span><span>Ratione sunt praesentium nesciunt pariatur itaque quidem iure
                                    quaerat veniam
                                    alias, odio incidunt tempora nihil? Sunt, nulla optio pariatur nostrum voluptates
                                    eaque, ea
                                    repellat dolor ipsam minus esse consequuntur accusantium!</span><span>Laborum
                                    quibusdam
                                    reprehenderit alias accusantium saepe, animi dignissimos suscipit, asperiores ad
                                    placeat minus
                                    commodi est deleniti repellat, doloribus aperiam. Eveniet, nostrum. Numquam, eos ab
                                    porro totam
                                    consequatur sapiente quam amet?</span><span>Quia veniam cum eveniet exercitationem
                                    sint tempora
                                    consequuntur harum, facilis praesentium id, excepturi vitae soluta omnis ipsa
                                    doloremque aperiam
                                    natus quos corporis cumque minus. Dignissimos aliquam perferendis iure libero
                                    laudantium.</span><span>Mollitia esse dolore rerum quod repellendus aut incidunt
                                    repellat, sit
                                    aliquid adipisci, ex maxime iusto harum quasi! Ab facere nostrum quia. m temporibus
                                    aliquam
                                    similique veniam tenetur omnis, cumque adipisci quae inventore dolorem dignissimos
                                    odit, culpa
                                    repudiandae dicta asperiores expedita praesentium.</span></p>
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingThree">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            College
                        </button>
                    </h2>
                    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime, odio doloremque
                                laudantium unde
                                tempora id voluptatem repellendus ipsa enim accusantium praesentium ut! Quia quasi in ab
                                sint
                                accusantium quam nulla!</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>