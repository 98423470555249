<app-navbar-style-three></app-navbar-style-three>
<!-- 
<div class="page-title-area bg-image" style="height: 250px;">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>On Campus</h2>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="subscribe-wrap-area">
    <div class="container">
        <div class="subscribe-wrap-inner-box">
            <div class="blog-area">
                <div class="container">
                    <h2 class="" style="color: #327ab5; text-align: left !important;"> <u>Activities</u> </h2>
                    <div class="row justify-content-center">
                        <div class="col-lg-4 col-md-6">
                            <div class="single-blog-post badgeItem">
                                <div class="image-box">
                                    <a routerLink="/blog-details"><img src="assets/img/blog-image/blog13.jpg"
                                            alt="image"></a>
                                </div>
                                <div class="post-content">
                                    <h3><a routerLink="/blog-details">How To Boost Your Digital Marketing Agency</a>
                                    </h3>
                                    <a routerLink="/blog-details" class="read-more-btn">Read More <i
                                            class="flaticon-right-arrow"></i></a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-blog-post badgeItem">
                                <div class="image-box">
                                    <a routerLink="/blog-details"><img src="assets/img/blog-image/blog13.jpg"
                                            alt="image"></a>
                                </div>
                                <div class="post-content">
                                    <h3><a routerLink="/blog-details">How To Boost Your Digital Marketing Agency</a>
                                    </h3>
                                    <a routerLink="/blog-details" class="read-more-btn">Read More <i
                                            class="flaticon-right-arrow"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->


<div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
    <div class="carousel-indicators">
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active"
            aria-current="true" aria-label="Slide 1"></button>
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1"
            aria-label="Slide 2"></button>
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2"
            aria-label="Slide 3"></button>
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3"
            aria-label="Slide 4"></button>
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4"
            aria-label="Slide 5"></button>
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="5"
            aria-label="Slide 6"></button>
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="6"
            aria-label="Slide 7"></button>
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="7"
            aria-label="Slide 8"></button>
    </div>
    <div class="carousel-inner">
        <div class="carousel-item active">
            <img src="assets/img/school/onCampus/1.jpg" class="d-block w-100" alt="Image 1">
        </div>
        <div class="carousel-item">
            <img src="assets/img/school/onCampus/2.jpg" class="d-block w-100" alt="Image 2">
        </div>
        <div class="carousel-item">
            <img src="assets/img/school/onCampus/3.jpg" class="d-block w-100" alt="Image 3">
        </div>
        <div class="carousel-item">
            <img src="assets/img/school/onCampus/4.jpg" class="d-block w-100" alt="Image 4">
        </div>
        <div class="carousel-item">
            <img src="assets/img/school/onCampus/5.jpg" class="d-block w-100" alt="Image 5">
        </div>
        <div class="carousel-item">
            <img src="assets/img/school/onCampus/6.jpg" class="d-block w-100" alt="Image 6">
        </div>
        <div class="carousel-item">
            <img src="assets/img/school/onCampus/7.jpg" class="d-block w-100" alt="Image 7">
        </div>
    </div>
    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators"
        data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators"
        data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
    </button>
</div>
<section>
    <div style="margin: 40px;">
            The campus of St. Gregory's High School & College is a beautiful and tranquil setting for learning and
            growth. The
            100-acre campus is located in Elmwood, Connecticut, and is home to a variety of academic buildings,
            athletic facilities,
            and residential buildings. <br>
            The academic buildings include a library, a science center, and a performing arts center. The athletic
            facilities
            include a football field, a baseball field, a softball field, a soccer field, a lacrosse field, a track
            and field. The
            residential buildings include dormitories, townhouses, and apartments. <br>
            The campus is a safe and supportive environment for students. In addition to the academic and athletic
            facilities, the
            campus also includes a number of other amenities, such as a cafeteria, a bookstore, a student center,
            and a chapel. <br>
            The campus is located in a convenient location, just minutes from downtown Hartford and the University
            of Connecticut.
            The school is also close to a number of parks and recreation areas. <br>Overall, the campus of St.
            Gregory's High School
            & College is a beautiful and well-equipped facility that provides students with everything they need to
            succeed. <br>
    </div>
</section>