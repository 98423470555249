import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nameGhange',
  pure: true,
})
export class NameGhangePipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): string {
    // console.log()
    let after_ = value.split('gallery/')[1];
    return after_;
  }
}
