import { Component, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';
import { EmitterService } from 'src/app/EmitterService';

@Component({
  selector: 'app-navbar-style-three',
  templateUrl: './navbar-style-three.component.html',
  styleUrls: ['./navbar-style-three.component.scss'],
})
export class NavbarStyleThreeComponent
{
  isScrolled = false;
  isSmall: boolean;
  isBig = true;
  isLeft = true;
  isMiddle = false;
  subscription: Subscription;

  Notices = [];

  constructor(private emitterService: EmitterService)
  {
    this.subscription = this.emitterService.currentMessage.subscribe(
      (message) =>
      {
        if (message === true)
        {
          let noticeData = localStorage.getItem('noticeData');
          let parsedData: any = JSON.parse(noticeData);

          this.Notices = parsedData.filter((x) => x.ShowScroll === true && x.Id !== 1045);

          this.Notices.sort((a, b) => b.Id - a.Id);

          // console.log(this.Notices, 'this.Notices');
        }
      }
    );

    // this.subscription = this.emitterService.currentMessage.subscribe(message =>
    // {
    //   if (message == true)
    //   {
    //     let noticeData = localStorage.getItem("noticeData");

    //     let parsedData: any = JSON.parse(noticeData);

    //     this.Notices = parsedData.filter((x) =>
    //       x.ShowScroll == true
    //     );

    //     console.log(this.Notices, 'this.Notices');
    //   }
    // })
    this.smallScreen();
  }

  @HostListener('window:resize')
  onResize()
  {
    this.smallScreen();
  }

  @HostListener('window:scroll', [])
  onWindowScroll()
  {
    this.isScrolled = window.pageYOffset > 0;
  }

  smallScreen()
  {
    this.isSmall = window.innerWidth < 1300;
    this.isBig = window.innerWidth > 1300;
    this.isLeft = window.innerWidth > 1300;
    this.isMiddle = window.innerWidth < 1300;
  }

  onNavigate(url: string)
  {
    let MyUrl = '';
    if (url.startsWith('http://') || url.startsWith('https://'))
    {
      MyUrl = url
    } else
    {
      MyUrl = 'https://' + url;
    }
    window.open(MyUrl, '_blank');
  }

  NoAdmissionAlert()
  {
    alert('Admission services not open yet.');
    return;
  }
}
